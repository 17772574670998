export function PointTextPreload() {
  return (
    <div className='fixed z-[-1] opacity-0'>
      <p className='font-semibold'>
        ページTOP Comadoでできる4つのこと ポイントとは ご利用方法 よくある質問
        利用規約 プライバシーポリシー 0123456789 ,
      </p>
      <p className='font-medium'>
        ページTOP Comadoでできる4つのこと ポイントとは ご利用方法 よくある質問
        利用規約 プライバシーポリシー 0123456789 ,
      </p>
    </div>
  )
}
