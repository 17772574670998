import { ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
  number: number | string
  label: string
  title: string
  onClick?: () => void
}

export function SPTopExtendOverviewItem({
  number,
  label,
  title,
  onClick,
  ...more
}: Props) {
  return (
    <div
      onClick={onClick}
      className='flex items-center bg-white pt-4 pb-[10px] px-8 first:rounded-t-[9px] last:rounded-b-[9px]'
      {...more}
    >
      <div className='w-[30px] h-[30px] rounded-full bg-accent-green grid place-content-center'>
        <p className='text-[18px] font-semibold leading-[18px] text-white'>
          {number}
        </p>
      </div>
      <div className='flex flex-col ml-6 grow'>
        <p className='font-medium leading-[2.28] tracking-[0.42px]'>{title}</p>
        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px] transform -translate-y-[6px]'>
          {label}
        </p>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M10.9776 14.2973L16.7987 8.47626C17.5862 7.68881 17.0284 6.34238 15.9148 6.34238L4.27268 6.34238C3.15905 6.34238 2.60134 7.68881 3.3888 8.47626L9.20987 14.2973C9.69802 14.7855 10.4895 14.7855 10.9776 14.2973Z'
          fill='currentColor'
        />
      </svg>
    </div>
  )
}
