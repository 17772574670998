import { ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function LimitBlockMobile() {
  return (
    <div className='rounded-lg relative'>
      <div className='absolute left-0 top-[-12px] z-10 flex justify-center w-full'>
        <img
          src='/images/top/limited-block/lead-mobile.png'
          alt='leading'
          className='max-w-[238px] w-full aspect-[238/32]'
        />
      </div>
      <div className='bg-white z-0 p-6 rounded-tl-lg rounded-tr-lg relative text-center'>
        <div className='flex flex-col gap-2 items-center'>
          <img
            src='/images/top/limited-block/coin.png'
            alt='coin'
            className='w-[65px] aspect-[65/35] inline-block mt-2'
          />
          <h3 className='text-[20px] leading-[36px] tracking-[2px] font-semibold'>
            Comadoの健康行動で
            <br />
            ポイントを貯めよう
          </h3>
          <div className='flex flex-col gap-4 tracking-[0.42px] text-[14px] leading-[32px] font-medium text-left'>
            サントリーウエルネスの健康食品・
            美容商品や、サントリーグループの飲料・
            グッズの購入時に使えるポイントで、
            １ポイント＝１円からご利用できます。
          </div>

          <img
            src='/images/top/limited-block/limit-version.webp'
            alt='定期お届けコースの方限定'
            className='w-full aspect-[396/162]'
          />

          <p className='text-[11px] leading-[20px] text-left'>
            ※ハーゲンダッツのギフト券は1枚でミニカップ・クリスピーサンド・バーのいずれか2個とお引き換え可能。
            <br />
            ※画像はイメージです。
          </p>
        </div>
      </div>
      <div className='bg-[#F4F5FB] px-[32px] py-[24px]  font-medium rounded-br-lg rounded-bl-lg'>
        <div className='text-[11px] leading-[20px] text-left'>
          ※Comadoのポイント機能は定期お届けコースの方限定機能です。
        </div>
        <a
          {...getGTM({
            event_name: 'button_click',
            content_type: 'TOP_Point',
            content_id: 'ポイントとは',
          })}
          href={ROUTE_PATHS.POINT}
          className='flex mt-2 font-semibold text-[18px] leading-[36px] tracking-[1.8px] items-center bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full  justify-center py-[8px]'
          style={{
            boxShadow: 'rgb(215, 169, 5) 0px 4px 0px 0px',
          }}
        >
          <p className='font-semibold text-[18px] d-tracking-[1.8px]'>
            ポイントとは
          </p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className='transform -rotate-90'
          >
            <path
              d='M10.9854 14.2973L16.8065 8.47626C17.594 7.68881 17.0363 6.34238 15.9226 6.34238L4.28049 6.34238C3.16686 6.34238 2.60915 7.68881 3.39661 8.47626L9.21768 14.2973C9.70584 14.7855 10.4973 14.7855 10.9854 14.2973Z'
              fill='currentColor'
            ></path>
          </svg>
        </a>
      </div>
    </div>
  )
}
