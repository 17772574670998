import { motion, useInView } from 'framer-motion'
import { useEffect, useId, useRef, useState } from 'react'

type TickerProps = {
  children: JSX.Element[]
  duration?: number
  direction?: 'left' | 'right'
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const noop = () => {}

export function Ticker(props: TickerProps) {
  const {
    children,
    duration = 20,
    onMouseEnter = noop,
    onMouseLeave = noop,
    direction = 'left',
  } = props

  const tickerRef = useRef<HTMLDivElement>(null)
  const [tickerUUID, setTickerUUID] = useState('')
  const [tickerContentWidth, setTickerContentWidth] = useState<number>(0)
  const [numDupes, setNumDupes] = useState<number>(1)

  const isInView = useInView(tickerRef, { once: true })

  const id = useId()

  useEffect(() => {
    setTickerUUID(id)
  }, [])

  useEffect(() => {
    if (isInView) {
      const contentWidth = children.reduce((sum, _, index) => {
        const element = document.getElementById(tickerUUID + '_' + index)
        return element ? sum + element?.clientWidth : sum
      }, 0)

      setTickerContentWidth(contentWidth)
    }
  }, [isInView])

  useEffect(() => {
    if (tickerRef.current && tickerContentWidth) {
      setNumDupes(
        Math.max(
          Math.ceil((2 * tickerRef.current.clientWidth) / tickerContentWidth),
          1,
        ),
      )
    }
  }, [tickerRef.current, tickerContentWidth])

  const directionFactor = direction === 'left' ? -1 : 1

  return (
    <div
      ref={tickerRef}
      className='w-full h-full overflow-hidden'
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <motion.div
        initial={false}
        animate={{ x: tickerContentWidth * directionFactor }}
        transition={{ ease: 'linear', duration, repeat: Infinity }}
        className='flex'
      >
        {children.map((item, index) => (
          <div key={index} id={`${tickerUUID}_${index}`}>
            {item}
          </div>
        ))}
        {[...Array(numDupes)].map((_) =>
          children.map((item, index) => <div key={index}>{item}</div>),
        )}
      </motion.div>
    </div>
  )
}
