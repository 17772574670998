import { ArrowDownSvg } from '../svg/arrow-down'
import { ComponentPropsWithoutRef } from 'react'

export function TopExtendDeviceButton({
  children,
  ...more
}: ComponentPropsWithoutRef<'button'>) {
  return (
    <button
      type='button'
      className='relative hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] flex items-center justify-center outline-none bg-primary-600 rounded-full w-fit d-py-[12px] d-px-[70px]'
      style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
      {...more}
    >
      <p className='font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
        {children}
      </p>
      <ArrowDownSvg className='transform -rotate-90 d-w-[24px] d-h-[24px] absolute d-right-[24px]' />
    </button>
  )
}
