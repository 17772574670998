import { PCPoint } from 'components/point'
import { SPPoint } from 'components/point-sp'
import { Fragment } from 'react'

export default function PointPage() {
  return (
    <Fragment>
      <div className='pc-version'>
        <PCPoint />
      </div>
      <div className='sp-version'>
        <SPPoint />
      </div>
    </Fragment>
  )
}
