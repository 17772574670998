import { ComponentPropsWithoutRef } from 'react'

export function ArrowDownSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      {...props}
    >
      <path
        d='M10.9854 14.2973L16.8065 8.47626C17.594 7.68881 17.0363 6.34238 15.9226 6.34238L4.28049 6.34238C3.16686 6.34238 2.60915 7.68881 3.39661 8.47626L9.21768 14.2973C9.70584 14.7855 10.4973 14.7855 10.9854 14.2973Z'
        fill='currentColor'
      />
    </svg>
  )
}
