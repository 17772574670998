import { TopExtendQuestionCollapse } from 'components/top/extend/questions/collapse'
import { CopySvg } from 'components/top/extend/svg/copy'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function PCPointPart06() {
  return (
    <div
      id={ELEMENT_ID.FAQ}
      className={`d-pb-[80px] d-pt-[95px] d-px-[40px] background-morning bg-repeat`}
    >
      <div className='max-w-[880px] mx-auto w-full flex flex-col relative'>
        <div className='flex items-center justify-center d-gap-[8px]'>
          <LeftSlashSvg className='d-w-[15px] d-h-[15px]' />
          <p className='font-semibold d-text-[32px] d-leading-[64px] d-tracking-[3.2px]'>
            ポイントのよくある質問
          </p>
          <RightSlashSvg className='d-w-[15px] d-h-[15px]' />
        </div>

        <div className='flex items-center d-gap-[12px] d-mt-[16px]'>
          <span className='rounded-full bg-accent-green d-w-[18px] d-h-[18px]' />
          <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
            サントリーウエルネスポイント
          </p>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='サントリーウエルネスポイントは何をすれば貯まりますか？'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_1',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                ・サントリーウエルネス商品（定期的なお届けも含む）や
                <br />
                サントリーグループ商品のご購入によってポイントが貯まります。
                <br />
                ・定期お届けコースのお客様で、Comadoアプリをご利用いただき、
                <br />
                飲用習慣タスクや健康習慣タスクを実行頂くことでポイントがたまります。
              </p>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='サントリーウエルネスポイントの有効期限はありますか?'
            duration={0.2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_2',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                有効期限はあります。
                <br />
                サントリーウエルネスポイントを獲得頂いた年の翌々年末（12月31日）までが有効期限です。
                <br />
                有効期限がきれたポイントは、失効扱いとなり、利用することができません。
                <br />
                一方で、有効期限切れ以外（例えば、定期解約やComadoアプリの退会など）では、
                <br />
                サントリーウエルネスポイントは失効することはありません。
              </p>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='貯まったポイントは何ポイントから使えますか?'
            duration={0.2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_3',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-center d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                1ポイントから利用することができます。
              </p>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <div className='flex items-center d-gap-[12px] d-mt-[40px]'>
          <span className='rounded-full bg-accent-green d-w-[18px] d-h-[18px]' />
          <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
            チャレンジ
          </p>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='チャレンジに達成期限はありますか'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_1',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                チャレンジには全部で3つのカテゴリがあり、カテゴリにより達成期限は異なります。
                <br />
                ・はじめてのチャレンジ：達成期限はありません。
                <br />
                ・今日のチャレンジ：毎日0:00に達成記録がリセットされるため、達成期限は毎日23:59です。
                <br />
                ・週間のチャレンジ：毎週月曜日0:00に達成記録がリセットされるため、達成期限は毎週日曜日23:59です。
              </p>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='4,000歩数以上歩いたのにポイントが貯まりません。'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_2',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <div className='flex flex-col'>
                <p className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  歩数でポイントを貯めるためには、4,000歩歩いた当日中にComadoアプリを開き、「トップページ」もしくは「歩数記録ページ」を訪れる必要があります。
                </p>

                <div className='flex items-center d-gap-[8px] d-mt-[16px]'>
                  <div className='d-w-[24px] d-h-[24px] rounded-full d-border-[2px] border-illust-tealblue flex items-center justify-center'>
                    <span className='d-text-[16px] d-leading-[16px] text-black font-semibold'>
                      1
                    </span>
                  </div>
                  <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                    ComadoのTOPページに訪れる
                  </p>
                </div>
                <img
                  src='/images/point/part-06/pic-01.webp'
                  alt='pic-01'
                  className='d-w-[408px] d-mt-[8px] object-contain'
                />
                <div className='flex items-center d-gap-[8px] d-mt-[16px]'>
                  <div className='d-w-[24px] d-h-[24px] rounded-full d-border-[2px] border-illust-tealblue flex items-center justify-center'>
                    <span className='d-text-[16px] d-leading-[16px] text-black font-semibold'>
                      2
                    </span>
                  </div>
                  <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                    歩数記録ページに訪れる
                  </p>
                </div>
                <img
                  src='/images/point/part-06/pic-02.webp'
                  alt='pic-02'
                  className='d-w-[408px] d-mt-[8px] object-contain'
                />

                <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] d-mt-[8px] font-medium'>
                  アプリを開いても歩数が「0歩」と表示されている場合、
                  <br />
                  恐れ入りますが以下のよくある質問をご確認ください。
                  <br />
                  ・iPhoneをご利用の方
                  <br />
                  よくある質問＞歩数＞常に0歩と表示されてしまう（iPhone）
                  <br />
                  ・Androidをご利用の方
                  <br />
                  よくある質問＞歩数＞常に0歩と表示されてしまう（Android）
                </p>
              </div>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <div className='d-mt-[16px]'>
          <TopExtendQuestionCollapse
            title='ポイントはどのタイミングでもらえますか？'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_3',
            })}
          >
            <div className='rounded-b-lg bg-bg-blue flex items-start d-gap-[16px] d-p-[24px]'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='object-contain d-w-[42px]'
              />
              <p className='font-medium d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
                ポイントの種類によってタイミングが異なります。
                <br />
                以下のタイミングで、今回、獲得頂いたポイントが、これまでためていたポイントに加算されます。
                <br />
                <br />
                ■サントリーウエルネス商品購入によって獲得するポイント
                <br />
                ご注文頂いた商品を出荷した翌日にポイントが反映されます。
                <br />
                ECサイトの注文完了時点、または、お電話による注文完了時点ではありません。目安として、注文完了時点から数日後の反映となります。
                <br />
                <br />
                ■Comadoアプリの対象アクション実行によって獲得するポイント
                <br />
                対象となるアクションを実行頂いたタイミングでポイントが反映されます。
                <br />
                通信状況等により多少の差異はあります。
                <br />
                <br />
                但し、キャンペーンなどによるポイント進呈のタイミングは上記の限りではありません。
              </p>
            </div>
          </TopExtendQuestionCollapse>
        </div>

        <a
          target='_blank'
          href='https://secure.okbiz.jp/swe/category/show/37?key=400000147'
          className='flex items-baseline d-gap-[4px] d-mt-[32px] mx-auto'
        >
          <span className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium underline'>
            よくある質問をもっと見る
          </span>
          <CopySvg className='d-w-[20px] d-h-[20px]' />
        </a>
      </div>
    </div>
  )
}
