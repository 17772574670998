export function SPPointPart01() {
  return (
    <div className='w-full min-h-dvh bg-primary-600 px-[16px]'>
      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <img
          src='/images/point-sp/part-01/label.webp'
          alt='label'
          className='w-[min(238px,40vh)] mt-[min(73px,12vh)] object-contain'
        />

        <p className='text-[min(22px,3.5vh)] leading-[min(36px,6vh)] tracking-[2.2px] mt-[min(16px,2vh)] font-semibold text-center'>
          Comadoアプリで健康行動が
          <br />
          ポイントに変わる！
        </p>

        <p className='mt-[8px] text-[min(14px,2.5vh)] leading-[min(22px,5vh)] tracking-[0.42px] font-medium self-start'>
          サントリーウエルネスの健康食品・美容商品や、
          <br />
          サントリーグループの飲料・グッズの購入時に使えるポイントで、
          <br />
          １ポイント＝１円からご利用できます。
        </p>

        <img
          src='/images/point-sp/part-01/main.webp'
          alt='main'
          className='w-[min(291px,35vh)] mt-[12px] object-contain'
        />
      </div>
    </div>
  )
}
