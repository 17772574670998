import { useEffect, useState } from 'react'
import { LeftSlashSvg } from '../svg/left-slash'
import { RightSlashSvg } from '../svg/right-slash'
import { ELEMENT_ID, MAIL_TO, TEL_TO } from 'utils/constant'
import { TopExtendDeviceCollapse } from './collapse'
import { TopExtendDeviceButton } from './button'
import { PhoneSvg } from '../svg/phone'
import { EmailSvg } from '../svg/email'
import { ArrowDownSvg } from '../svg/arrow-down'
import { PCPointVideoPlayer } from './video'
import { getGTM } from 'utils/functions'

export function TopExtendDevice() {
  const [value, setValue] = useState(0)
  const [openVideo, setOpenVideo] = useState(false)
  const [youtubeCode, setYoutubeCode] = useState('')

  useEffect(() => {
    const googleElements = document.querySelectorAll('.js-google')
    const appleElements = document.querySelectorAll('.js-apple')

    googleElements.forEach((el: any) => {
      el.style.display = value === 0 ? 'block' : 'none'
    })
    appleElements.forEach((el: any) => {
      el.style.display = value === 1 ? 'block' : 'none'
    })
  }, [value])

  const onOpenVideo = (youtubeCode: string) => {
    document.documentElement.classList.toggle('block', true)
    setYoutubeCode(youtubeCode)
    setOpenVideo(true)
  }

  const onCloseVideo = () => {
    document.documentElement.classList.toggle('block', false)
    setOpenVideo(false)
  }

  return (
    <div
      id={ELEMENT_ID.HOW_TO_USER}
      className='max-w-[1000px] mx-auto w-full flex flex-col items-center relative d-pt-[95px]'
    >
      <PCPointVideoPlayer
        youtubeCode={youtubeCode}
        open={openVideo}
        onClose={onCloseVideo}
      />
      <div className='flex items-center d-gap-[8px]'>
        <LeftSlashSvg className='d-w-[15px] d-h-[22px]' />
        <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
          ご利用方法
        </p>
        <RightSlashSvg className='d-w-[15px] d-h-[22px]' />
      </div>

      <div className='flex w-full d-mt-[32px]'>
        <button
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'howtouse',
            content_id: 'Android',
          })}
          type='button'
          className='grid place-content-center outline-none border-none bg-white rounded-t-lg transition d-h-[60px] d-px-[72px]'
          onClick={() => setValue(0)}
          style={{ backgroundColor: value === 0 ? 'white' : '#dee9f9' }}
        >
          <p
            className='font-semibold transition d-text-[18px] d-leading-[33px] d-tracking-[1.8px]'
            style={{ opacity: value === 0 ? '1' : '0.2' }}
          >
            Android
          </p>
        </button>
        <button
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'howtouse',
            content_id: 'IPhone',
          })}
          type='button'
          className='grid place-content-center outline-none border-none bg-white rounded-t-lg transition d-h-[60px] d-px-[72px]'
          onClick={() => setValue(1)}
          style={{ backgroundColor: value === 1 ? 'white' : '#dee9f9' }}
        >
          <p
            className='font-semibold transition d-text-[18px] d-leading-[33px] d-tracking-[1.8px]'
            style={{ opacity: value === 1 ? '1' : '0.2' }}
          >
            iPhone
          </p>
        </button>
      </div>

      <div className='flex flex-col w-full bg-white rounded-b-lg rounded-tr-lg d-px-[48px] d-pt-[40px] d-pb-[48px]'>
        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
          ダウンロード方法
        </p>
        <span className='w-full border-primary-600 d-border-b-[4px] d-mt-[12px]' />

        <div className='d-mt-[24px]'>
          <TopExtendDeviceCollapse
            title={
              value === 0 ? 'Google Playにアクセス' : 'App Storeにアクセス'
            }
            number={1}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'ストアアクセス',
            })}
          >
            <div className='flex d-py-[16px] d-pl-[50px] d-gap-[32px]'>
              <img
                src='/images/top-extend/devices/android/pic-01.webp'
                alt='step-01'
                className='js-google object-contain d-w-[280px]'
              />
              <img
                src='/images/top-extend/devices/apple/pic-01.webp'
                alt='step-01'
                className='js-apple hidden object-contain d-w-[280px]'
              />
              <div className='flex w-full min-h-full flex-col justify-between d-gap-[24px]'>
                <p className='js-google font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  スマホのカメラで二次元コードを読み取るか、
                  <br />
                  Google Playを開き「Comado」と検索してください。
                </p>
                <p className='js-apple hidden font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  スマホのカメラで二次元コードを読み取るか、
                  <br />
                  App Storeを開き「Comado」と検索してください。
                </p>
                <div className='flex flex-col d-gap-[8px]'>
                  <p className='font-medium d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                    カメラ読み取り編動作動画
                  </p>
                  <TopExtendDeviceButton
                    onClick={() =>
                      onOpenVideo(value === 0 ? 'nVs7ivn3GuY' : 'WW6pYNdoRBQ')
                    }
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'howtouse',
                      content_id: 'ストアアクセス',
                    })}
                  >
                    動画を再生する
                  </TopExtendDeviceButton>
                </div>
              </div>
            </div>
          </TopExtendDeviceCollapse>
        </div>
        <div className='d-mt-[24px]'>
          <TopExtendDeviceCollapse
            title='アプリをダウンロード'
            number={2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'アプリダウンロード',
            })}
          >
            <div className='flex d-py-[16px] d-pl-[50px] d-gap-[32px]'>
              <img
                src='/images/top-extend/devices/android/pic-02.webp'
                alt='step-02'
                className='js-google object-contain d-w-[280px]'
              />
              <img
                src='/images/top-extend/devices/apple/pic-02.webp'
                alt='step-02'
                className='js-apple hidden object-contain d-w-[280px]'
              />
              <div className='flex w-full min-h-full flex-col justify-between d-gap-[24px]'>
                <p className='js-google font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  「インストール」をタップして
                  <br />
                  アプリをダウンロードしましょう。
                  <br />
                  ※アプリをインストールするには、
                  <br />
                  google playのアカウントが必要になります。
                </p>
                <p className='js-apple hidden font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  「インストール」をタップして
                  <br />
                  アプリをダウンロードしましょう。
                  <br />
                  ※アプリをインストールするには、apple IDが
                  <br />
                  必要になります。
                </p>
                <div className='flex flex-col d-gap-[8px]'>
                  <p className='font-medium d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                    アプリダウンロード編動作動画
                  </p>
                  <TopExtendDeviceButton
                    onClick={() =>
                      onOpenVideo(value === 0 ? 'edEcUtkr6VY' : 'eTZ41BEr87U')
                    }
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'howtouse',
                      content_id: 'アプリダウンロード',
                    })}
                  >
                    動画を再生する
                  </TopExtendDeviceButton>
                </div>
              </div>
            </div>
          </TopExtendDeviceCollapse>
        </div>
        <div className='d-mt-[24px]'>
          <TopExtendDeviceCollapse
            title='Comadoアプリを開く'
            number={3}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'comadoアプリを開く',
            })}
          >
            <div className='flex d-py-[16px] d-pl-[50px] d-gap-[32px]'>
              <img
                src='/images/top-extend/devices/android/pic-03.webp'
                alt='step-03'
                className='js-google object-contain d-w-[280px]'
              />
              <img
                src='/images/top-extend/devices/apple/pic-03.webp'
                alt='step-03'
                className='js-apple hidden object-contain d-w-[280px]'
              />
              <div className='flex w-full min-h-full flex-col justify-between d-gap-[24px]'>
                <p className='js-google font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  ボタンが 「開く」に変わったら
                  <br />
                  タップしてComadoアプリを開きましょう。
                </p>
                <p className='js-apple hidden font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  ボタンが 「開く」に変わったら
                  <br />
                  タップしてComadoアプリを開きましょう。
                </p>
                <div className='flex flex-col d-gap-[8px]'>
                  <p className='font-medium d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                    Comadoアプリの開き方編動画
                  </p>
                  <TopExtendDeviceButton
                    onClick={() =>
                      onOpenVideo(value === 0 ? 'umIp_1yj1Ds' : 'tZfVbijlghY')
                    }
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'howtouse',
                      content_id: 'comadoアプリを開く',
                    })}
                  >
                    動画を再生する
                  </TopExtendDeviceButton>
                </div>
              </div>
            </div>
          </TopExtendDeviceCollapse>
        </div>

        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px] d-mt-[36px]'>
          ログイン方法
        </p>
        <span className='w-full border-primary-600 d-border-b-[4px] d-mt-[12px]' />

        <div className='d-mt-[24px]'>
          <TopExtendDeviceCollapse
            title='氏名・電話番号の入力'
            number={1}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: '氏名・電話番号の入力',
            })}
          >
            <div className='flex d-py-[16px] d-pl-[50px] d-gap-[32px]'>
              <img
                src='/images/top-extend/devices/android/pic-04.webp'
                alt='step-04'
                className='js-google object-contain d-w-[280px]'
              />
              <img
                src='/images/top-extend/devices/apple/pic-04.webp'
                alt='step-04'
                className='js-apple hidden object-contain d-w-[280px]'
              />
              <div className='flex w-full min-h-full flex-col justify-between d-gap-[24px]'>
                <p className='js-google font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  氏名をひらがなで入力してください。
                </p>
                <p className='js-apple hidden font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  氏名をひらがなで入力してください。
                </p>
                <div className='flex flex-col d-gap-[8px]'>
                  <p className='font-medium d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                    氏名・電話番号の入力編
                  </p>
                  <TopExtendDeviceButton
                    onClick={() =>
                      onOpenVideo(value === 0 ? 'ZpJBobKDzEU' : 'mVI-t7bZl-g')
                    }
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'howtouse',
                      content_id: '氏名・電話番号の入力',
                    })}
                  >
                    動画を再生する
                  </TopExtendDeviceButton>
                </div>
              </div>
            </div>
          </TopExtendDeviceCollapse>
        </div>
        <div className='d-mt-[24px]'>
          <TopExtendDeviceCollapse
            title='お客様番号の入力'
            number={2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'お客様番号の入力',
            })}
          >
            <div className='flex d-py-[16px] d-pl-[50px] d-gap-[32px]'>
              <img
                src='/images/top-extend/devices/android/pic-05.webp'
                alt='step-05'
                className='js-google object-contain d-w-[280px]'
              />
              <img
                src='/images/top-extend/devices/apple/pic-05.webp'
                alt='step-05'
                className='js-apple hidden object-contain d-w-[280px]'
              />
              <div className='flex w-full min-h-full flex-col justify-between d-gap-[24px]'>
                <p className='js-google font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  最後にお客様番号を入力しログイン完了です。
                  <br />
                  お客様番号は、DM・ECサイト・領収書に
                  <br />
                  記載されています。
                </p>
                <p className='js-apple hidden font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                  最後にお客様番号を入力しログイン完了です。
                  <br />
                  お客様番号は、DM・ECサイト・領収書に
                  <br />
                  記載されています。
                </p>
                <div className='flex flex-col d-gap-[8px]'>
                  <p className='font-medium d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                    お客様番号入力編
                  </p>
                  <TopExtendDeviceButton
                    onClick={() =>
                      onOpenVideo(value === 0 ? 'p_2bm_ATnZ4' : 'XGzxREeJNRE')
                    }
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'howtouse',
                      content_id: 'お客様番号の入力',
                    })}
                  >
                    動画を再生する
                  </TopExtendDeviceButton>
                </div>
              </div>
            </div>
          </TopExtendDeviceCollapse>
        </div>

        <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px] d-mt-[36px]'>
          お困りの方はこちら
        </p>
        <span className='w-full border-primary-600 d-border-b-[4px] d-mt-[12px]' />

        <p className='font-medium d-text-[18px] d-leading-[38px] d-tracking-[1.8px] d-mt-[24px]'>
          アプリのダウンロードや、アプリの操作についてお気軽にお問い合わせください。
        </p>

        <div className='w-full border-border d-mt-[16px] d-border-[2px] d-rounded-[10px] d-py-[32px]'>
          <div className='flex flex-col items-center d-w-[444px] mx-auto'>
            <div className='flex items-center d-gap-[8px]'>
              <PhoneSvg className='d-w-[33px] d-h-[32px]' />
              <p className='d-text-[18px] leading-[36px] tracking-[1.8px] font-semibold'>
                電話でのお問い合わせ
              </p>
            </div>
            <a
              href={TEL_TO}
              className='d-mt-[12px] d-text-[40px] leading-[29px] tracking-[4px] underline'
            >
              0120-630-310
            </a>
            <p className='d-text-[16px] d-leading-[30px] tracking-[1.6px] font-medium d-mt-[12px]'>
              受付時間：9:00～20:00（土日祝日も承ります）
            </p>
            <div className='flex flex-col items-center d-mt-[12px] d-pt-[12px] border-t-[2px] border-border w-full'>
              <div className='flex items-center d-gap-[8px]'>
                <EmailSvg className='d-w-[20px] d-h-[20px]' />
                <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                  メールでのお問い合わせ
                </p>
              </div>
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-medium d-mt-[4px]'>
                info@comado.com
              </p>

              <div className='d-w-[280px] d-mt-[4px]'>
                <a
                  {...getGTM({
                    event_name: 'button_click',
                    content_type: 'send_mail',
                    content_id: 'お問い合わせ_ご利用方法',
                  })}
                  href={MAIL_TO}
                  className='outline-none d-w-[280px] d-mt-[4px] bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center justify-center d-py-[12px] relative'
                  style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
                >
                  <span className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] font-semibold'>
                    メールを送る
                  </span>
                  <ArrowDownSvg className='d-w-[24px] d-h-[24px] transform -rotate-90 absolute d-right-[24px]' />
                </a>
              </div>
              <p className='d-mt-[12px] d-text-[14px] d-leading-[20px]'>
                ※メールアプリが立ち上がります。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
