import { Ticker } from 'components/base/Ticker'
import { CheckSvg } from '../svg/check'

export function TopExtendMenu04() {
  return (
    <div
      id='pc-menu-04'
      className='max-w-[1060px] w-full mx-auto relative d-mt-[68px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-extend/menu-02/roof.webp'
          alt='roof'
          className='object-contain d-w-[1060px] d-h-[156px]'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 d-pt-[29px]'>
          <div className='bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2 d-w-[40px] d-h-[40px]'>
            <p className='font-semibold text-white d-text-[25px] d-leading-[25px]'>
              4
            </p>
          </div>
          <p className='d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
            空いた時間が
          </p>
          <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
            楽しみに変わる
          </p>
        </div>
      </div>

      <div className='w-full d-px-[30px]'>
        <div className='bg-white flex flex-col items-center d-pt-[172px] d-pb-[40px] d-gap-[46px]'>
          <div className='relative flex flex-col d-w-[1000px] items-center d-mt-[13px]'>
            <div className='w-full d-mt-[74px]'>
              <Ticker>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-01.webp'
                    alt='ticker-01'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-02.webp'
                    alt='ticker-02'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-03.webp'
                    alt='ticker-03'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-04.webp'
                    alt='ticker-04'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-05.webp'
                    alt='ticker-05'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-06.webp'
                    alt='ticker-06'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-07.webp'
                    alt='ticker-07'
                    className='object-contain'
                  />
                </div>
              </Ticker>
            </div>
            <div className='w-full d-mt-[18px]'>
              <Ticker>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-06.webp'
                    alt='ticker-06'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-07.webp'
                    alt='ticker-07'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-08.webp'
                    alt='ticker-08'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-09.webp'
                    alt='ticker-09'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-05.webp'
                    alt='ticker-05'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-04.webp'
                    alt='ticker-04'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-03.webp'
                    alt='ticker-03'
                    className='object-contain'
                  />
                </div>
              </Ticker>
            </div>
            <div className='w-full d-mt-[18px]'>
              <Ticker>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-01.webp'
                    alt='ticker-01'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-02.webp'
                    alt='ticker-02'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-03.webp'
                    alt='ticker-03'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-04.webp'
                    alt='ticker-04'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-05.webp'
                    alt='ticker-05'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-06.webp'
                    alt='ticker-06'
                    className='object-contain'
                  />
                </div>
                <div className='relative d-w-[157px] d-h-[105px] d-px-[10px]'>
                  <img
                    src='/images/top-extend/menu-04/pic-07.webp'
                    alt='ticker-07'
                    className='object-contain'
                  />
                </div>
              </Ticker>
            </div>

            <img
              src='/images/top-extend/menu-04/main.webp'
              alt='main'
              className='d-w-[304px] d-h-[396px] object-contain d-ml-[62px] absolute'
            />

            <div
              aria-hidden
              className='d-w-[1000px] d-h-[291px] absolute bottom-0'
              style={{
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
              }}
            />
          </div>

          <div className='flex flex-col'>
            <p className='font-semibold mx-auto border-light-green w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
              できること
            </p>
            <p className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              健康やレシピ、落語も多彩なコラム・動画
            </p>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                健康悩みのマメ知識が満載
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                食や落語、旅などジャンルも豊富
              </p>
            </div>
          </div>
        </div>

        <div className='bg-white flex items-center justify-center relative d-pb-[60px] d-gap-[34px]'>
          <div className='flex flex-col'>
            <p className='font-semibold border-light-green w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
              できること
            </p>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              人気有料雑誌が読み放題
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px] items-center'>
              <CheckSvg className='d-w-[20px] d-h-[20px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                るるぶ・オレンジページ・家庭画報・
                <br />
                プレジデントなど人気雑誌を随時更新
              </p>
            </div>
            <p className='d-mt-[24px] d-text-[16px] d-leading-[30px] d-tracking-[1.6px] font-medium'>
              ※対象雑誌については予告なく変更になる
              <br />
              場合がございます。
            </p>{' '}
            <p className='d-mt-[8px] d-text-[16px] d-leading-[30px] d-tracking-[1.6px] font-medium'>
              ※定期お届けコースの方限定です。
            </p>
          </div>
          <img
            src='/images/top-extend/menu-04/sub-main-pic.webp'
            alt='sub-picture'
            className='object-contain d-w-[410px] d-h-[410px]'
          />

          <img
            src='/images/top/afternoon/pc-afternoon-bus.webp'
            alt='bus'
            className='object-contain absolute d-w-[145px] d-bottom-[-15px] d-left-[-44px]'
          />

          <img
            src='/images/top/afternoon/pc-afternoon-bus-stop.webp'
            alt='bus-stop'
            className='object-contain absolute d-w-[162px] d-bottom-[-35px] d-right-[-58px]'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='object-cover d-h-[39px] d-w-[1069px] d-rounded-[4px]'
      />
    </div>
  )
}
