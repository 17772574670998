import { CheckSvg } from '../svg/check'
import { ArrowDownSvg } from '../svg/arrow-down'
import { ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function TopExtendMenu01() {
  return (
    <div
      id='pc-menu-01'
      className='max-w-[1060px] w-full mx-auto relative d-mt-[60px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-extend/menu-01/roof.webp'
          alt='roof'
          className='object-contain d-w-[1060px] d-h-[157px]'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 d-pt-[29px]'>
          <div className='bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2 d-w-[40px] d-h-[40px]'>
            <p className='font-semibold text-white d-text-[25px] d-leading-[25px]'>
              1
            </p>
          </div>
          <p className='d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
            健康行動が
          </p>
          <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
            ポイントに
          </p>
        </div>
      </div>

      <div className='w-full d-px-[30px]'>
        <div className='bg-white flex items-center justify-center d-pt-[180px] d-pb-[26px] d-gap-[60px]'>
          <div className='flex flex-col'>
            <p className='font-semibold border-primary-600 w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
              できること
            </p>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              健康行動が
              <br />
              お得なポイントに
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                ポイントも貯めながら
                <br />
                健康行動を習慣化
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                サプリなどの商品をお得に購入できます
              </p>
            </div>
          </div>
          <img
            src='/images/top-extend/menu-01/main-pic.webp'
            alt='main-picture'
            className='object-contain d-w-[410px] d-h-[410px]'
          />
        </div>

        <div className='flex flex-col bg-bg-blue items-center relative d-py-[24px]'>
          <p className='font-semibold d-text-[18px] d-tracking-[1.8px]'>
            ポイントは、サプリ購入やサントリー体験などにご活用いただけます。
          </p>
          <p className='d-mt-[8px] d-text-[16px] d-leading-[30px] d-tracking-[1.6px]'>
            ※Comadoのポイント機能は
            <a
              href='https://www.suntory-kenko.com/service/regular/?key=400000144'
              target='_blank'
              className='underline'
            >
              定期お届けコースの方
            </a>
            限定機能です。
          </p>
          <a
            {...getGTM({
              event_name: 'button_click',
              content_type: 'TOP_Point',
              content_id: 'ポイントとは',
            })}
            href={ROUTE_PATHS.POINT}
            className='flex items-center bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full d-mt-[16px] d-gap-[8px] d-px-[40px] d-py-[12px]'
            style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
          >
            <p className='font-semibold d-text-[18px] d-tracking-[1.8px]'>
              ポイントとは
            </p>
            <ArrowDownSvg className='transform -rotate-90 d-w-[20px] d-h-[20px]' />
          </a>

          <img
            src='/images/top-extend/menu-01/medicine.webp'
            alt='medicine'
            className='object-contain absolute bottom-0 d-w-[128px] d-left-[-46px]'
          />

          <img
            src='/images/top/morning/pc-morning-tree.webp'
            alt='tree'
            className='object-contain absolute d-w-[85px] d-bottom-[-28px] d-right-[-5px]'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='object-cover d-h-[39px] d-w-[1069px] d-rounded-[4px]'
      />
    </div>
  )
}
