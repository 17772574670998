import { Fragment } from 'react'
import { TopExtendOverview } from './overview'
import { TopExtendMenu01 } from './menu-01'
import { TopExtendMenu02 } from './menu-02'
import { TopExtendMenu03 } from './menu-03'
import { TopExtendMenu04 } from './menu-04'
import { TopExtendMiddle } from './middle'
import { TopExtendDevice } from './devices'
import { TopExtendQuestions } from './questions'
import { PCFooter } from '../../common/footer/pc'

export function TopExtend() {
  return (
    <Fragment>
      <div className='flex flex-col relative w-full bg-primary-100 d-pb-[80px] d-px-[10px]'>
        <TopExtendOverview />
        <TopExtendMenu01 />
        <TopExtendMenu02 />
        <TopExtendMenu03 />
        <TopExtendMenu04 />
      </div>
      <TopExtendMiddle />
      <div className='bg-bg-green'>
        <div className='d-px-[40px] background-extend-pc-devices bg-[length:100vw_auto] bg-no-repeat bg-top'>
          <TopExtendDevice />
          <TopExtendQuestions />
        </div>
        <PCFooter />
      </div>
    </Fragment>
  )
}
