import { Ticker } from 'components/base/Ticker'
import { CheckSvg } from 'components/top/extend/svg/check'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'

export function SPTopExtendMenu02() {
  return (
    <div
      id='sp-menu-02'
      className='max-w-[360px] w-full mx-auto relative mt-[46px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-sp-extend/menu-02/roof.webp'
          alt='roof'
          className='w-full aspect-[360/104] object-contain'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 pt-[17px]'>
          <div className='w-[32px] h-[32px] bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2'>
            <p className='text-[20px] leading-[20px] font-semibold text-white'>
              2
            </p>
          </div>
          <p className='text-[16px] leading-[2] tracking-[0.48px]'>
            小さな健康行動を
          </p>
          <p className='text-[20px] leading-[1.8] tracking-[2px] font-semibold'>
            習慣化する
          </p>
        </div>
      </div>

      <div className='w-full px-[10px] flex flex-col'>
        <div className='bg-white pt-[137px] px-8 flex flex-col'>
          <div className='flex gap-[10px]'>
            <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-light-green w-fit h-fit'>
              できること
            </p>
            <img
              src='/images/top-extend/menu-02/coin.webp'
              alt='coin'
              className='w-[35px] aspect-[35/39] object-contain'
            />
          </div>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            健康行動を習慣化
          </h1>
          <div className='mt-2 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              専門家が監修した健康行動
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              毎日のチェックで気軽に習慣化
            </p>
          </div>
          <img
            src='/images/top-extend/menu-02/main-pic.webp'
            alt='main-picture'
            className='w-full aspect-square object-contain mt-2'
          />

          <div className='flex items-center justify-center gap-2 mt-4'>
            <LeftSlashSvg className='w-[6px] h-[10px]' />
            <p className='text-[16px] font-semibold leading-[1.88] tracking-[1.6px]'>
              生活の中で取り組めます
            </p>
            <RightSlashSvg className='w-[6px] h-[10px]' />
          </div>
        </div>

        <div className='w-full bg-white pt-2'>
          <Ticker>
            <div className='w-[119px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-01.webp'
                alt='ticker-01'
                className='object-contain'
              />
            </div>
            <div className='w-[125px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-02.webp'
                alt='ticker-02'
                className='object-contain'
              />
            </div>
            <div className='w-[119px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-03.webp'
                alt='ticker-04'
                className='object-contain'
              />
            </div>
            <div className='w-[119px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-04.webp'
                alt='ticker-04'
                className='object-contain'
              />
            </div>
            <div className='w-[125px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-05.webp'
                alt='ticker-05'
                className='object-contain'
              />
            </div>
            <div className='w-[119px] h-[140px] relative mx-[5px]'>
              <img
                src='/images/top-extend/menu-02/ticker-06.webp'
                alt='ticker-06'
                className='object-contain'
              />
            </div>
          </Ticker>
        </div>

        <div className='bg-white pt-[40px] px-8 pb-8 flex flex-col relative'>
          <div className='flex gap-[10px]'>
            <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-light-green w-fit h-fit'>
              できること
            </p>
            <img
              src='/images/top-extend/menu-02/coin.webp'
              alt='coin'
              className='w-[35px] aspect-[35/39] object-contain'
            />
          </div>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            うっかり忘れ防止
            <br />
            サプリの飲用記録
          </h1>
          <div className='mt-2 flex gap-2'>
            <CheckSvg className='w-4 h-4 transform !translate-y-2' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              サプリ飲用や
              <br />
              スキンケア使用を記録
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              決まった時間にお知らせが届く
            </p>
          </div>
          <img
            src='/images/top-extend/menu-02/sub-main-pic.webp'
            alt='sub-picture'
            className='w-full aspect-square object-contain mt-2'
          />

          <img
            src='/images/top/morning/pc-morning-tree.webp'
            alt='tree'
            className='w-[61px] object-contain absolute -bottom-[23px] -right-2'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='w-full aspect-[360/39] rounded-[4px] object-cover'
      />
    </div>
  )
}
