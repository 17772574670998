import { ELEMENT_ID } from 'utils/constant'
import { TopOverViewItem } from './item'
import { getGTM, isSp } from 'utils/functions'

export function TopExtendOverview() {
  const scrollTo = (id: string) => {
    // Check PC version
    if (isSp()) return

    document.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div
      id={ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}
      className='max-w-[1060px] mx-auto w-full flex flex-col items-center d-pt-[146px] d-px-[30px]'
    >
      <img
        src='/images/top-extend/overview/left-leafs.webp'
        alt='left-leafs'
        className='object-contain absolute d-w-[246px] d-top-[104px] d-left-[-12px]'
      />
      <img
        src='/images/top-extend/overview/right-leafs.webp'
        alt='right-leafs'
        className='object-contain absolute d-w-[246px] d-top-[104px] d-right-[-12px]'
      />

      <p className='font-medium d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
        Comadoでちょっと楽しくずっと健康な毎日を。
      </p>

      <div className='flex items-center'>
        <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
          Comadoでできる
        </p>
        <p className='d-mx-[8px] d-text-[60px] d-leading-[82px] d-tracking-[6px]'>
          4
        </p>
        <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
          つのこと
        </p>
      </div>

      <div className='bg-white grid grid-cols-4 w-full d-mt-[50px] d-rounded-[10px]'>
        <TopOverViewItem
          number={1}
          label='健康行動が'
          title='ポイントに'
          onClick={() => scrollTo('pc-menu-01')}
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'main_contents',
            content_id: 'できること１',
          })}
        />
        <TopOverViewItem
          number={2}
          label='小さな健康行動を'
          title='習慣化する'
          onClick={() => scrollTo('pc-menu-02')}
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'main_contents',
            content_id: 'できること２',
          })}
        />
        <TopOverViewItem
          number={3}
          label='自分のペースで'
          title='続けられる'
          onClick={() => scrollTo('pc-menu-03')}
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'main_contents',
            content_id: 'できること３',
          })}
        />
        <TopOverViewItem
          number={4}
          label='空いた時間が'
          title='楽しみに'
          onClick={() => scrollTo('pc-menu-04')}
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'main_contents',
            content_id: 'できること４',
          })}
        />
      </div>
    </div>
  )
}
