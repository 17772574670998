export type SPChipLinkProps = {
  to: string
  label: string
  target?: string
}

export function SPChipLink({ to, label }: SPChipLinkProps) {
  return (
    <a href={to} className='flex items-center gap-1 select-none'>
      <p className='text-[10px] font-medium leading-[1.8] tracking-[1px]'>
        {label}
      </p>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='10'
        viewBox='0 0 10 10'
        fill='none'
      >
        <path
          d='M7.14866 4.51118L4.23813 1.60065C3.8444 1.20692 3.17119 1.48578 3.17119 2.04259L3.17119 7.86366C3.17119 8.42047 3.8444 8.69933 4.23813 8.3056L7.14866 5.39507C7.39274 5.15099 7.39274 4.75526 7.14866 4.51118Z'
          fill='currentColor'
        />
      </svg>
    </a>
  )
}
