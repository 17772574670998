import { motion, useAnimate, useInView } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { sleep } from 'utils/functions'

export function SPFocus() {
  const tickerRef = useRef<HTMLDivElement>(null)

  const [carousel, animateCarousel] = useAnimate()
  const isInView = useInView(tickerRef, { once: true })

  const [box2, animateBox2] = useAnimate()
  const [box3, animateBox3] = useAnimate()
  const [box4, animateBox4] = useAnimate()
  const [box5, animateBox5] = useAnimate()

  const index = useRef<number>(0)
  const previousX = useRef<number>(0)

  const calculateNewX = (newIndex: number) => {
    if (tickerRef.current && box3.current && box2.current) {
      if (newIndex === 2) {
        return previousX.current - box2.current?.clientWidth
      }
      return previousX.current - box3.current?.clientWidth
    }
    return 0
  }

  useEffect(() => {
    if (
      isInView &&
      carousel.current &&
      box2.current &&
      box3.current &&
      box4.current &&
      box5.current
    ) {
      carousel.current.style.height = `${box2.current?.clientHeight}px`
      ;(async () => {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          await sleep(3000)
          const newIndex = index.current + 1

          if (newIndex === 1) {
            animateBox2(
              box2.current,
              { width: '80%', paddingLeft: 0, paddingRight: 0 },
              { duration: 1 },
            )
            animateBox3(
              box3.current,
              { width: '90%', paddingLeft: 4, paddingRight: 4 },
              { duration: 1 },
            )
          }

          if (newIndex === 2) {
            animateBox3(
              box3.current,
              { width: '80%', paddingLeft: 0, paddingRight: 0 },
              { duration: 1 },
            )
            animateBox4(
              box4.current,
              { width: '90%', paddingLeft: 4, paddingRight: 4 },
              { duration: 1 },
            )
          }

          if (newIndex === 3) {
            animateBox4(
              box4.current,
              { width: '80%', paddingLeft: 0, paddingRight: 0 },
              { duration: 1 },
            )
            animateBox5(
              box5.current,
              { width: '90%', paddingLeft: 4, paddingRight: 4 },
              { duration: 1 },
            )
          }

          const x = calculateNewX(newIndex)
          await animateCarousel(carousel.current, { x }, { duration: 1 })

          if (newIndex === 3) {
            animateBox2(
              box2.current,
              { width: '90%', paddingLeft: 4, paddingRight: 4 },
              { duration: 0 },
            )
            animateCarousel(carousel.current, { x: 0 }, { duration: 0 })
            animateBox5(
              box5.current,
              { width: '80%', paddingLeft: 0, paddingRight: 0 },
              { duration: 0 },
            )
          }
          previousX.current = newIndex >= 3 ? 0 : x
          index.current = newIndex >= 3 ? 0 : newIndex
        }
      })()
    }
  }, [isInView])

  return (
    <div ref={tickerRef} className='w-full overflow-hidden'>
      <div className='transform -translate-x-[75%]'>
        <motion.div ref={carousel} className='flex items-center'>
          <div className='shrink-0 w-[80%]'>
            <img
              src='/images/top-extend/menu-03/step-01.webp'
              alt='step-01'
              className='w-full aspect-[298/179] object-contain'
            />
          </div>
          <motion.div
            ref={box2}
            initial={{
              width: '90%',
              paddingLeft: 4,
              paddingRight: 4,
            }}
            className='shrink-0'
          >
            <img
              src='/images/top-extend/menu-03/step-02.webp'
              alt='step-02'
              className='w-full aspect-[298/179] object-contain'
            />
          </motion.div>
          <motion.div
            ref={box3}
            initial={{
              width: '80%',
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className='shrink-0'
          >
            <img
              src='/images/top-extend/menu-03/step-03.webp'
              alt='step-03'
              className='w-full aspect-[298/179] object-contain'
            />
          </motion.div>

          <motion.div
            ref={box4}
            initial={{
              width: '80%',
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className='shrink-0'
          >
            <img
              src='/images/top-extend/menu-03/step-01.webp'
              alt='step-01'
              className='w-full aspect-[298/179] object-contain'
            />
          </motion.div>
          <motion.div
            ref={box5}
            initial={{
              width: '80%',
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className='shrink-0'
          >
            <img
              src='/images/top-extend/menu-03/step-02.webp'
              alt='step-02'
              className='w-full aspect-[298/179] object-contain'
            />
          </motion.div>
          <div className='shrink-0 w-[80%]'>
            <img
              src='/images/top-extend/menu-03/step-03.webp'
              alt='step-03'
              className='w-full aspect-[298/179] object-contain'
            />
          </div>
        </motion.div>
      </div>
    </div>
  )
}
