import { YellowButton } from 'components/base/YellowButton'
import { CheckSvg } from 'components/top/extend/svg/check'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID, ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPPointPart07() {
  return (
    <div className='relative pb-[60px] px-[6px]'>
      <img
        src='/images/point/part-07/left-leaf.webp'
        alt='left-leaf'
        className='w-[117px] object-contain absolute inset-0'
      />
      <img
        src='/images/point/part-07/right-leaf.webp'
        alt='right-leaf'
        className='w-[115px] object-contain absolute top-0 right-0'
      />

      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <div className='flex items-center gap-[8px] mt-[60px]'>
          <LeftSlashSvg className='w-[7px] h-[11px]' />
          <p className='font-semibold text-[20px] leading-[36px] tracking-[2px]'>
            Comadoでできること
          </p>
          <RightSlashSvg className='w-[7px] h-[11px]' />
        </div>

        <div className='relative px-[10px] mt-[24px]'>
          <div className='w-full bg-white min-h-full pt-[84px] px-[32px] pb-[16px]'>
            <p className='text-[22px] leading-[36px] tracking-[2.2px] font-semibold text-center'>
              小さな健康行動を
              <br />
              習慣化する
            </p>

            <div className='flex gap-[8px] mt-[8px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                健康行動を習慣化
              </p>
            </div>

            <div className='flex gap-[8px] mt-[4px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                うっかり忘れ防止サプリの飲用記録
              </p>
            </div>

            <img
              src='/images/point/part-07/pic-01.webp'
              alt='pic-01'
              className='w-full object-contain mt-[36px]'
            />
          </div>

          <img
            src='/images/point-sp/part-07/roof.webp'
            alt='roof'
            className='w-full object-contain absolute inset-0'
          />
        </div>

        <img
          src='/images/point-sp/part-07/floor.webp'
          alt='floor'
          className='w-full object-contain'
        />

        <div className='relative px-[10px] mt-[24px]'>
          <div className='w-full bg-white min-h-full pt-[84px] px-[32px] pb-[16px]'>
            <p className='text-[22px] leading-[36px] tracking-[2.2px] font-semibold text-center'>
              自分のペースで
              <br />
              続けられる
            </p>

            <div className='flex gap-[8px] mt-[8px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                お家で5分からできるフィットネス
              </p>
            </div>

            <div className='flex gap-[8px] mt-[4px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                結果がひと目でわかる歩数記録
              </p>
            </div>

            <img
              src='/images/point/part-07/pic-02.webp'
              alt='pic-02'
              className='w-full object-contain mt-[28px]'
            />
          </div>

          <img
            src='/images/point-sp/part-07/roof.webp'
            alt='roof'
            className='w-full object-contain absolute inset-0'
          />
        </div>

        <img
          src='/images/point-sp/part-07/floor.webp'
          alt='floor'
          className='w-full object-contain'
        />

        <div className='relative px-[10px] mt-[24px]'>
          <div className='w-full bg-white min-h-full pt-[84px] px-[32px] pb-[16px]'>
            <p className='text-[22px] leading-[36px] tracking-[2.2px] font-semibold text-center'>
              空いた時間が
              <br />
              楽しみに変わる
            </p>

            <div className='flex gap-[8px] mt-[8px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                読み放題コンテンツ
              </p>
            </div>

            <div className='flex gap-[8px] mt-[4px]'>
              <CheckSvg className='w-[16px] h-[16px] mt-[8px]' />
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                健康やレシピ、
                <br />
                落語も多彩なコラム・動画
              </p>
            </div>

            <img
              src='/images/point/part-07/pic-03.webp'
              alt='pic-03'
              className='w-full object-contain mt-[35px]'
            />
          </div>

          <img
            src='/images/point-sp/part-07/roof.webp'
            alt='roof'
            className='w-full object-contain absolute inset-0'
          />
        </div>

        <img
          src='/images/point-sp/part-07/floor.webp'
          alt='floor'
          className='w-full object-contain'
        />

        <div className='px-[10px] w-full'>
          <p className='mt-[40px] text-[14px] leading-[20px] text-center'>
            Comadoの機能を確認しよう
          </p>

          <a
            href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
            className='mt-[4px]'
            {...getGTM({
              event_name: 'button_click',
              content_type: 'recommend',
              content_id: 'comadoでできること',
            })}
          >
            <YellowButton style={{ paddingTop: 8, paddingBottom: 8 }}>
              Comadoでできること
            </YellowButton>
          </a>
        </div>
      </div>
    </div>
  )
}
