import { TinyEmitter } from 'tiny-emitter'

export const emitter = new TinyEmitter()

export const ACTIONS = {
  SCROLL_ENABLE: 'SCROLL_ENABLE',
  EXTEND_OPEN: 'EXTEND_OPEN',
  EXTEND_CLOSE: 'EXTEND_CLOSE',
  ANIMATION_01: {
    SCROLL_UP: 'ANIMATION_01_SCROLL_UP',
    SCROLL_DOWN: 'ANIMATION_01_SCROLL_DOWN',
  },
  ANIMATION_02: {
    SCROLL_UP: 'ANIMATION_02_SCROLL_UP',
    SCROLL_DOWN: 'ANIMATION_02_SCROLL_DOWN',
  },
  ANIMATION_03: {
    SCROLL_UP: 'ANIMATION_03_SCROLL_UP',
    SCROLL_DOWN: 'ANIMATION_03_SCROLL_DOWN',
  },
  ANIMATION_04: {
    SCROLL_UP: 'ANIMATION_04_SCROLL_UP',
    SCROLL_DOWN: 'ANIMATION_04_SCROLL_DOWN',
  },
  ANIMATION_05: {
    SCROLL_UP: 'ANIMATION_05_SCROLL_UP',
    SCROLL_DOWN: 'ANIMATION_05_SCROLL_DOWN',
  },
  TRIGGER: 'TOP_PC_SCROLL_TRIGGER',
  MODAL_LOCK: 'TOP_PC_MODAL_LOCK',
}

export const topSPEmitter = new TinyEmitter()

export const TOP_SP_ACTIONS = {
  TOP_SP_SCROLL_ENABLE: 'TOP_SP_SCROLL_ENABLE',
  TOP_SP_EXTEND_OPEN: 'TOP_SP_EXTEND_OPEN',
  TOP_SP_EXTEND_CLOSE: 'TOP_SP_EXTEND_CLOSE',
  TOP_SP_ANIM_FV: {
    SHOW: 'TOP_SP_ANIM_FV_SHOW',
    HIDE: 'TOP_SP_ANIM_FV_HIDE',
  },
  TOP_SP_ANIM_FV_WINDOW: {
    SHOW: 'TOP_SP_ANIM_FV_WINDOW_SHOW',
    HIDE: 'TOP_SP_ANIM_FV_WINDOW_HIDE',
  },
  TOP_SP_ANIM_MORNING: {
    SHOW: 'TOP_SP_ANIM_MORNING_SHOW',
    HIDE: 'TOP_SP_ANIM_MORNING_HIDE',
  },
  TOP_SP_ANIM_MORNING_CONT: {
    SHOW: 'TOP_SP_ANIM_MORNING_CONT_SHOW',
    HIDE: 'TOP_SP_ANIM_MORNING_CONT_HIDE',
  },
  TOP_SP_ANIM_AFTERNOON: {
    SHOW: 'TOP_SP_ANIM_AFTERNOON_SHOW',
    HIDE: 'TOP_SP_ANIM_AFTERNOON_HIDE',
  },
  TOP_SP_ANIM_AFTERNOON_CONT: {
    SHOW: 'TOP_SP_ANIM_AFTERNOON_SHOW_CONT',
    HIDE: 'TOP_SP_ANIM_AFTERNOON_HIDE_CONT',
  },
  TOP_SP_ANIM_EVENING: {
    SHOW: 'TOP_SP_ANIM_EVENING_SHOW',
    HIDE: 'TOP_SP_ANIM_EVENING_HIDE',
  },
  TOP_SP_ANIM_EVENING_CONT: {
    SHOW: 'TOP_SP_ANIM_EVENING_SHOW_CONT',
    HIDE: 'TOP_SP_ANIM_EVENING_HIDE_CONT',
  },
  TOP_SP_ANIM_LAST_VIEW: {
    SHOW: 'TOP_SP_ANIM_LAST_VIEW_SHOW',
    HIDE: 'TOP_SP_ANIM_LAST_VIEW_HIDE',
  },
  TRIGGER: 'TOP_SP_SCROLL_TRIGGER',
  MODAL_LOCK: 'TOP_SP_MODAL_LOCK',
}
