import { ComponentPropsWithoutRef, useRef } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

interface CollapseProps extends ComponentPropsWithoutRef<'button'> {
  title: string
  duration?: number
}

const DURATION = 0.25

export function TopExtendQuestionCollapse({
  children,
  title,
  duration = DURATION,
  ...more
}: CollapseProps) {
  const controls = useAnimationControls()
  const vertical = useAnimationControls()
  const horizontal = useAnimationControls()

  const block = useRef<boolean>(false)
  const isOpen = useRef<boolean>(false)

  const toggle = async () => {
    if (!block.current) {
      isOpen.current = !isOpen.current
      block.current = true
      vertical.start({
        opacity: isOpen.current ? 0 : 1,
        transition: { duration },
      })
      horizontal.start({
        rotate: isOpen.current ? 180 : 0,
        transition: { duration },
      })
      await controls.start({
        height: isOpen.current ? 'auto' : 0,
        transition: { duration },
      })
      block.current = false
    }
  }

  return (
    <div className='flex flex-col bg-white rounded-lg'>
      <button
        type='button'
        className='w-full flex items-center justify-between d-gap-[16px] d-p-[24px]'
        onClick={toggle}
        {...more}
      >
        <div className='flex items-center'>
          <img
            src='/images/top-extend/questions/q.webp'
            alt='q-icon'
            className='object-contain d-w-[42px]'
          />
          <p className='font-semibold d-ml-[16px] d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
            {title}
          </p>
        </div>

        <div
          className='d-w-[40px] d-h-[40px] rounded-full bg-primary-600 relative flex items-center justify-center'
          style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
        >
          <motion.div
            aria-hidden
            animate={horizontal}
            className='d-w-[20px] d-h-[4px] rounded-full bg-text-color'
          />
          <motion.div
            aria-hidden
            animate={vertical}
            className='d-h-[20px] d-w-[4px] rounded-full bg-text-color absolute d-left-[18px]'
          />
        </div>
      </button>
      <motion.div animate={controls} className='overflow-hidden h-0'>
        {children}
      </motion.div>
    </div>
  )
}
