import { Ticker } from 'components/base/Ticker'
import { CheckSvg } from '../svg/check'
import { LeftSlashSvg } from '../svg/left-slash'
import { RightSlashSvg } from '../svg/right-slash'

export function TopExtendMenu02() {
  return (
    <div
      id='pc-menu-02'
      className='max-w-[1060px] w-full mx-auto relative d-mt-[62px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-extend/menu-02/roof.webp'
          alt='roof'
          className='object-contain d-w-[1060px] d-h-[156px]'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 d-pt-[29px]'>
          <div className='bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2 d-w-[40px] d-h-[40px]'>
            <p className='font-semibold text-white d-text-[25px] d-leading-[25px]'>
              2
            </p>
          </div>
          <p className='d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
            小さな健康行動を
          </p>
          <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
            習慣化する
          </p>
        </div>
      </div>

      <div className='w-full d-px-[30px]'>
        <div className='bg-white flex items-center justify-center relative d-pt-[180px] d-pb-[16px] d-gap-[130px]'>
          <div className='flex flex-col'>
            <div className='flex d-gap-[24px]'>
              <p className='font-semibold border-light-green w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
                できること
              </p>
              <img
                src='/images/top-extend/menu-02/coin.webp'
                alt='coin'
                className='object-contain d-w-[47px]'
              />
            </div>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              健康行動を習慣化
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                専門家が監修した健康行動
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                毎日のチェックで気軽に習慣化
              </p>
            </div>
          </div>
          <img
            src='/images/top-extend/menu-02/main-pic.webp'
            alt='main-picture'
            className='object-contain d-w-[410px] d-h-[410px]'
          />

          <img
            src='/images/top-extend/menu-02/leafs.webp'
            alt='leafs'
            className='object-contain absolute bottom-0 d-w-[91px] d-left-[18px]'
          />
        </div>

        <div className='bg-white flex flex-col items-center'>
          <div className='flex items-center d-gap-[8px]'>
            <LeftSlashSvg className='d-w-[11px] d-h-[16px] d-mt-[5px]' />
            <p className='font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
              生活の中で取り組めます
            </p>
            <RightSlashSvg className='d-w-[11px] d-h-[16px] d-mt-[5px]' />
          </div>

          <div className='w-full d-mt-[18px]'>
            <Ticker>
              <div className='relative d-w-[190px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-01.webp'
                  alt='ticker-01'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[190px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-02.webp'
                  alt='ticker-02'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[190px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-03.webp'
                  alt='ticker-04'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[190px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-04.webp'
                  alt='ticker-04'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[200px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-05.webp'
                  alt='ticker-05'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[200px] d-h-[224px] d-px-[8px]'>
                <img
                  src='/images/top-extend/menu-02/ticker-06.webp'
                  alt='ticker-06'
                  className='object-contain'
                />
              </div>
            </Ticker>
          </div>
        </div>

        <div className='bg-white flex items-center justify-center relative d-pt-[32px] d-pb-[26px] d-gap-[59px]'>
          <div className='flex flex-col'>
            <div className='flex d-gap-[24px]'>
              <p className='font-semibold border-light-green w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
                できること
              </p>
              <img
                src='/images/top-extend/menu-02/coin.webp'
                alt='coin'
                className='object-contain d-w-[47px]'
              />
            </div>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              うっかり忘れ防止
              <br />
              サプリの飲用記録
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                サプリ飲用やスキンケア使用を記録
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                決まった時間にお知らせが届く
              </p>
            </div>
          </div>
          <img
            src='/images/top-extend/menu-02/sub-main-pic.webp'
            alt='sub-picture'
            className='object-contain d-w-[410px] d-h-[410px]'
          />

          <img
            src='/images/top/morning/pc-morning-tree.webp'
            alt='tree'
            className='object-contain absolute d-w-[85px] d-bottom-[-19px] d-right-[-21px]'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='object-cover d-h-[39px] d-w-[1069px] d-rounded-[4px]'
      />
    </div>
  )
}
