import { ComponentPropsWithoutRef } from 'react'

export function CopySvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      {...props}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.3958 9C14.3958 8.44772 14.8435 8 15.3958 8H17C18.1046 8 19 8.89543 19 10V15C19 16.1046 18.1046 17 17 17H8C6.89543 17 6 16.1046 6 15V13.375C6 12.8227 6.44772 12.375 7 12.375C7.55228 12.375 8 12.8227 8 13.375V15H17L17 10H15.3958C14.8435 10 14.3958 9.55228 14.3958 9Z'
        fill='#373737'
      />
      <rect x='2' y='4' width='12' height='8' rx='1' fill='#373737' />
    </svg>
  )
}
