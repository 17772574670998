import { ComponentPropsWithoutRef } from 'react'

export function LeftSlashSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='22'
      viewBox='0 0 15 22'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.30113 0.895453C2.57941 0.117375 4.24641 0.522862 5.02448 1.80113L14.508 17.3812C15.2861 18.6595 14.8806 20.3265 13.6023 21.1045C12.324 21.8826 10.657 21.4771 9.87897 20.1989L0.395453 4.6188C-0.382625 3.34053 0.0228616 1.67353 1.30113 0.895453Z'
        fill='currentColor'
      />
    </svg>
  )
}
