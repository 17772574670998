import { SPDownloadButton } from 'components/base/DownloadButton'
import { SPContactModal } from 'components/common/contact/sp'
import { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { getGTM } from 'utils/functions'
import { WhatIsThisPointButton } from '../../base/WhatIsThisPointButton.tsx'
import { ROUTE_PATHS } from 'utils/constant.ts'

type Props = {
  isPointPage?: boolean
}

export function SPPointContact({ isPointPage = false }: Props) {
  const oldScroll = useRef<number>(0)
  const [open, setOpen] = useState(false)

  const container = createRef<HTMLDivElement>()
  const text = createRef<HTMLParagraphElement>()

  const timeout = useRef<any>(null)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const onScroll = () => {
    if (container.current && text.current) {
      // Opacity animation
      container.current.style.opacity = '0'
      container.current.style.transform = 'translateY(100px)'
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        if (container.current) {
          container.current.style.opacity = '1'
          container.current.style.transform = 'none'
        }
      }, 100)

      // Background animation
      const newScroll = window.scrollY
      const isTrigger =
        oldScroll.current < 300 ? newScroll >= 300 : newScroll < 300
      if (isTrigger) {
        if (newScroll > oldScroll.current) {
          container.current.style.background =
            'linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%)'
          container.current.style.backdropFilter = 'blur(2px)'
          text.current.style.display = 'none'
        } else {
          container.current.style.background = 'transparent'
          container.current.style.backdropFilter = 'none'
          text.current.style.display = 'block'
        }
      }

      oldScroll.current = newScroll
    }
  }

  useEffect(() => {
    if (!open) {
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [open, onScroll])

  useEffect(() => {
    onScroll()
  }, [])

  const downloadLink = isPointPage
    ? 'https://comado.info/172/'
    : 'https://comado.info/163/'

  const downloadGTM = {
    event_name: 'download_click',
    content_type: isPointPage ? 'Point' : 'TOP',
    content_id: 'FV_DL',
  }

  return (
    <Fragment>
      <SPContactModal open={open} onClose={onClose} />
      <div
        ref={container}
        className='fixed w-full z-50 bottom-0 p-[16px] transition-all duration-300'
      >
        <div className='flex flex-col max-w-[360px] w-full mx-auto'>
          <div className='grid grid-cols-2 gap-[8px]'>
            <a
              href={ROUTE_PATHS.POINT}
              {...getGTM({
                event_name: 'button_click',
                content_type: 'TOP_Point',
                content_id: 'ポイントとは',
              })}
            >
              <WhatIsThisPointButton />
            </a>
            <a target='_blank' href={downloadLink} {...getGTM(downloadGTM)}>
              <SPDownloadButton />
            </a>
          </div>

          <p
            ref={text}
            className='ds-text-[11px] ds-leading-[20px] ds-mt-[8px]'
          >
            ※定期お届けコース限定の機能やコンテンツもございます。
          </p>
        </div>
      </div>
    </Fragment>
  )
}
