import { PCDownloadModal } from 'components/common/modal'
import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { ELEMENT_ID, ROUTE_PATHS } from 'utils/constant'
import { getGTM, isSp } from 'utils/functions'

type Props = {
  isTopPage?: boolean
}

export function PCHeader({ isTopPage = false }: Props) {
  const image = createRef<HTMLImageElement>()
  const header = createRef<HTMLDivElement>()

  const oldScroll = useRef<number>(0)

  const [open, setOpen] = useState(false)

  const onOpenModal = () => {
    // Check PC version
    if (isSp()) return

    document.documentElement.classList.toggle('block', true)
    setOpen(true)
  }

  const onCloseModal = () => {
    // Check PC version
    if (isSp()) return

    document.documentElement.classList.toggle('block', false)
    setOpen(false)
  }

  useEffect(() => {
    const footer: any = document.querySelector('#pcfooter')

    const onScroll = () => {
      // Check PC version
      if (isSp()) return

      if (image.current && header.current) {
        const scrollY = window.scrollY
        const oldValueY = oldScroll.current
        const windowHeight = window.innerHeight
        const footerOffset = footer?.offsetTop
        const isTopTrigger =
          scrollY >= windowHeight
            ? oldValueY < windowHeight
            : oldValueY >= windowHeight
        const isBottomTrigger =
          scrollY >= footerOffset
            ? oldValueY < footerOffset
            : oldValueY >= footerOffset

        if (isTopTrigger) {
          if (scrollY > oldValueY) {
            image.current.setAttribute('src', '/images/assets/logo-02.svg')
            if (isTopPage) {
              image.current.style.opacity = '1'
            }

            header.current.style.background =
              'linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%)'
            header.current.style.backdropFilter = 'blur(2px)'
          } else {
            image.current.setAttribute('src', '/images/assets/logo.svg')
            if (isTopPage) {
              image.current.style.opacity = '0'
            }

            header.current.style.background = 'transparent'
            header.current.style.backdropFilter = 'none'
          }
        }

        if (isBottomTrigger) {
          if (scrollY > oldValueY) {
            image.current.setAttribute('src', '/images/assets/logo.svg')
          } else {
            image.current.setAttribute('src', '/images/assets/logo-02.svg')
          }
        }

        oldScroll.current = scrollY
      }
    }

    onScroll()
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Fragment>
      <PCDownloadModal open={open} onClose={onCloseModal} />
      <header
        ref={header}
        className='fixed w-full d-py-[25px_1440] flex items-center justify-between d-px-[40px_1440] z-40 top-0'
      >
        <a
          href={ROUTE_PATHS.TOP}
          aria-label='logo'
          {...getGTM({
            event_name: 'button_click',
            content_type: 'header',
            content_id: 'comado_ロゴ',
          })}
        >
          <img
            style={{ opacity: isTopPage ? '0' : '1' }}
            ref={image}
            src='/images/assets/logo.svg'
            alt='logo'
            className='object-contain d-h-[36px_1440]'
          />
        </a>

        <nav className='flex items-center'>
          <a
            href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
            className='font-semibold d-text-[14px_1440] d-leading-[48px_1440] d-tracking-[1.4px_1440]'
          >
            Comadoでできる4つのこと
          </a>
          <a
            href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
            className='font-semibold d-text-[14px_1440] d-leading-[48px_1440] d-tracking-[1.4px_1440] d-ml-[24px_1440]'
          >
            ご利用方法
          </a>
          <a
            href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
            className='font-semibold d-text-[14px_1440] d-leading-[48px_1440] d-tracking-[1.4px_1440] d-ml-[24px_1440]'
          >
            よくある質問
          </a>

          <a
            {...getGTM({
              event_name: 'button_click',
              content_type: 'TOP_Point',
              content_id: 'ポイントとは',
            })}
            href={ROUTE_PATHS.POINT}
            className='d-py-[12px_1440] d-px-[32px_1440] d-ml-[26px_1440] d-border-[2px_1440] border-primary-600 hover:!shadow-none hover:border-primary-700 transform transition-all duration-150 hover:translate-y-[4px] flex items-center rounded-full bg-white'
            style={{ boxShadow: '0px 4px 0px 0px #ECBF1E' }}
          >
            <span className='d-text-[14px_1440] d-leading-[28px_1440] d-tracking-[1.4px_1440] font-semibold'>
              ポイントとは
            </span>
            <ArrowDownSvg className='transform -rotate-90 d-w-[24px_1440] d-h-[24px_1440] d-ml-[8px_1440]' />
          </a>

          <button
            {...getGTM({
              event_name: 'download_click',
              content_type: 'header',
              content_id: 'header_DL',
            })}
            type='button'
            className='outlined-none d-py-[12px_1440] d-px-[40px_1440] d-ml-[16px_1440] d-border-[2px_1440] border-black hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] flex items-center rounded-full bg-[#494949]'
            style={{ boxShadow: '0px 4px 0px 0px black' }}
            onClick={onOpenModal}
          >
            <span className='d-text-[24px_1440] d-leading-[30px_1440] d-tracking-[2.4px_1440] font-semibold text-white'>
              無料でダウンロード
            </span>
          </button>
        </nav>
      </header>
    </Fragment>
  )
}
