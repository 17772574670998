import { ReactNode } from 'react'

export const ROUTE_PATHS = {
  TOP:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_TOP_URL
      : process.env.REACT_APP_DEV_TOP_URL,
  POINT:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_POINT_URL
      : process.env.REACT_APP_DEV_POINT_URL,
}

export const ELEMENT_ID = {
  FOUR_THINGS_YOU_CAN_DO: 'fourthingsyoucando',
  HOW_TO_USER: 'howtouse',
  FAQ: 'faq',
}

export type WithChildren<P = object> = P & {
  children: ReactNode
}

export const MAIL_TO = 'mailto:info@comado.com'

export const TEL_TO = 'tel:0120630310'

export const TEL_TO_02 = 'tel:0120049310'
