import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import TopPage from 'pages/top'
import PointPage from 'pages/point'
import { ROUTE_PATHS } from 'utils/constant'

const routes = (
  <Route>
    <Route index element={<TopPage />}></Route>
    <Route path={ROUTE_PATHS.POINT} element={<PointPage />}></Route>
    <Route path={ROUTE_PATHS.TOP} element={<TopPage />}></Route>
  </Route>
)

export default function Router() {
  return (
    <RouterProvider
      router={createBrowserRouter(createRoutesFromElements(routes))}
    />
  )
}
