import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { SPTopExtendQuestionCollapse } from './collapse'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPTopExtendQuestions() {
  return (
    <div
      id={ELEMENT_ID.FAQ}
      className='max-w-[360px] pt-[44px] pb-[60px] mx-auto w-full flex flex-col relative'
    >
      <div className='flex items-center gap-2 justify-center'>
        <LeftSlashSvg className='w-2 h-3' />
        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px]'>
          よくある質問
        </p>
        <RightSlashSvg className='w-2 h-3' />
      </div>

      <div className='flex items-center gap-2 mt-4'>
        <span aria-hidden className='w-4 h-4 rounded-full bg-accent-green' />
        <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
          ダウンロード前
        </p>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title={
            <span>
              Comadoは
              <br />
              誰が利用できますか？
            </span>
          }
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード前_１',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
              サントリーウエルネスの会員の方はご利用いただけます。定期お届けコースのご契約が無い場合は、「美感遊創」の記事を閲覧できません。※利用できないコンテンツは、今後変更になる可能性がございます。
            </p>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='mt-2'>
        <SPTopExtendQuestionCollapse
          title={
            <span>
              Comadoの利用に
              <br />
              料金はかかりますか？
            </span>
          }
          duration={0.2}
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード前_２',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
              サントリーウエルネスの会員の方は、無料でお使いいただけます。
            </p>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='flex items-center gap-2 mt-8'>
        <span aria-hidden className='w-4 h-4 rounded-full bg-accent-green' />
        <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
          アプリダウンロード後の初期設定
        </p>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title='お客様番号を確認したいです。'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_１',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <div className='flex flex-col text-[16px] font-medium leading-[2] tracking-[0.48px]'>
              <p>
                お客様番号はサントリーウエルネスより発行させていただいております
                <br />
                『郵送物』や『納品書』よりご確認いただけます。
              </p>

              <div className='flex items-center gap-[8px] mt-[8px]'>
                <div
                  aria-hidden
                  className='w-[12px] h-[12px] rounded-[2px] bg-illust-tealblue'
                />
                <p>ダイレクトメール</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-01.webp'
                alt='pic-01'
                className='w-full mt-[8px] object-contain'
              />

              <div className='flex items-center gap-[8px] mt-[8px]'>
                <div
                  aria-hidden
                  className='w-[12px] h-[12px] d-rounded-[2px] bg-illust-tealblue'
                />
                <p>納品書</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-02.webp'
                alt='pic-02'
                className='w-full mt-[8px] object-contain'
              />

              <div className='flex items-center gap-[8px] mt-[8px]'>
                <div
                  aria-hidden
                  className='w-[12px] h-[12px] d-rounded-[2px] bg-illust-tealblue'
                />
                <p>サントリーウエルネスオンライン（マイページ）</p>
              </div>
              <img
                src='/images/top-extend/questions/3/pic-03.webp'
                alt='pic-03'
                className='w-full mt-[8px] object-contain'
              />
            </div>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title={
            <span>
              【iPhone】ログイン後の
              <br />
              ヘルスケア連携とは何ですか？
            </span>
          }
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_２',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <div className='flex flex-col'>
              <p className='font-medium text-[16px] leading-[32px] tracking-[0.48px]'>
                ヘルスケア連携を行うと、Comadoで歩数を計測できるようになります。ログイン後にヘルスケアのデータのアクセスを問われたら、下記の手順で設定してください。
              </p>

              <div className='flex items-center gap-[8px] mt-[16px]'>
                <div className='w-[24px] h-[24px] rounded-full border-[2px] border-illust-tealblue flex items-center justify-center'>
                  <span className='text-[16px] leading-[16px] text-black font-semibold'>
                    1
                  </span>
                </div>
                <p className='text-[16px] leading-[32px] tracking-[0.48px] font-semibold'>
                  「すべてオンにする」をタップする
                </p>
              </div>

              <img
                src='/images/top-extend/questions/4/pic-01.webp'
                alt='pic-01'
                className='w-full mt-[8px] object-contain'
              />

              <div className='flex items-center gap-[8px] mt-[16px]'>
                <div className='w-[24px] h-[24px] rounded-full d-border-[2px] border-illust-tealblue flex items-center justify-center'>
                  <span className='text-[16px] leading-[16px] text-black font-semibold'>
                    2
                  </span>
                </div>
                <p className='text-[16px] leading-[32px] tracking-[0.48px] font-semibold'>
                  右上「許可」をタップする
                </p>
              </div>

              <img
                src='/images/top-extend/questions/4/pic-02.webp'
                alt='pic-02'
                className='w-full mt-[8px] object-contain'
              />

              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px] mt-[8px]'>
                ※ご登録後、お客様番号がマイページに反映されるまで半日〜2日程度お時間をいただいております。
              </p>
            </div>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title={
            <span>
              【iPhone】ログイン後の
              <br />
              通知許諾とは何ですか？
            </span>
          }
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_３',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <div className='flex flex-col'>
              <p className='text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
                通知を許可すると、歩数状況や登録した健康習慣のお知らせ、
                <br />
                予約したレッスンの開催連絡をアプリから受け取ることができます。
                <br />
                ログイン後に通知の許諾を問われたら、右下の「許可」をタップしてください。
              </p>
              <img
                src='/images/top-extend/questions/5/pic-01.webp'
                alt='pic-01'
                className='w-full mt-[12px] object-contain'
              />
            </div>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title={
            <span>
              【Android】ログイン後の
              <br />
              通知許諾とは何ですか？
            </span>
          }
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'ダウンロード後_４',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <div className='flex flex-col'>
              <p className='text-[16px] font-medium leading-[32px] tracking-[0.48px]'>
                通知を許可すると、歩数状況や登録した健康習慣のお知らせ、
                <br />
                予約したレッスンの開催連絡をアプリから受け取ることができます。
                <br />
                ログイン後に通知の許諾を問われたら、右下の「設定」をタップしてください。
              </p>
              <img
                src='/images/top-extend/questions/6/pic-01.webp'
                alt='pic-01'
                className='w-full mt-[12px] object-contain'
              />
            </div>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>

      <div className='flex items-center gap-2 mt-8'>
        <span aria-hidden className='w-4 h-4 rounded-full bg-accent-green' />
        <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
          その他
        </p>
      </div>

      <div className='mt-3'>
        <SPTopExtendQuestionCollapse
          title='Comadoを退会するにはどうしたらよいですか？'
          {...getGTM({
            event_name: 'accordion_click',
            content_type: 'comado_FAQ',
            content_id: 'その他_1',
          })}
        >
          <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
            <img
              src='/images/top-extend/questions/a.webp'
              alt='answer'
              className='w-[28px] object-contain shrink-0'
            />
            <div className='flex flex-col'>
              <p className='text-[16px] font-medium leading-[32px] tracking-[0.48px]'>
                Comadoアプリ内の「よくある質問」から「Comado全般」をタップし、その中の「Comadoを退会したい」をタップしてください。
                <br />
                ページ下部にある「Comadoを退会する」をタップすると、「Comadoを退会しますか」という画面が出てくるので「退会する」をタップすると、Comadoを退会できます。
              </p>
              <img
                src='/images/top-extend/questions/7/pic-01.webp'
                alt='pic-01'
                className='w-full mt-[12px] object-contain'
              />
              <p className='mt-[8px] text-[16px] font-medium leading-[32px] tracking-[0.48px]'>
                ※Comadoを退会すると、利用履歴や登録情報は全て削除されます。
                <br />
                <span className='ml-[16px]'>
                  チャレンジ達成状況(ポイント進呈履歴)については、引き続きご確認が可能です。
                </span>
                <br />
                ※貯まったサントリーウエルネスポイントは、有効期限内であれば、そのまま残り、利用することができます。
                <br />
                ※複数端末でご利用の場合は、全ての端末でログアウトすることで退会いただけます。
              </p>
            </div>
          </div>
        </SPTopExtendQuestionCollapse>
      </div>
    </div>
  )
}
