import { CheckSvg } from '../svg/check'
import { LeftSlashSvg } from '../svg/left-slash'
import { RightSlashSvg } from '../svg/right-slash'
import { Focus } from './focus'

export function TopExtendMenu03() {
  return (
    <div
      id='pc-menu-03'
      className='max-w-[1060px] w-full mx-auto relative d-mt-[68px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-extend/menu-01/roof.webp'
          alt='roof'
          className='d-w-[1060px] d-h-[157px] object-contain'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 d-pt-[29px]'>
          <div className='bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2 d-w-[40px] d-h-[40px]'>
            <p className='font-semibold text-white d-text-[25px] d-leading-[25px]'>
              3
            </p>
          </div>
          <p className='d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
            自分のペースで
          </p>
          <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
            続けられる
          </p>
        </div>
      </div>

      <div className='w-full d-px-[30px]'>
        <div className='bg-white flex items-center justify-end d-pt-[224px] d-pb-[32px] d-gap-[46px]'>
          <div className='flex flex-col'>
            <div className='flex d-gap-[24px]'>
              <p className='font-semibold border-primary-600 w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
                できること
              </p>
              <img
                src='/images/top-extend/menu-02/coin.webp'
                alt='coin'
                className='object-contain d-w-[47px]'
              />
            </div>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              お家で5分からできる
              <br />
              フィットネス
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                現役インストラクターが監修したレッスン
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                体のお悩みにあわせて選べる
              </p>
            </div>
          </div>
          <img
            src='/images/top-extend/menu-03/main-pic.webp'
            alt='main-picture'
            className='object-contain d-w-[448px] d-h-[291px]'
          />
        </div>

        <div className='bg-white flex flex-col items-center'>
          <div className='flex items-center d-gap-[8px]'>
            <LeftSlashSvg className='d-w-[11px] d-h-[16px]' />
            <p className='font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
              レッスン内容が豊富！
            </p>
            <RightSlashSvg className='d-w-[11px] d-h-[16px]' />
          </div>

          <div className='w-full relative d-mt-[24px] d-px-[32px]'>
            <Focus />
          </div>
        </div>

        <div className='bg-white flex items-center justify-center relative d-pt-[40px] d-pb-[26px] d-gap-[59px]'>
          <div className='flex flex-col'>
            <div className='flex d-gap-[24px]'>
              <p className='font-semibold border-primary-600 w-fit d-text-[22px] d-leading-[40px] d-tracking-[2.2px] d-border-b-[5px]'>
                できること
              </p>
              <img
                src='/images/top-extend/menu-02/coin.webp'
                alt='coin'
                className='object-contain d-w-[47px]'
              />
            </div>
            <h1 className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px] d-mt-[24px]'>
              結果がひと目でわかる
              <br />
              歩数記録
            </h1>
            <div className='flex d-mt-[16px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                1日の歩数を簡単チェック
              </p>
            </div>
            <div className='flex d-mt-[8px] d-gap-[8px]'>
              <CheckSvg className='d-w-[20px] d-h-[20px] d-mt-[5px]' />
              <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'>
                歩数で変わる応援コメントも！
              </p>
            </div>
          </div>
          <img
            src='/images/top-extend/menu-03/sub-main-pic.webp'
            alt='main-picture'
            className='object-contain d-w-[410px] d-h-[410px]'
          />

          <img
            src='/images/top-extend/menu-03/people.webp'
            alt='people'
            className='object-contain absolute d-w-[126px] d-bottom-[-16px] d-left-[-56px]'
          />

          <img
            src='/images/top/morning/pc-morning-tree.webp'
            alt='tree'
            className='object-contain absolute d-w-[85px] d-bottom-[-19px] d-right-[-21px]'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='object-cover d-h-[39px] d-w-[1069px] d-rounded-[4px]'
      />
    </div>
  )
}
