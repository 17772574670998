import { ComponentPropsWithoutRef } from 'react'
import { ArrowDownSvg } from '../svg/arrow-down'

interface Props extends ComponentPropsWithoutRef<'div'> {
  number: number
  label: string
  title: string
  onClick?: () => void
}

export function TopOverViewItem({
  number,
  label,
  title,
  onClick,
  ...more
}: Props) {
  return (
    <div className='flex items-center w-full group' {...more}>
      <div
        onClick={onClick}
        className='relative flex flex-col items-center justify-center grow d-pt-[36px] d-pb-[28px] cursor-pointer'
      >
        <div className='grid place-content-center absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-accent-green rounded-full d-h-[45px] d-w-[45px]'>
          <p className='font-semibold text-white d-text-[28px] d-leading-[28px]'>
            {number}
          </p>
        </div>
        <p className='d-text-[16px] d-leading-[30px] d-tracking-[1.6px]'>
          {label}
        </p>
        <div className='flex items-center d-gap-[8px]'>
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            {title}
          </p>

          <ArrowDownSvg className='d-w-[20px] d-h-[20px]' />
        </div>
      </div>
      <span className='border-l-2 border-primary-600 group-last:hidden d-h-[30px]' />
    </div>
  )
}
