import { SPTopExtendOverview } from './overview'
import { SPTopExtendMenu01 } from './menu-01'
import { SPTopExtendMenu02 } from './menu-02'
import { SPTopExtendMenu03 } from './menu-03'
import { SPTopExtendMenu04 } from './menu-04'
import { SPTopExtendMiddle } from './middle'
import { SPTopExtendDevice } from './devices'
import { SPTopExtendQuestions } from './questions'
import { SPFooter } from '../../common/footer/sp'
import { Fragment } from 'react'

export function SPTopExtend() {
  return (
    <Fragment>
      <div className='w-full pb-10 px-[10px] bg-primary-100 relative'>
        <SPTopExtendOverview />
        <SPTopExtendMenu01 />
        <SPTopExtendMenu02 />
        <SPTopExtendMenu03 />
        <SPTopExtendMenu04 />
      </div>
      <div className='bg-bg-green'>
        <SPTopExtendMiddle />
        <div className='px-[20px] background-extend-sp-devices bg-[length:100vw_auto] bg-no-repeat bg-top'>
          <SPTopExtendDevice />
          <SPTopExtendQuestions />
        </div>
        <SPFooter />
      </div>
    </Fragment>
  )
}
