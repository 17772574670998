import { motion, useAnimationControls } from 'framer-motion'
import { createRef, useEffect } from 'react'

type Props = {
  open: boolean
  onClose: () => void
  youtubeCode?: string
}

export function PCPointVideoPlayer({ open, onClose, youtubeCode }: Props) {
  const container = createRef<HTMLDivElement>()

  const modalControls = useAnimationControls()
  const overlayControls = useAnimationControls()

  const handleOpen = async () => {
    if (container.current) {
      container.current.style.display = 'flex'

      overlayControls.start({ opacity: 1, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })
    }
  }

  const handleClose = async () => {
    if (container.current) {
      overlayControls.start({ opacity: 0, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })

      container.current.style.display = 'none'
    }
  }

  useEffect(() => {
    if (open) handleOpen()
    else handleClose()
  }, [open])

  return (
    <div
      ref={container}
      className='fixed hidden inset-0 z-[2000] items-center justify-center d-px-[40px]'
    >
      <motion.div
        aria-hidden
        animate={overlayControls}
        className='bg-[#0B0B0B4D] w-full absolute h-dvh opacity-0'
        onClick={onClose}
      />
      <motion.div
        animate={modalControls}
        initial={{ scale: 0.5 }}
        className='flex flex-col z-[10000] opacity-0 max-h-90dvh overflow-auto'
      >
        <div className='bg-white d-rounded-t-[8px] d-p-[12px] flex justify-end'>
          <button
            type='button'
            className='border-none outline-none flex items-center d-gap-[8px]'
            onClick={onClose}
          >
            <img
              src='/images/top-sp/header/menu-close.png'
              alt='menu-close'
              className='d-w-[44px] object-contain'
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 23 11'
              fill='none'
              className='d-w-[23px] d-h-[11px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0974483 0.719618C-0.156157 1.31905 0.124191 2.01057 0.723621 2.26418L21.1511 10.9066C21.7505 11.1602 22.442 10.8798 22.6956 10.2804C22.9492 9.68095 22.6689 8.98943 22.0694 8.73582L1.64201 0.0934453C1.04258 -0.16016 0.351053 0.120187 0.0974483 0.719618Z'
                fill='currentColor'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.6916 0.719618C22.9452 1.31905 22.6649 2.01057 22.0654 2.26418L1.638 10.9066C1.03857 11.1602 0.347051 10.8798 0.0934453 10.2804C-0.16016 9.68095 0.120187 8.98943 0.719618 8.73582L21.1471 0.0934453C21.7465 -0.16016 22.438 0.120187 22.6916 0.719618Z'
                fill='currentColor'
              />
            </svg>
          </button>
        </div>
        <iframe
          loading='lazy'
          className='d-w-[330px] d-h-[587px]'
          title='Comado app'
          sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
          src={`https://youtube.com/embed/${youtubeCode}?autoplay=0`}
        ></iframe>
      </motion.div>
    </div>
  )
}
