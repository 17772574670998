import { SPTopNoAnimation } from 'components/top-sp/no-animation'
import { PCTopNoAnimation } from 'components/top/no-animation'
import { Fragment } from 'react'

export default function TopPage() {
  return (
    <Fragment>
      <div className='pc-version'>
        <PCTopNoAnimation />
      </div>
      <div className='sp-version'>
        <SPTopNoAnimation />
      </div>
    </Fragment>
  )
}
