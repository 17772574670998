export function TextPreload() {
  return (
    <div className='fixed z-[-1] opacity-0'>
      <p className='font-semibold'>
        あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン一二三四五六七八九十百千万円円時分年月日水火木金土曜日月火水木金土週間今朝昼晩朝食昼食夜食昨日今日明日昨年今年来年去年一昨年毎週毎月毎日午前午後朝晩昼夜夜明け夜明け一昼夜中中央外国国内人間友達家族兄弟姉妹父母父親母親父兄弟姉妹弟妹兄弟子孫祖父祖母孫息子娘両親親子父子母子親友男女兄妹姉弟おじおばいとこおじさんおばさんおじいさんおばあさんおとこのこおんなのここども赤ちゃんおとな年寄り若者大人学生生徒教師先生医者看護婦患者客運転手警官学者科学者芸術家画家音楽家作家俳優女優歌手歌手運動家サッカーサッカー選手野球野球選手テニステニス選手料理人料理皿コップフォークナイフスプーンキッチン冷蔵庫冷凍庫品水飲み物コーヒー紅茶牛乳ビールワイン水果野菜肉魚朝昼夕お次元読取開検索。確認が必要ボ開ょ最様番号入力完了様番号領収書記載操会員美感遊創記事閲覧が無契約定期届※変更可能性がざ会員無使
        ある日の朝、スマホからサプリを飲む時間のお知らせ。
        サプリを飲んだら「できた！」というボタンを押すだけ。毎日記録できるから、簡単に習慣化できます。
        昼下がりは近所の公園へ。4,000歩目指してウォーキング！
        歩数は自動記録。結果もひと目でチェックできます。歩いてもらえる特典ポイントも毎日うれしい！
        おやすみ前に5分ストレッチ。1日疲れた体もリラックス。
        サプリや体悩みから選べるプロ監修のフィットネス。自宅で5分からできる気軽さで、むりなく習慣化！
        次の日の朝。今日もいい1日になりそう！
        朝、目覚めるとスマホからサプリを飲む時間のお知らせ。
        サプリを飲んだら「できた！」ボタンを押すだけ。毎日記録できるから、簡単に習慣化できます。
        昼下がりは近所の公園へ。4,000歩目指してウォーキング！
        歩数は自動で記録。結果もひと目でチェック。履歴も確認できるから毎日の目標達成が嬉しい！
        おやすみ前に5分ストレッチ。1日疲れた体もリラックス。
        サプリや体悩みから選べるプロ監修のフィットネス。自宅で5分からできる気軽さで、むりなく習慣化！
        App Storeにアクセス ページTOP よくある質問 0123456789 ,
        サプリや体悩みから選べる プロ監修のフィットネス。
        自宅で5分からできる気軽さで、 むりなく習慣化！
        歩数は自動記録。結果もひと目で チェックできます。
        歩いてもらえる特典ポイントも 毎日うれしい！
      </p>
      <p className='font-medium'>
        あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをんアイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲン一二三四五六七八九十百千万円円時分年月日水火木金土曜日月火水木金土週間今朝昼晩朝食昼食夜食昨日今日明日昨年今年来年去年一昨年毎週毎月毎日午前午後朝晩昼夜夜明け夜明け一昼夜中中央外国国内人間友達家族兄弟姉妹父母父親母親父兄弟姉妹弟妹兄弟子孫祖父祖母孫息子娘両親親子父子母子親友男女兄妹姉弟おじおばいとこおじさんおばさんおじいさんおばあさんおとこのこおんなのここども赤ちゃんおとな年寄り若者大人学生生徒教師先生医者看護婦患者客運転手警官学者科学者芸術家画家音楽家作家俳優女優歌手歌手運動家サッカーサッカー選手野球野球選手テニステニス選手料理人料理皿コップフォークナイフスプーンキッチン冷蔵庫冷凍庫品水飲み物コーヒー紅茶牛乳ビールワイン水果野菜肉魚朝昼夕お次元読取開検索。確認が必要ボ開ょ最様番号入力完了様番号領収書記載操会員美感遊創記事閲覧が無契約定期届※変更可能性がざ会員無使
        ある日の朝、スマホからサプリを飲む時間のお知らせ。
        サプリを飲んだら「できた！」というボタンを押すだけ。毎日記録できるから、簡単に習慣化できます。
        昼下がりは近所の公園へ。4,000歩目指してウォーキング！
        歩数は自動記録。結果もひと目でチェックできます。歩いてもらえる特典ポイントも毎日うれしい！
        おやすみ前に5分ストレッチ。1日疲れた体もリラックス。
        サプリや体悩みから選べるプロ監修のフィットネス。自宅で5分からできる気軽さで、むりなく習慣化！
        次の日の朝。今日もいい1日になりそう！
        朝、目覚めるとスマホからサプリを飲む時間のお知らせ。
        サプリを飲んだら「できた！」ボタンを押すだけ。毎日記録できるから、簡単に習慣化できます。
        昼下がりは近所の公園へ。4,000歩目指してウォーキング！
        歩数は自動で記録。結果もひと目でチェック。履歴も確認できるから毎日の目標達成が嬉しい！
        おやすみ前に5分ストレッチ。1日疲れた体もリラックス。
        サプリや体悩みから選べるプロ監修のフィットネス。自宅で5分からできる気軽さで、むりなく習慣化！
        App Storeにアクセス ページTOP よくある質問 0123456789 ,
        サプリや体悩みから選べる プロ監修のフィットネス。
        自宅で5分からできる気軽さで、 むりなく習慣化！
        歩数は自動記録。結果もひと目で チェックできます。
        歩いてもらえる特典ポイントも 毎日うれしい！
      </p>
    </div>
  )
}
