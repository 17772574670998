import { ROUTE_PATHS } from 'utils/constant'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { getGTM, isPc, sleep } from 'utils/functions'
import { TOP_SP_ACTIONS, topSPEmitter } from 'utils/emitter'
import { SPMenu } from '../menu'

export function SPHeader() {
  const [open, setOpen] = useState(false)

  const block = useRef<boolean>(false)
  const image = useRef<HTMLImageElement>(null)
  const header = useRef<HTMLDivElement>(null)
  const oldScroll = useRef<number>(0)

  const toggle = async () => {
    // Check SP version
    if (isPc()) return

    if (!block.current) {
      block.current = true
      const newOpen = !open
      document.documentElement.classList.toggle('block', newOpen)
      topSPEmitter.emit(TOP_SP_ACTIONS.MODAL_LOCK, newOpen)
      setOpen(newOpen)
      await sleep(500)
      block.current = false
    }
  }

  const svgVariants: Variants = {
    close: { width: 15, height: 27, viewBox: '0 0 15 27' },
    open: { width: 15, height: 8, viewBox: '0 0 15 8' },
  }

  const path01Variants: Variants = {
    close: {
      d: 'M13.5 3.5L1.5 1',
      transition: { duration: 0.5, type: 'spring' },
    },
    open: { d: 'M14 6.5L1 1', transition: { duration: 0.5, type: 'spring' } },
  }

  const path02Variants: Variants = {
    close: {
      d: 'M1.5 25.5L13.5 23',
      transition: { duration: 0.5, type: 'spring' },
    },
    open: {
      d: 'M1 6.5L14 1',
      transition: { duration: 0.5, type: 'spring' },
    },
  }

  useEffect(() => {
    const footer: any = document.querySelector('#spfooter')

    const onScroll = () => {
      // Check SP version
      if (isPc()) return

      if (image.current && header.current) {
        const scrollY = window.scrollY
        const oldValueY = oldScroll.current
        const windowHeight = window.innerHeight
        const footerOffset = footer?.offsetTop
        const isTopTrigger =
          scrollY >= windowHeight
            ? oldValueY < windowHeight
            : oldValueY >= windowHeight
        const isBottomTrigger =
          scrollY >= footerOffset
            ? oldValueY < footerOffset
            : oldValueY >= footerOffset

        if (isTopTrigger) {
          if (scrollY > oldValueY) {
            image.current.setAttribute('src', '/images/assets/logo-02.svg')
          } else {
            image.current.setAttribute('src', '/images/assets/logo.svg')
          }
          header.current.classList.toggle('header-sp-blur', scrollY > oldValueY)
        }

        if (isBottomTrigger) {
          if (scrollY > oldValueY) {
            image.current.setAttribute('src', '/images/assets/logo.svg')
          } else {
            image.current.setAttribute('src', '/images/assets/logo-02.svg')
          }
        }

        oldScroll.current = scrollY
      }
    }

    const initialScroll = () => {
      if (image.current && header.current) {
        const footerOffset = footer?.offsetTop
        const scrollY = window.scrollY
        const windowHeight = window.innerHeight
        header.current.classList.toggle(
          'header-sp-blur',
          scrollY > windowHeight,
        )
        if (scrollY >= windowHeight && scrollY < footerOffset) {
          image.current.setAttribute('src', '/images/assets/logo-02.svg')
        } else {
          image.current.setAttribute('src', '/images/assets/logo.svg')
        }
      }
    }

    initialScroll()
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [image.current, header.current])

  return (
    <header
      ref={header}
      className='header-sp-blur fixed h-[57px] left-0 top-0 w-screen px-4 flex items-center justify-between z-[1001] transition-opacity duration-200'
    >
      <SPMenu open={open} onClick={toggle} />
      <a
        href={ROUTE_PATHS.TOP}
        aria-label='logo'
        {...getGTM({
          event_name: 'button_click',
          content_type: 'header',
          content_id: 'comado_ロゴ',
        })}
      >
        <img
          ref={image}
          src='/images/assets/logo.svg'
          alt='logo'
          className='h-6'
        />
      </a>
      <button
        type='button'
        className='outline-none border-none relative flex items-center justify-end z-[10001]'
        aria-label='menu-btn'
        onClick={toggle}
      >
        <motion.svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          variants={svgVariants}
          initial='close'
          animate={open ? 'open' : 'close'}
          className='absolute'
        >
          <motion.path
            variants={path01Variants}
            initial='close'
            animate={open ? 'open' : 'close'}
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
          />
          <motion.path
            variants={path02Variants}
            initial='close'
            animate={open ? 'open' : 'close'}
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
          />
        </motion.svg>
        <AnimatePresence>
          {open ? (
            <motion.img
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, x: -20 }}
              exit={{ opacity: 0, x: 0 }}
              transition={{ duration: 0.3, type: 'spring' }}
              src='/images/top-sp/header/menu-close.png'
              alt='menu-close'
              className='w-[44px] object-contain'
            />
          ) : (
            <motion.img
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.5, type: 'spring' }}
              src='/images/top-sp/header/menu-open.png'
              alt='menu-open'
              className='w-[44px] object-contain'
            />
          )}
        </AnimatePresence>
      </button>
    </header>
  )
}
