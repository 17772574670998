import { CheckSvg } from 'components/top/extend/svg/check'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { SPFocus } from './focus'

export function SPTopExtendMenu03() {
  return (
    <div
      id='sp-menu-03'
      className='max-w-[360px] w-full mx-auto relative mt-[46px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-sp-extend/menu-01/roof.webp'
          alt='roof'
          className='w-full aspect-[360/104] object-contain'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 pt-[17px]'>
          <div className='w-[32px] h-[32px] bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2'>
            <p className='text-[20px] leading-[20px] font-semibold text-white'>
              3
            </p>
          </div>
          <p className='text-[16px] leading-[2] tracking-[0.48px]'>
            自分のペースで
          </p>
          <p className='text-[20px] leading-[1.8] tracking-[2px] font-semibold'>
            無理なくつづく
          </p>
        </div>
      </div>

      <div className='w-full px-[10px] flex flex-col items-center'>
        <div className='bg-white pt-[137px] px-8 flex flex-col w-full'>
          <div className='flex gap-[10px]'>
            <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-primary-600 w-fit h-fit'>
              できること
            </p>
            <img
              src='/images/top-extend/menu-02/coin.webp'
              alt='coin'
              className='w-[35px] aspect-[35/39] object-contain'
            />
          </div>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            お家で5分からできる
            <br />
            フィットネス
          </h1>
          <div className='mt-2 flex gap-2'>
            <CheckSvg className='w-4 h-4 transform !translate-y-2' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              現役インストラクターが
              <br />
              監修したレッスン
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              体のお悩みにあわせて選べる
            </p>
          </div>
        </div>

        <div className='bg-white pl-8 pt-2'>
          <img
            src='/images/top-extend/menu-03/main-pic.webp'
            alt='main-picture'
            className='w-full aspect-[312/203] object-contain'
          />
        </div>

        <div className='w-full bg-white pt-4'>
          <div className='flex items-center justify-center gap-2 mb-2'>
            <LeftSlashSvg className='w-[6px] h-[10px]' />
            <p className='text-[16px] font-semibold leading-[1.88] tracking-[1.6px]'>
              レッスン内容が豊富！
            </p>
            <RightSlashSvg className='w-[6px] h-[10px]' />
          </div>
        </div>

        <div className='w-full bg-white pt-2'>
          <SPFocus />
        </div>

        <div className='bg-white pt-[40px] px-8 pb-8 flex flex-col relative'>
          <div className='flex gap-[10px]'>
            <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-primary-600 w-fit h-fit'>
              できること
            </p>
            <img
              src='/images/top-extend/menu-02/coin.webp'
              alt='coin'
              className='w-[35px] aspect-[35/39] object-contain'
            />
          </div>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            結果がひと目でわかる
            <br />
            歩数記録
          </h1>
          <div className='mt-2 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              1日の歩数を簡単チェック
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              歩数で変わる応援コメントも！
            </p>
          </div>
          <img
            src='/images/top-extend/menu-03/sub-main-pic.webp'
            alt='sub-picture'
            className='w-full aspect-square object-contain mt-2'
          />
          <img
            src='/images/top-extend/menu-03/people.webp'
            alt='people'
            className='w-[72px] object-contain absolute -bottom-2 left-3'
          />

          <img
            src='/images/top/morning/pc-morning-tree.webp'
            alt='tree'
            className='w-[61px] object-contain absolute -bottom-[10px] -right-2'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='w-full aspect-[360/39] rounded-[4px] object-cover'
      />
    </div>
  )
}
