import { SPTopExtendQuestionCollapse } from 'components/top-sp/extend/questions/collapse'
import { CopySvg } from 'components/top/extend/svg/copy'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPPointPart06() {
  return (
    <div
      id={ELEMENT_ID.FAQ}
      className='px-[16px] pb-[40px] background-point-sp-part-06 bg-repeat'
    >
      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <div className='flex items-center gap-[8px] mt-[40px]'>
          <LeftSlashSvg className='w-[7px] h-[11px]' />
          <p className='font-semibold text-[20px] leading-[36px] tracking-[2px]'>
            よくある質問
          </p>
          <RightSlashSvg className='w-[7px] h-[11px]' />
        </div>

        <div className='flex items-center gap-[8px] mt-[16px] self-start'>
          <span aria-hidden className='w-4 h-4 rounded-full bg-accent-green' />
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
            サントリーウエルネスポイント
          </p>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='サントリーウエルネスポイントは何をすれば貯まりますか？'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_1',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                ・サントリーウエルネス商品（定期的なお届けも含む）や
                <br />
                サントリーグループ商品のご購入によってポイントが貯まります。
                <br />
                ・定期お届けコースのお客様で、Comadoアプリをご利用いただき、
                <br />
                飲用習慣タスクや健康習慣タスクを実行頂くことでポイントがたまります。
              </p>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='サントリーウエルネスポイントの有効期限はありますか？'
            duration={0.2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_2',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                有効期限はあります。
                <br />
                サントリーウエルネスポイントを獲得頂いた年の翌々年末（12月31日）までが有効期限です。
                <br />
                有効期限がきれたポイントは、失効扱いとなり、利用することができません。
                <br />
                一方で、有効期限切れ以外（例えば、定期解約やComadoアプリの退会など）では、
                <br />
                サントリーウエルネスポイントは失効することはありません。
              </p>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='貯まったポイントは何ポイントから使えますか?'
            duration={0.2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'ウエルネスポイント_3',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                1ポイントから利用することができます。
              </p>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='flex items-center gap-[8px] mt-[32px] self-start'>
          <span aria-hidden className='w-4 h-4 rounded-full bg-accent-green' />
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
            チャレンジ
          </p>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='チャレンジに達成期限はありますか？'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_1',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                チャレンジには全部で3つのカテゴリがあり、カテゴリにより達成期限は異なります。
                <br />
                ・はじめてのチャレンジ：達成期限はありません。
                <br />
                ・今日のチャレンジ：毎日0:00に達成記録がリセットされるため、達成期限は毎日23:59です。
                <br />
                ・週間のチャレンジ：毎週月曜日0:00に達成記録がリセットされるため、達成期限は毎週日曜日23:59です。
              </p>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='4,000歩数以上歩いたのにポイントが貯まりません。'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_2',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <div className='flex flex-col'>
                <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                  歩数でポイントを貯めるためには、4,000歩歩いた当日中にComadoアプリを開き、「トップページ」もしくは「歩数記録ページ」を訪れる必要があります。
                </p>

                <div className='flex items-center gap-[8px] mt-[16px]'>
                  <div className='w-[24px] h-[24px] rounded-full border border-illust-tealblue flex items-center justify-center'>
                    <span className='text-[14px] leading-[16px] text-black font-semibold'>
                      1
                    </span>
                  </div>
                  <p className='text-[16px] leading-[2] tracking-[0.48px] font-semibold'>
                    ComadoのTOPページに訪れる
                  </p>
                </div>
                <img
                  src='/images/point/part-06/pic-01.webp'
                  alt='pic-01'
                  className='w-full object-contain'
                />
                <div className='flex items-center gap-[8px] mt-[16px]'>
                  <div className='w-[24px] h-[24px] rounded-full border border-illust-tealblue flex items-center justify-center'>
                    <span className='text-[14px] leading-[2] tracking-[0.48px] text-black font-semibold'>
                      2
                    </span>
                  </div>
                  <p className='text-[16px] leading-[2] tracking-[0.48px] font-semibold'>
                    歩数記録ページに訪れる
                  </p>
                </div>
                <img
                  src='/images/point/part-06/pic-02.webp'
                  alt='pic-02'
                  className='w-full object-contain'
                />

                <p className='text-[16px] leading-[2] tracking-[0.48px] mt-[8px] font-medium'>
                  アプリを開いても歩数が「0歩」と表示されている場合、
                  <br />
                  恐れ入りますが以下のよくある質問をご確認ください。
                  <br />
                  ・iPhoneをご利用の方
                  <br />
                  よくある質問＞歩数＞常に0歩と表示されてしまう（iPhone）
                  <br />
                  ・Androidをご利用の方
                  <br />
                  よくある質問＞歩数＞常に0歩と表示されてしまう（Android）
                </p>
              </div>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='mt-[12px]'>
          <SPTopExtendQuestionCollapse
            title='ポイントはどのタイミングでもらえますか？'
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'point_FAQ',
              content_id: 'チャレンジ_3',
            })}
          >
            <div className='p-4 rounded-b-lg bg-bg-blue flex items-start gap-2'>
              <img
                src='/images/top-extend/questions/a.webp'
                alt='answer'
                className='w-8 object-contain'
              />
              <p className='text-[16px] font-medium leading-[2] tracking-[0.48px]'>
                ポイントの種類によってタイミングが異なります。
                <br />
                以下のタイミングで、今回、獲得頂いたポイントが、これまでためていたポイントに加算されます。
                <br />
                <br />
                ■サントリーウエルネス商品購入によって獲得するポイント
                <br />
                ご注文頂いた商品を出荷した翌日にポイントが反映されます。
                <br />
                ECサイトの注文完了時点、または、お電話による注文完了時点ではありません。目安として、注文完了時点から数日後の反映となります。
                <br />
                <br />
                ■Comadoアプリの対象アクション実行によって獲得するポイント
                <br />
                対象となるアクションを実行頂いたタイミングでポイントが反映されます。
                <br />
                通信状況等により多少の差異はあります。
                <br />
                <br />
                但し、キャンペーンなどによるポイント進呈のタイミングは上記の限りではありません。
              </p>
            </div>
          </SPTopExtendQuestionCollapse>
        </div>

        <div className='flex items-end gap-[3px] mt-[19px] justify-center'>
          <a
            target='_blank'
            href='https://secure.okbiz.jp/swe/category/show/37?key=400000147'
            className='text-[16px] leading-[32px] tracking-[0.48px] font-medium underline text-center'
          >
            よくある質問をもっと見る
          </a>
          <CopySvg className='mb-[5px]' />
        </div>
      </div>
    </div>
  )
}
