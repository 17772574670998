import { SPFooter } from 'components/common/footer/sp'
import { SPPointPart01 } from './part-01'
import { SPPointPart02 } from './part-02'
import { SPPointPart03 } from './part-03'
import { SPPointPart04 } from './part-04'
import { SPPointPart05 } from './part-05'
import { SPPointPart06 } from './part-06'
import { SPPointPart07 } from './part-07'
import { SPHeader } from 'components/common/header/sp'
import { SPPointContact } from './contact'
import { Fragment } from 'react'
import { PointTextPreload } from 'components/common/loader/point'

export function SPPoint() {
  return (
    <Fragment>
      <PointTextPreload />
      <div className='bg-primary-100'>
        <SPPointContact isPointPage />
        <SPHeader />
        <SPPointPart01 />
        <SPPointPart02 />
        <SPPointPart03 />
        <SPPointPart04 />
        <SPPointPart05 />
        <SPPointPart06 />
        <SPPointPart07 />
        <SPFooter />
      </div>
    </Fragment>
  )
}
