import { PCDownloadModal } from 'components/common/modal'
import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { CopySvg } from 'components/top/extend/svg/copy'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { useState } from 'react'
import { ELEMENT_ID, ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function PCPointPart05() {
  const [open, setOpen] = useState(false)

  const onOpenModal = () => {
    document.documentElement.classList.toggle('block', true)
    setOpen(true)
  }

  const onCloseModal = () => {
    document.documentElement.classList.toggle('block', false)
    setOpen(false)
  }

  return (
    <div className='w-full flex flex-col items-center bg-illust d-pb-[80px] d-px-[40px]'>
      <PCDownloadModal open={open} onClose={onCloseModal} />
      <div className='flex items-center d-gap-[8px] d-mt-[80px]'>
        <LeftSlashSvg className='d-w-[14px] d-h-[21px]' />
        <p className='font-semibold d-text-[32px] d-leading-[58px] d-tracking-[3.2px]'>
          ポイントの始め方
        </p>
        <RightSlashSvg className='d-w-[14px] d-h-[21px]' />
      </div>

      <p className='d-text-[16px] d-leading-[30px] d-tracking-[1.6px] d-mt-[8px] font-medium'>
        Comadoアプリでポイントを貯めるには「定期お届けコース」のお申し込みが必要です。
      </p>

      <div className='max-w-[1000px] w-full flex flex-col d-mt-[32px]'>
        <div className='flex items-center d-gap-[12px]'>
          <div
            aria-hidden
            className='d-w-[18px] d-h-[18px] rounded-full bg-accent-green'
          />
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            定期お届けコースにお申し込み済みの方
          </p>
        </div>

        <div className='w-full bg-white d-rounded-[10px] d-mt-[16px] d-pt-[40px] d-px-[48px] d-pb-[48px]'>
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            1.Comadoにログイン
          </p>
          <div className='d-mt-[12px] d-border-t-[4px] border-t-primary-600 w-full' />
          <div className='flex items-center d-px-[32px] d-mt-[24px] d-gap-[32px]'>
            <img
              src='/images/point/part-05/pic-01.webp'
              alt='pic-01'
              className='d-w-[320px] d-h-[320px] object-contain'
            />
            <div className='flex flex-col'>
              <p className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium'>
                Comadoアプリを左のコードからダウンロード。
                <br />
                サントリーウエルネスでログインした
                <br />
                アカウント情報で、Comadoに ログインしてください。
              </p>
              <a
                className='flex items-center d-mt-[24px] w-fit'
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
              >
                <span className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium underline'>
                  ログイン方法はこちら
                </span>
                <ArrowDownSvg className='d-w-[20px] d-h-[20px] transform -rotate-90' />
              </a>
              <p className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] d-mt-[44px] font-medium'>
                アプリのダウンロードがまだの方はこちら
              </p>

              <button
                {...getGTM({
                  event_name: 'download_click',
                  content_type: 'Point',
                  content_id: '訴求エリア_DL',
                })}
                onClick={onOpenModal}
                type='button'
                className='outline-none bg-[#494949] hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center w-fit d-gap-[15px] d-py-[8px] d-pl-[60px] d-pr-[24px] d-mt-[8px] d-border-[2px] border-black'
                style={{ boxShadow: '0px 4px 0px 0px black' }}
              >
                <span className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] font-semibold text-white'>
                  無料でダウンロード
                </span>
                <ArrowDownSvg className='d-w-[24px] d-h-[24px] text-primary-600 transform -rotate-90' />
              </button>
            </div>
          </div>

          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] d-mt-[24px] font-semibold'>
            2.チャレンジを達成する
          </p>
          <div className='d-mt-[12px] d-border-t-[4px] border-t-primary-600 w-full' />
          <div className='flex d-px-[32px] d-mt-[24px] d-gap-[32px]'>
            <img
              src='/images/point/part-05/pic-02.webp'
              alt='pic-02'
              className='d-w-[320px] d-h-[320px] object-contain'
            />
            <p className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium'>
              下のタブから「チャレンジ」をタップし、
              <br />
              該当の健康行動を達成したら
              <br />
              青いバナーが出てきます。
              <br />
              青いバナーが出たらポイント獲得です。
            </p>
          </div>
        </div>

        <div className='flex items-center d-gap-[12px] d-mt-[40px]'>
          <div
            aria-hidden
            className='d-w-[18px] d-h-[18px] rounded-full bg-accent-green'
          />
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            定期お届けコースに申し込んでいない方
          </p>
        </div>

        <div className='w-full bg-white d-rounded-[10px] d-mt-[16px] d-py-[40px] d-px-[48px]'>
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            定期お届けコースお申し込み方法
          </p>
          <div className='d-mt-[12px] d-border-t-[4px] border-t-primary-600 w-full' />
          <div className='flex items-center d-px-[32px] d-mt-[24px] d-gap-[32px]'>
            <img
              src='/images/point/part-05/pic-03.webp'
              alt='pic-03'
              className='d-w-[320px] d-h-[320px] object-contain'
            />
            <div className='flex flex-col'>
              <p className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium'>
                サントリーウエルネスのwebサイトで商品を
                <br />
                ご注文いただく際、各商品「定期お届けコース」
                <br />
                枠内の『買い物かごに入れる』ボタンをクリックして定期お届けコースにお申し込みください。
              </p>
              <a
                className='flex items-center d-mt-[24px] w-fit'
                href='https://www.suntory-kenko.com/service/regular/?key=400000144'
                target='_blank'
              >
                <span className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium underline'>
                  定期お届けコースとは？
                </span>
                <CopySvg className='d-w-[20px] d-h-[20px]' />
              </a>
              <p className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] d-mt-[44px] font-medium'>
                サントリーウエルネスwebサイトで商品を確認できます
              </p>

              <a
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtostart',
                  content_id: '商品を選ぶ',
                })}
                href='https://www.suntory-kenko.com/?key=400000146'
                target='_blank'
                className='outline-none bg-primary-600 hover:bg-primary-700 hover:border-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center w-fit d-gap-[49px] d-py-[12px] d-pl-[90px] d-pr-[18px] d-mt-[8px] d-border-[2px] border-[#D7A905]'
                style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
              >
                <span className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                  商品を選ぶ
                </span>
                <ArrowDownSvg className='d-w-[24px] d-h-[24px] transform -rotate-90' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
