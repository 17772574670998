import { ComponentPropsWithoutRef, ReactNode, useRef } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

interface CollapseProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'title'> {
  title: ReactNode
  duration?: number
}

const DURATION = 0.25

export function SPTopExtendQuestionCollapse({
  children,
  title,
  duration = DURATION,
  ...more
}: CollapseProps) {
  const controls = useAnimationControls()
  const vertical = useAnimationControls()
  const horizontal = useAnimationControls()

  const block = useRef<boolean>(false)
  const isOpen = useRef<boolean>(false)

  const toggle = async () => {
    if (!block.current) {
      isOpen.current = !isOpen.current
      block.current = true
      vertical.start({
        opacity: isOpen.current ? 0 : 1,
        transition: { duration },
      })
      horizontal.start({
        rotate: isOpen.current ? 180 : 0,
        transition: { duration },
      })
      await controls.start({
        height: isOpen.current ? 'auto' : 0,
        transition: { duration },
      })
      block.current = false
    }
  }

  return (
    <div className='flex flex-col bg-white rounded-lg'>
      <button
        type='button'
        className='w-full flex items-center justify-between gap-2 p-4'
        onClick={toggle}
        {...more}
      >
        <img
          src='/images/top-extend/questions/q.webp'
          alt='q-icon'
          className='w-[28px] object-contain'
        />
        <p className='text-[16px] grow text-left font-semibold leading-[1.87] tracking-[1.6px]'>
          {title}
        </p>

        <div
          className='w-[24px] h-[24px] rounded-full bg-primary-600 relative flex items-center justify-center shrink-0'
          style={{ boxShadow: '0px 2px 0px 0px #D7A905' }}
        >
          <motion.div
            aria-hidden
            animate={horizontal}
            className='w-[12px] h-[2px] rounded-full bg-text-color'
          />
          <motion.div
            aria-hidden
            animate={vertical}
            className='h-[12px] w-[2px] rounded-full bg-text-color absolute'
          />
        </div>
      </button>
      <motion.div animate={controls} className='overflow-hidden h-0'>
        {children}
      </motion.div>
    </div>
  )
}
