import { PCContactModal } from 'components/common/contact/pc'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { getGTM } from 'utils/functions'

export function Admin() {
  const [pcOpen, setPcOpen] = useState(false)

  return (
    <div className='flex flex-col gap-[8px]'>
      <PCContactModal open={pcOpen} onClose={() => setPcOpen(false)} />
      <img
        src='/images/assets/bubble.png'
        alt='bubble'
        className='d-w-[135px] object-contain cursor-pointer'
        onClick={() => setPcOpen(true)}
        {...getGTM({
          event_name: 'button_click',
          content_type: 'contact_icon',
          content_id: 'お問い合わせ_アイコン',
        })}
      />
      <div
        className='relative flex items-center justify-center cursor-pointer'
        onClick={() => setPcOpen(true)}
        {...getGTM({
          event_name: 'button_click',
          content_type: 'contact_icon',
          content_id: 'お問い合わせ_アイコン',
        })}
      >
        <motion.div
          whileHover={{
            scale: 1.2,
            transition: {
              duration: 1,
              bounce: 0.7,
              type: 'spring',
            },
          }}
          className='aspect-square rounded-full bg-[#85CDC5] border-2 border-white peer d-w-[96px]'
        >
          <div className='shrink-0 aspect-square overflow-hidden rounded-full'>
            <img
              src='/images/assets/admin.png'
              alt='admin'
              className='w-[80%] object-contain transform translate-x-[4px] translate-y-[8px]'
            />
          </div>
        </motion.div>

        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 19 19'
          className='absolute right-0 bottom-0 transform translate-x-[-50%] text-[#85CDC5] peer-hover:text-text-color transition d-w-[32px] d-h-[32px]'
        >
          <path
            d='M17.0682 9.87784C17.0682 14.2459 13.5272 17.7869 9.15909 17.7869C4.79102 17.7869 1.25 14.2459 1.25 9.87784C1.25 5.50977 4.79102 1.96875 9.15909 1.96875C13.5272 1.96875 17.0682 5.50977 17.0682 9.87784Z'
            fill='currentColor'
            stroke='white'
          />
          <path
            d='M8.9714 12.9662C9.58231 12.9662 9.91322 13.3862 9.91322 13.8953C9.91322 14.4172 9.55686 14.8244 8.94595 14.8244C8.4114 14.8244 8.01686 14.4426 8.01686 13.9081C8.01686 13.3481 8.38595 12.9662 8.9714 12.9662ZM9.27686 5.40625C10.9059 5.40625 11.8732 6.44989 11.8732 7.73534C11.8732 8.66443 11.3387 9.37716 10.7532 9.78443L10.7473 9.78861C9.88701 10.3959 9.72231 10.5121 9.72231 11.4899C9.72231 11.6935 9.62049 11.7953 9.45504 11.8208C9.35322 11.8335 9.16231 11.8335 8.9714 11.8335C8.78049 11.8335 8.58958 11.8335 8.47504 11.8208C8.2714 11.7953 8.19504 11.719 8.18231 11.4899C8.15686 10.4462 8.39868 9.84807 9.45504 9.10988C10.0278 8.70261 10.295 8.29534 10.295 7.8117C10.295 7.30261 9.96413 6.80625 9.14958 6.80625C8.57686 6.80625 8.24595 7.06079 7.91504 7.60807C7.83868 7.74807 7.73686 7.79898 7.62231 7.79898C7.39322 7.79898 6.90958 7.5317 6.7314 7.40443C6.56595 7.28989 6.52777 7.14989 6.60413 6.98443C7.07504 5.88989 8.04231 5.40625 9.27686 5.40625Z'
            fill='white'
          />
        </svg>
      </div>
    </div>
  )
}
