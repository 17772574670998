import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { Variants, motion } from 'framer-motion'

export function PCPointPart02() {
  const edgeBubble: Variants = {
    initial: { opacity: 0, scale: 0.5, y: 150 },
    animate: {
      opacity: 1,
      scale: 1,
      y: 40,
      transition: { type: 'spring', duration: 1.5, bounce: 0.5 },
    },
  }

  const centerBubble: Variants = {
    initial: { opacity: 0, scale: 0.5, y: 100 },
    animate: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { type: 'spring', duration: 1.5, bounce: 0.5 },
    },
  }

  return (
    <div className='relative flex flex-col items-center bg-primary-100 d-px-[40px]'>
      <img
        src='/images/point/part-02/left-leaf.webp'
        alt='left-leaf'
        className='d-w-[222px] d-left-[-16px] top-0 object-contain absolute'
      />
      <img
        src='/images/point/part-02/right-leaf.webp'
        alt='right-leaf'
        className='d-w-[222px] d-right-[-3px] object-contain absolute'
      />

      <img
        src='/images/point/part-02/pig.webp'
        alt='pig'
        className='d-w-[68px] d-h-[68px] d-mt-[80px] object-contain'
      />

      <div className='flex items-center d-gap-[8px] d-mt-[10px]'>
        <LeftSlashSvg className='d-w-[11px] d-h-[16px]' />
        <p className='font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
          健康行動でポイントを貯める
        </p>
        <RightSlashSvg className='d-w-[11px] d-h-[16px]' />
      </div>

      <div className='flex items-baseline d-mt-[21px]'>
        <p className='d-text-[34px] d-leading-[48px] d-tracking-[1.7px] font-semibold'>
          Comadoアプリの
        </p>
        <div className='relative d-ml-[26px]'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='17'
            viewBox='0 0 25 17'
            fill='none'
            className='absolute d-top-[-11px] d-left-[-32px]'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M14.291 10.463C14.056 6.61 17.926 2.346 20.621.08c.258-.216.62.04.511.36a23.949 23.949 0 0 0-.815 3.058c-.217 1.104.65 2.076 1.653 2.588a5.285 5.285 0 1 1-7.678 4.377Zm-13.781 0C.275 6.61 4.145 2.346 6.84.08c.258-.216.62.04.511.36a23.947 23.947 0 0 0-.815 3.058c-.218 1.104.65 2.076 1.652 2.588A5.285 5.285 0 1 1 .51 10.463Z'
              fill='#FFD335'
            />
          </svg>
          <p className='d-text-[34px] d-leading-[48px] d-tracking-[1.7px] font-semibold'>
            チャレンジ
          </p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='18'
            viewBox='0 0 25 18'
            fill='none'
            className='absolute d-right-[-30px] d-bottom-[5px]'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M11.123 6.615c.235 3.852-3.635 8.117-6.33 10.383-.258.216-.62-.04-.511-.359.34-1.003.612-2.025.815-3.06.217-1.103-.65-2.075-1.653-2.587a5.285 5.285 0 1 1 7.678-4.377Zm13.765 0c.235 3.852-3.635 8.117-6.33 10.383-.258.216-.619-.04-.51-.359.34-1.003.611-2.025.815-3.06.217-1.103-.651-2.075-1.653-2.587a5.285 5.285 0 1 1 7.678-4.377Z'
              fill='#FFD335'
            />
          </svg>
        </div>
        <p className='d-ml-[38px] d-text-[22px] font-semibold d-leading-[39px] d-tracking-[2.2px]'>
          で貯まる！
        </p>
      </div>

      <p className='d-text-[16px] d-leading-[30px] d-tracking-[1.6px] d-mt-[18px] font-medium text-center'>
        チャレンジとは、Comado内での特定の「健康行動」を指します。
        <br />
        チャレンジを達成するとポイントが獲得できます。
      </p>

      <p className='d-text-[14px] d-leading-[20px] d-mt-[16px] text-center'>
        ※ポイントは商品購入時にも獲得できます。
      </p>

      <p className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] d-mt-[24px] font-semibold'>
        チャレンジの例
      </p>

      <motion.div
        initial='initial'
        transition={{ staggerChildren: 0.25 }}
        whileInView='animate'
        viewport={{ margin: '0px 0px -30% 0px', once: true }}
        className='flex items-start d-mt-[8px]'
      >
        <motion.img
          variants={edgeBubble}
          src='/images/point/part-02/bubble-01.webp'
          alt='bubble-01'
          className='d-w-[284px] d-h-[337px] object-contain'
        />
        <motion.img
          variants={centerBubble}
          src='/images/point/part-02/bubble-02.webp'
          alt='bubble-02'
          className='d-w-[284px] d-h-[337px] object-contain'
        />
        <motion.img
          variants={edgeBubble}
          src='/images/point/part-02/bubble-03.webp'
          alt='bubble-02'
          className='d-w-[284px] d-h-[337px] d-translate-y-[40px] object-contain'
        />
      </motion.div>

      <div className='relative'>
        <div
          aria-hidden
          className='w-full h-[50%] absolute bottom-0 bg-light-green'
        />
        <div
          className={`relative w-screen d-mt-[2px] d-pb-[63px] pt-[max(0px,calc(6.5792vw-100px))] flex flex-col items-center background-point-pc-part-02 bg-[length:100vw_auto] bg-no-repeat bg-top`}
        >
          <img
            src='/images/point/part-02/bird.webp'
            alt='bird'
            className='d-w-[323px] d-h-[117px] object-contain'
          />
          <p className='d-mt-[40px] d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-semibold'>
            商品購入・チャレンジによるポイント獲得で年間…
          </p>
          <img
            src='/images/point/part-02/point.webp'
            alt='point'
            className='d-w-[614px] d-h-[94px] d-mt-[12px] object-contain'
          />
          <p className='d-text-[14px] d-leading-[20px] d-mt-[20px]'>
            ※オメガエイドをご購入のV I
            Pステージのお客様が、健康ポイントを日次チャレンジで5ポ
            <br />
            イント、週次チャレンジで2ポイントを1年間貯め、各種ポイントキャンペーンに参加いただ
            <br />
            いた場合。
            <br />
            ※チャレンジの内容は予告なく変動する可能性があります。
          </p>

          <div className='d-px-[40px]'>
            <div className='d-mt-[86px] max-w-[1000px] d-px-[16px] d-pt-[18px] d-pb-[14px] w-full relative d-rounded-[4px] background-point-pc-part-04 bg-[length:100%_auto] bg-repeat'>
              <div className='absolute w-full d-top-[-23px] left-0 flex justify-center'>
                <div
                  className='d-w-[74px] d-h-[74px] rounded-full bg-primary-800 absolute d-top-[-20px] flex items-center justify-center'
                  style={{ clipPath: 'inset(0 0 50% 0)' }}
                >
                  <div
                    aria-hidden
                    className='d-w-[12px] d-h-[12px] bg-light-green rounded-full d-translate-y-[-22px]'
                  />
                </div>
                <div className='d-pl-[40px] d-pr-[41px] d-pt-[8px] d-pb-[5px] bg-primary-800 d-rounded-[4px]'>
                  <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                    アプリのポイント確認方法
                  </p>
                </div>
              </div>

              <div className='bg-white w-full d-rounded-[8px] d-pl-[60px] d-pr-[78px] d-pb-[31px] d-pt-[38px] grid grid-cols-3 d-gap-[50px]'>
                <div className='flex flex-col'>
                  <img
                    src='/images/point/part-02/pic-01.webp'
                    alt='pic-01'
                    className='d-w-[243px] d-h-[216px] object-contain'
                  />
                  <div className='flex d-gap-[12px] d-mt-[12px]'>
                    <div className='d-w-[32px] d-h-[32px] rounded-full bg-accent-green flex items-center justify-center d-text-[21px] font-semibold text-white'>
                      1
                    </div>
                    <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-semibold'>
                      チャレンジはタブから
                      <br />
                      簡単にアクセス
                    </p>
                  </div>
                </div>

                <div className='flex flex-col'>
                  <img
                    src='/images/point/part-02/pic-02.webp'
                    alt='pic-02'
                    className='d-w-[243px] d-h-[216px] object-contain'
                  />
                  <div className='flex d-gap-[12px] d-mt-[12px]'>
                    <div className='d-w-[32px] d-h-[32px] rounded-full bg-accent-green flex items-center justify-center d-text-[21px] font-semibold text-white'>
                      2
                    </div>
                    <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-semibold'>
                      チャレンジは
                      <br />
                      毎日更新されます。
                    </p>
                  </div>
                </div>

                <div className='flex flex-col'>
                  <img
                    src='/images/point/part-02/pic-03.webp'
                    alt='pic-03'
                    className='d-w-[243px] d-h-[216px] object-contain'
                  />
                  <div className='flex d-gap-[12px] d-mt-[12px]'>
                    <div className='d-w-[32px] d-h-[32px] rounded-full bg-accent-green flex items-center justify-center d-text-[21px] font-semibold text-white'>
                      3
                    </div>
                    <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-semibold'>
                      貯まったポイントは
                      <br />
                      チャレンジタブで
                      <br />
                      すぐに確認できます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
