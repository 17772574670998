import { ComponentPropsWithoutRef } from 'react'

export function EmailSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      {...props}
    >
      <rect x='2' y='4' width='16' height='12' rx='1' fill='black' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.4299 4.09679L10.1213 9.96124L2.73588 4.03516C2.31176 4.151 2 4.53905 2 4.99991V6.00894L8.86964 11.5212C9.60093 12.1079 10.6417 12.1079 11.373 11.5212L18 6.20363V4.99991C18 4.60153 17.7671 4.25757 17.4299 4.09679Z'
        fill='white'
      />
    </svg>
  )
}
