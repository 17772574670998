export function SPFirstViewNoAnimation() {
  return (
    <div className='w-full h-dvh bg-primary-600 flex px-4'>
      <div className='mx-auto max-w-[360px] w-full h-dvh flex flex-col'>
        <h1 className='text-center text-[min(4.8vh,32px)] font-semibold leading-[1.44] tracking-[3.2px] ds-mt-[86px]'>
          サプリ&スキンケア
          <br />
          生活に効くアプリ
        </h1>
        <img
          src='/images/top-sp/first-view/sp-first-view-main-pic-full.webp'
          alt='first-view-main-pic'
          className='max-w-[min(432px,47vh)] w-full mx-auto object-contain mt-6'
        />
      </div>
    </div>
  )
}
