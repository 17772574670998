export function FirstViewNoAnimation() {
  return (
    <div className='bg-primary-600'>
      <div className='w-full h-dvh d-px-[40px] bg-pc-first-view bg-no-repeat bg-cover'>
        <div className='flex items-center max-w-[1160px] overflow-hidden w-full h-dvh mx-auto'>
          <div className='flex flex-col shrink-0'>
            <img
              src='/images/assets/logo.svg'
              className='object-contain d-w-[220px]'
            />
            <h1 className='font-semibold d-text-[48px] d-leading-[78px] d-tracking-[4.8px] d-mt-[24px]'>
              サプリ&スキンケア
              <br /> 生活に効くアプリ
            </h1>
            <p className='d-mt-[16px] d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
              フィットネスや歩数など体と心にアプローチした
              <br />
              サントリーウエルネス会員限定の無料アプリです。
            </p>
            <div className='flex items-center bg-white w-fit d-rounded-[18px] d-py-[16px] d-pl-[33px] d-pr-[56px] d-gap-[16px] d-mt-[24px]'>
              <div className='flex flex-col'>
                <div className='flex items-baseline d-gap-[7px]'>
                  <div className='flex flex-col'>
                    <p className='font-semibold d-text-[32px] d-leading-[26px] d-tracking-[3.2px]'>
                      無料
                    </p>
                    <div className='w-full rounded-[1px] bg-primary-600 d-h-[5px] d-mt-[5px]' />
                  </div>
                  <p className='font-semibold d-text-[16px] d-tracking-[1.6px]'>
                    でご利用いただけます。
                  </p>
                </div>
                <p className='font-semibold d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                  右のコードからダウンロード！
                </p>
              </div>
              <img
                src='/images/top/first-view/pc-first-view-qr.webp'
                alt='qr'
                className='object-contain d-w-[88px]'
              />
            </div>
            <p className='d-text-[14px] d-leading-[20px] d-mt-[12px]'>
              ※定期お届けコース限定の機能やコンテンツもございます。
            </p>
          </div>
          <div className='flex relative d-pt-[80px]'>
            <img
              src='/images/top/first-view/pc-first-view-main-pic-full.webp'
              alt='main-pic'
              className='object-contain d-w-[827px]'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
