import { ComponentPropsWithoutRef, useRef } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

interface CollapseProps extends ComponentPropsWithoutRef<'button'> {
  number: number
  title: string
  duration?: number
}

const DURATION = 0.25

export function TopExtendDeviceCollapse({
  children,
  number,
  title,
  duration = DURATION,
  ...more
}: CollapseProps) {
  const panelControls = useAnimationControls()
  const arrowControls = useAnimationControls()

  const block = useRef<boolean>(false)
  const isOpen = useRef<boolean>(false)

  const toggle = async () => {
    if (!block.current) {
      isOpen.current = !isOpen.current
      block.current = true
      arrowControls.start({
        rotate: isOpen.current ? 180 : 0,
        transition: { duration },
      })
      await panelControls.start({
        height: isOpen.current ? 'auto' : 0,
        transition: { duration },
      })
      block.current = false
    }
  }

  return (
    <div className='flex flex-col border-border rounded-lg d-px-[24px] d-py-[16px] d-border-[2px]'>
      <button
        type='button'
        className='w-full flex items-center justify-between d-gap-[16px]'
        onClick={toggle}
        {...more}
      >
        <div className='flex items-center'>
          <div className='rounded-full grid place-content-center bg-accent-green d-w-[35px] d-h-[35px]'>
            <p className='text-white d-text-[24px] d-leading-[24px]'>
              {number}
            </p>
          </div>
          <p className='d-text-[24px] d-leading-[43px] d-tracking-[2.4px] d-ml-[16px] font-semibold'>
            {title}
          </p>
        </div>
        <motion.svg
          xmlns='http://www.w3.org/2000/svg'
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          animate={arrowControls}
          className='d-w-[21px] d-h-[20px]'
        >
          <path
            d='M11.0323 14.2973L16.8534 8.47626C17.6408 7.68881 17.0831 6.34238 15.9695 6.34238L4.32737 6.34238C3.21374 6.34238 2.65603 7.68881 3.44349 8.47626L9.26455 14.2973C9.75271 14.7855 10.5442 14.7855 11.0323 14.2973Z'
            fill='currentColor'
          />
        </motion.svg>
      </button>
      <motion.div animate={panelControls} className='overflow-hidden h-0'>
        {children}
      </motion.div>
    </div>
  )
}
