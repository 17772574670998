import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { Variants, motion } from 'framer-motion'

export function SPPointPart02() {
  const bubble01Variants: Variants = {
    initial: { y: 100, opacity: 0, scale: 0.5 },
    animate: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { duration: 1, type: 'spring', bounce: 0.3 },
    },
  }

  const bubble02Variants: Variants = {
    initial: { x: -10, y: 100, opacity: 0, scale: 0.5 },
    animate: {
      x: -10,
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { duration: 1, type: 'spring', bounce: 0.3, delay: 0.25 },
    },
  }

  const bubble03Variants: Variants = {
    initial: { y: 100, opacity: 0, scale: 0.5 },
    animate: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { duration: 1, type: 'spring', bounce: 0.3, delay: 0.5 },
    },
  }

  return (
    <div className='relative px-[12px] overflow-hidden'>
      <img
        src='/images/point-sp/part-02/left-leaf.webp'
        alt='left-leaf'
        className='absolute -left-[7px] -top-[6px] w-[100px] object-contain'
      />
      <img
        src='/images/point-sp/part-02/right-leaf.webp'
        alt='right-leaf'
        className='absolute -right-[7px] -top-[6px] w-[100px] object-contain'
      />
      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <img
          src='/images/point-sp/part-02/pig.webp'
          alt='pig'
          className='w-[40px] aspect-square mt-[76px] object-contain'
        />

        <div className='flex items-center gap-[5px] mt-[4px]'>
          <LeftSlashSvg className='w-[6px] h-[10px]' />
          <p className='font-semibold text-[16px] leading-[30px] tracking-[1.6px]'>
            健康行動でポイントを貯める
          </p>
          <RightSlashSvg className='w-[6px] h-[10px]' />
        </div>

        <div className='flex items-baseline justify-center flex-wrap mt-[21px]'>
          <p className='text-[20px] leading-[36px] tracking-[2px] font-semibold'>
            Comadoの
          </p>
          <div className='relative ml-[11px]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='11'
              height='7'
              viewBox='0 0 25 17'
              fill='none'
              className='absolute top-[5px] -left-[10px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.291 10.463C14.056 6.61 17.926 2.346 20.621.08c.258-.216.62.04.511.36a23.949 23.949 0 0 0-.815 3.058c-.217 1.104.65 2.076 1.653 2.588a5.285 5.285 0 1 1-7.678 4.377Zm-13.781 0C.275 6.61 4.145 2.346 6.84.08c.258-.216.62.04.511.36a23.947 23.947 0 0 0-.815 3.058c-.218 1.104.65 2.076 1.652 2.588A5.285 5.285 0 1 1 .51 10.463Z'
                fill='#FFD335'
              />
            </svg>
            <p className='text-[20px] leading-[36px] tracking-[2px] font-semibold'>
              チャレンジ
            </p>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='11'
              height='7'
              viewBox='0 0 25 18'
              fill='none'
              className='absolute right-[-10px] bottom-[8px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.123 6.615c.235 3.852-3.635 8.117-6.33 10.383-.258.216-.62-.04-.511-.359.34-1.003.612-2.025.815-3.06.217-1.103-.65-2.075-1.653-2.587a5.285 5.285 0 1 1 7.678-4.377Zm13.765 0c.235 3.852-3.635 8.117-6.33 10.383-.258.216-.619-.04-.51-.359.34-1.003.611-2.025.815-3.06.217-1.103-.651-2.075-1.653-2.587a5.285 5.285 0 1 1 7.678-4.377Z'
                fill='#FFD335'
              />
            </svg>
          </div>
          <p className='ml-[12px] text-[16px] font-semibold leading-[30px] tracking-[1.6px]'>
            で貯まる！
          </p>
        </div>

        <p className='mt-[12px] text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
          チャレンジとは、Comado内での特定の
          <br />
          「健康行動」を指します。チャレンジを
          <br />
          達成するとポイントが獲得できます。
        </p>

        <p className='mt-[4px] text-[11px] leading-[20px]'>
          ※ポイントは商品購入時にも獲得できます。
        </p>

        <p className='mt-[8px] text-[14px] leading-[28px] tracking-[1.4px] font-semibold'>
          チャレンジの例
        </p>

        <motion.div
          initial='initial'
          whileInView='animate'
          viewport={{ once: true, margin: '0px 0px -300px 0px' }}
          className='flex flex-col items-center'
        >
          <motion.img
            variants={bubble02Variants}
            src='/images/point-sp/part-02/bubble-02.webp'
            alt='bubble-02'
            className='ds-w-[168px] aspect-[168/181] object-contain'
          />

          <div className='flex items-center gap-[26px] -mt-[28px]'>
            <motion.img
              variants={bubble01Variants}
              src='/images/point-sp/part-02/bubble-01.webp'
              alt='bubble-01'
              className='ds-w-[154px] aspect-[154/179] object-contain'
            />
            <motion.img
              variants={bubble03Variants}
              src='/images/point-sp/part-02/bubble-03.webp'
              alt='bubble-03'
              className='ds-w-[154px] aspect-[154/179] object-contain'
            />
          </div>
        </motion.div>

        <img
          src='/images/point-sp/part-02/bird.webp'
          alt='bird'
          className='ds-w-[276px] aspect-[276/105] object-contain transform translate-x-[34px] -mt-[27px] z-10'
        />

        <div className='relative pb-[40px]'>
          <div
            aria-hidden
            className='absolute w-full h-[90%] bottom-0 bg-light-green'
          />
          <div
            className={`relative -mt-[22px] pt-[43px] px-[12px] w-screen background-point-sp-part-02 bg-[length:100vw_auto] bg-no-repeat bg-top`}
          >
            <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
              <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold text-center'>
                商品購入・チャレンジによる
                <br />
                ポイント獲得で年間…
              </p>

              <img
                src='/images/point-sp/part-02/point.webp'
                alt='point'
                className='w-[289px] aspect-[289/55] mt-[12px] object-contain'
              />
              <p className='text-[16px] leading-[30px] tracking-[1.6px] mt-[12px] font-semibold'>
                ポイント以上貯まる！
              </p>
              <p className='text-[11px] leading-[20px] mt-[12px]'>
                ※オメガエイドをご購入のV I
                Pステージのお客様が、健康ポイントを日次チャレンジで5ポイント、週次チャレンジで2ポイントを1年間貯め、各種ポイントキャンペーンに参加いただいた場合。
                <br />
                ※チャレンジの内容は予告なく変動する可能性があります。
              </p>

              <div
                className={`mt-[47px] w-full relative px-[16px] py-[18px] rounded-[4px] background-point-sp-part-04 bg-[length:100%_auto] bg-repeat`}
              >
                <div className='absolute w-full -top-[23px] left-0 flex justify-center'>
                  <div
                    className='w-[74px] h-[74px] rounded-full bg-primary-800 absolute -top-[20px] flex items-center justify-center'
                    style={{ clipPath: 'inset(0 0 50% 0)' }}
                  >
                    <div
                      aria-hidden
                      className='w-[12px] h-[12px] bg-light-green rounded-full transform -translate-y-[22px]'
                    />
                  </div>
                  <div className='pl-[14px] pr-[14px] pt-[8px] pb-[5px] bg-primary-800 rounded-[4px]'>
                    <p className='text-[18px] leading-[36px] tracking-[1.8px] font-semibold'>
                      アプリのポイント確認方法
                    </p>
                  </div>
                </div>

                <div className='bg-white w-full rounded-[8px] pl-[32px] pr-[38px] pb-[25px] flex flex-col'>
                  <div className='flex gap-[12px] mt-[44px]'>
                    <div className='w-[25px] h-[25px] mt-[4px] rounded-full bg-accent-green flex items-center justify-center text-[18px] font-semibold text-white'>
                      1
                    </div>
                    <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                      チャレンジはタブから
                      <br />
                      簡単にアクセス
                    </p>
                  </div>
                  <img
                    src='/images/point-sp/part-02/pic-01.webp'
                    alt='pic-01'
                    className='w-full aspect-[258/230] object-contain mt-[16px]'
                  />

                  <div className='flex gap-[12px] mt-[24px]'>
                    <div className='w-[25px] h-[25px] mt-[4px] rounded-full bg-accent-green flex items-center justify-center text-[18px] font-semibold text-white'>
                      2
                    </div>
                    <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                      チャレンジは
                      <br />
                      毎日更新されます
                    </p>
                  </div>
                  <img
                    src='/images/point-sp/part-02/pic-02.webp'
                    alt='pic-02'
                    className='w-full aspect-[258/230] object-contain mt-[16px]'
                  />

                  <div className='flex gap-[12px] mt-[24px]'>
                    <div className='w-[25px] h-[25px] mt-[4px] rounded-full bg-accent-green flex items-center justify-center text-[18px] font-semibold text-white'>
                      3
                    </div>
                    <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                      貯まったポイントは
                      <br />
                      チャレンジタブで
                      <br />
                      すぐに確認できます。
                    </p>
                  </div>
                  <img
                    src='/images/point-sp/part-02/pic-03.webp'
                    alt='pic-03'
                    className='w-full aspect-[258/230] object-contain mt-[16px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
