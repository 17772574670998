import { PCFooter } from 'components/common/footer/pc'
import { PCPointPart01 } from './part-01'
import { PCPointPart02 } from './part-02'
import { PCPointPart03 } from './part-03'
import { PCPointPart04 } from './part-04'
import { PCPointPart05 } from './part-05'
import { PCPointPart06 } from './part-06'
import { PCPointPart07 } from './part-07'
import { PCHeader } from '../common/header/pc'
import { Admin } from 'components/base/Admin'

export function PCPoint() {
  return (
    <div className='bg-primary-100'>
      <div className='fixed d-left-[16px] d-bottom-[16px] z-50'>
        <Admin />
      </div>
      <PCHeader />
      <PCPointPart01 />
      <PCPointPart02 />
      <PCPointPart03 />
      <PCPointPart04 />
      <PCPointPart05 />
      <PCPointPart06 />
      <PCPointPart07 />
      <PCFooter />
    </div>
  )
}
