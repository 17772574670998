import { useEffect, useState } from 'react'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { SPTopExtendDeviceCollapse } from './collapse'
import { YellowButton } from 'components/base/YellowButton'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { ELEMENT_ID, MAIL_TO, TEL_TO } from 'utils/constant'
import { EmailSvg } from 'components/top/extend/svg/email'
import { SPPointVideoPlayer } from './video'
import { getGTM } from 'utils/functions'

export function SPTopExtendDevice() {
  const [value, setValue] = useState(0)
  const [openVideo, setOpenVideo] = useState(false)
  const [youtubeCode, setYoutubeCode] = useState('')

  useEffect(() => {
    const googleElements = document.querySelectorAll('.js-google')
    const appleElements = document.querySelectorAll('.js-apple')

    googleElements.forEach((el: any) => {
      el.style.display = value === 0 ? 'block' : 'none'
    })
    appleElements.forEach((el: any) => {
      el.style.display = value === 1 ? 'block' : 'none'
    })
  }, [value])

  const onOpenVideo = (code: string) => {
    document.documentElement.classList.toggle('block', true)
    setYoutubeCode(code)
    setOpenVideo(true)
  }

  const onCloseVideo = () => {
    document.documentElement.classList.toggle('block', false)
    setOpenVideo(false)
  }

  return (
    <div
      id={ELEMENT_ID.HOW_TO_USER}
      className='max-w-[360px] pt-10 mx-auto w-full flex flex-col items-center relative'
    >
      <SPPointVideoPlayer
        youtubeCode={youtubeCode}
        open={openVideo}
        onClose={onCloseVideo}
      />
      <div className='flex items-center gap-2'>
        <LeftSlashSvg className='w-2 h-3' />
        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px]'>
          ご利用方法
        </p>
        <RightSlashSvg className='w-2 h-3' />
      </div>

      <div className='flex w-full mt-4'>
        <button
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'howtouse',
            content_id: 'Android',
          })}
          type='button'
          className='h-[36px] grid place-content-center outline-none border-none px-4 rounded-t transition'
          onClick={() => setValue(0)}
          style={{
            backgroundColor: value === 0 ? 'white' : '#dee9f9',
          }}
        >
          <p
            className='text-[14px] font-semibold leading-[1.8] tracking-[1.4px] transition'
            style={{
              opacity: value === 0 ? '1' : '0.2',
            }}
          >
            Android
          </p>
        </button>
        <button
          {...getGTM({
            event_name: 'tab_click',
            content_type: 'howtouse',
            content_id: 'IPhone',
          })}
          type='button'
          className='h-[36px] grid place-content-center outline-none border-none px-4 rounded-t transition'
          onClick={() => setValue(1)}
          style={{
            backgroundColor: value === 1 ? 'white' : '#dee9f9',
          }}
        >
          <p
            className='text-[14px] font-semibold leading-[1.8] tracking-[1.4px] transition'
            style={{
              opacity: value === 1 ? '1' : '0.2',
            }}
          >
            iPhone
          </p>
        </button>
      </div>

      <div className='flex flex-col px-4 py-6 w-full bg-white rounded-b-lg rounded-tr-lg'>
        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px]'>
          ダウンロード方法
        </p>
        <span
          aria-hidden
          className='w-full border-t-[3px] border-primary-600 mt-1'
        />
        <div className='mt-4'>
          <SPTopExtendDeviceCollapse
            title={
              value === 0 ? 'Google Playにアクセス' : 'App Storeにアクセス'
            }
            number={1}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'ストアアクセス',
            })}
          >
            <div className='pt-4 pb-1 flex flex-col'>
              <img
                src='/images/top-extend/devices/android/pic-01.webp'
                alt='step-01'
                className='js-google w-full object-contain'
              />
              <img
                src='/images/top-extend/devices/apple/pic-01.webp'
                alt='step-01'
                className='js-apple hidden w-full object-contain'
              />
              <p className='js-google text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                スマホのカメラで二次元コードを読み取るか、 Google
                Playを開き「Comado」と検索してください。
              </p>
              <p className='js-apple hidden text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                スマホのカメラで二次元コードを読み取るか、 App
                Storeを開き「Comado」と検索してください。
              </p>
              <p className='js-google leading-[1.42] text-center mb-1 mt-4'>
                Playストアにアクセス編動作動画
              </p>
              <p className='js-apple hidden leading-[1.42] text-center mb-1 mt-4'>
                App storeにアクセス編動作動画
              </p>
              <YellowButton
                onClick={() =>
                  onOpenVideo(value === 0 ? 'dURa7nlI2WY' : 'X-g-V28-opA')
                }
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtouse',
                  content_id: 'ストアアクセス',
                })}
              >
                動画を再生
              </YellowButton>
            </div>
          </SPTopExtendDeviceCollapse>
        </div>
        <div className='mt-3'>
          <SPTopExtendDeviceCollapse
            title='アプリをダウンロード'
            number={2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'アプリダウンロード',
            })}
          >
            <div className='pt-4 pb-1 flex flex-col'>
              <img
                src='/images/top-extend/devices/android/pic-02.webp'
                alt='step-02'
                className='js-google w-full object-contain'
              />
              <img
                src='/images/top-extend/devices/apple/pic-02.webp'
                alt='step-02'
                className='js-apple hidden w-full object-contain'
              />
              <p className='js-google text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                「インストール」をタップして
                <br />
                アプリをダウンロードしましょう。
                <br />
                ※アプリをインストールするには、 google
                playのアカウントが必要になります。
              </p>
              <p className='js-apple hidden text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                「インストール」をタップして
                <br />
                アプリをダウンロードしましょう。
                <br />
                ※アプリをインストールするには、apple IDが 必要になります。
              </p>
              <p className='leading-[1.42] text-center mb-1 mt-4'>
                アプリダウンロード編動作動画
              </p>
              <YellowButton
                onClick={() =>
                  onOpenVideo(value === 0 ? 'edEcUtkr6VY' : 'eTZ41BEr87U')
                }
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtouse',
                  content_id: 'アプリダウンロード',
                })}
              >
                動画を再生
              </YellowButton>
            </div>
          </SPTopExtendDeviceCollapse>
        </div>
        <div className='mt-3'>
          <SPTopExtendDeviceCollapse
            title='Comadoアプリを開く'
            number={3}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'comadoアプリを開く',
            })}
          >
            <div className='pt-4 pb-1 flex flex-col'>
              <img
                src='/images/top-extend/devices/android/pic-03.webp'
                alt='step-03'
                className='js-google w-full object-contain'
              />
              <img
                src='/images/top-extend/devices/apple/pic-03.webp'
                alt='step-03'
                className='js-apple hidden w-full object-contain'
              />
              <p className='js-google text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                ボタンが 「開く」に変わったら
                <br />
                タップしてComadoアプリを開きましょう。
              </p>
              <p className='js-apple hidden text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                ボタンが 「開く」に変わったら
                <br />
                タップしてComadoアプリを開きましょう。
              </p>
              <p className='leading-[1.42] text-center mb-1 mt-4'>
                Comadoアプリの開き方編動画
              </p>
              <YellowButton
                onClick={() =>
                  onOpenVideo(value === 0 ? 'umIp_1yj1Ds' : 'tZfVbijlghY')
                }
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtouse',
                  content_id: 'comadoアプリを開く',
                })}
              >
                動画を再生
              </YellowButton>
            </div>
          </SPTopExtendDeviceCollapse>
        </div>

        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px] mt-8'>
          ログイン方法
        </p>
        <span
          aria-hidden
          className='w-full border-t-[3px] border-primary-600 mt-1'
        />

        <div className='mt-4'>
          <SPTopExtendDeviceCollapse
            title='氏名・電話番号の入力'
            number={1}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: '氏名・電話番号の入力',
            })}
          >
            <div className='pt-4 pb-1 flex flex-col'>
              <img
                src='/images/top-extend/devices/android/pic-04.webp'
                alt='step-04'
                className='js-google w-full object-contain'
              />
              <img
                src='/images/top-extend/devices/apple/pic-04.webp'
                alt='step-04'
                className='js-apple hidden w-full object-contain'
              />
              <p className='js-google text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                氏名をひらがなで入力してください。
              </p>
              <p className='js-apple hidden text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                氏名をひらがなで入力してください。
              </p>
              <p className='leading-[1.42] text-center mb-1 mt-4'>
                氏名・電話番号の入力編
              </p>
              <YellowButton
                onClick={() =>
                  onOpenVideo(value === 0 ? 'ZpJBobKDzEU' : 'mVI-t7bZl-g')
                }
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtouse',
                  content_id: '氏名・電話番号の入力',
                })}
              >
                動画を再生
              </YellowButton>
            </div>
          </SPTopExtendDeviceCollapse>
        </div>
        <div className='mt-3'>
          <SPTopExtendDeviceCollapse
            title='お客様番号の入力'
            number={2}
            {...getGTM({
              event_name: 'accordion_click',
              content_type: 'howtouse',
              content_id: 'お客様番号の入力',
            })}
          >
            <div className='pt-4 pb-1 flex flex-col'>
              <img
                src='/images/top-extend/devices/android/pic-05.webp'
                alt='step-05'
                className='js-google w-full object-contain'
              />
              <img
                src='/images/top-extend/devices/apple/pic-05.webp'
                alt='step-05'
                className='js-apple hidden w-full object-contain'
              />
              <p className='js-google text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                最後にお客様番号を入力しログイン完了です。
                <br />
                お客様番号は、DM・ECサイト・領収書に 記載されています。
              </p>
              <p className='js-apple hidden text-[16px] font-medium leading-[2] tracking-[0.48px] mt-2'>
                最後にお客様番号を入力しログイン完了です。
                <br />
                お客様番号は、DM・ECサイト・領収書に 記載されています。
              </p>
              <p className='leading-[1.42] text-center mb-1 mt-4'>
                お客様番号入力編
              </p>
              <YellowButton
                onClick={() =>
                  onOpenVideo(value === 0 ? 'p_2bm_ATnZ4' : 'XGzxREeJNRE')
                }
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'howtouse',
                  content_id: 'お客様番号の入力',
                })}
              >
                動画を再生
              </YellowButton>
            </div>
          </SPTopExtendDeviceCollapse>
        </div>

        <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px] mt-8'>
          お困りの方はこちら
        </p>
        <span
          aria-hidden
          className='w-full border-t-[3px] border-primary-600 mt-1'
        />

        <p className='text-[14px] leading-[1.43] mt-4'>
          アプリのダウンロードや、アプリの操作に
          <br />
          ついてお気軽にお問い合わせください。
        </p>

        <div className='mt-4 border-2 rounded-[10px] border-border pt-[24px] px-[16px] pb-[16px] flex flex-col items-center w-full'>
          <div className='flex items-center justify-center gap-[8px]'>
            <PhoneSvg className='w-[20x] h-[20px]' />
            <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
              電話でのお問い合わせ
            </p>
          </div>
          <a
            href={TEL_TO}
            target='_blank'
            className='text-[28px] leading-[29px] tracking-[2.8px] mt-[8px] underline'
          >
            0120-630-310
          </a>
          <p className='text-center text-[16px] font-medium leading-[32px] tracking-[0.48px] mt-[8px]'>
            受付時間：9:00～20:00
            <br />
            （土日祝日も承ります）
          </p>

          <div className='flex flex-col items-center mt-[16px] pt-[16px] border-t-[2px] border-t-border w-full'>
            <div className='flex items-center justify-center gap-[8px]'>
              <EmailSvg className='w-[20px] h-[20px] mb-[2px]' />
              <p className='text-[16px] font-semibold leading-[30px] tracking-[1.6px]'>
                メールでのお問い合わせ
              </p>
            </div>
            <p className='text-[16px] leading-[30px] tracking-[1.6px] font-medium mt-[8px]'>
              info@comado.com
            </p>
            <a
              href={MAIL_TO}
              target='_blank'
              className='w-full mt-[8px]'
              {...getGTM({
                event_name: 'button_click',
                content_type: 'send_mail',
                content_id: 'お問い合わせ_ご利用方法',
              })}
            >
              <YellowButton>メールを送る</YellowButton>
            </a>
            <p className='mt-[8px] text-[11px] leading-[20px]'>
              ※メールアプリが立ち上がります。
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
