import { SPDownloadButton } from 'components/base/DownloadButton'
import { getGTM } from 'utils/functions'

export function SPPointPart03() {
  return (
    <div className='bg-primary-600 px-[16px] pb-[32px]'>
      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <p className='mt-[40px] text-center text-[22px] leading-[36px] tracking-[2.2px] font-semibold'>
          Comadoアプリを使って
          <br />
          ポイントを獲得しよう
        </p>

        <img
          src='/images/point-sp/part-03/main.webp'
          alt='main'
          className='w-[335px] aspect-[335/281] mt-[24px] object-contain'
        />

        <div className='w-full mt-[24px] px-[16px]'>
          <a
            target='_blank'
            href='https://comado.info/173/'
            {...getGTM({
              event_name: 'download_click',
              content_type: 'TOP',
              content_id: '訴求エリア_DL',
            })}
          >
            <SPDownloadButton />
          </a>
        </div>
      </div>
    </div>
  )
}
