import { Admin } from 'components/base/Admin'
import { TextPreload } from 'components/common/loader'
import { Fragment } from 'react'
import { TopExtend } from './extend'
import { FirstViewNoAnimation } from './first-view/no-animation'
import { PCHeader } from 'components/common/header/pc'

export function PCTopNoAnimation() {
  return (
    <Fragment>
      <TextPreload />
      <PCHeader isTopPage />
      <div className='fixed d-left-[16px] d-bottom-[16px] z-50'>
        <Admin />
      </div>
      <FirstViewNoAnimation />
      <TopExtend />
    </Fragment>
  )
}
