import { SPDownloadButton } from 'components/base/DownloadButton'
import { getGTM } from 'utils/functions'
import { LimitBlockMobile } from './LimitBlockMobile'

export function SPTopExtendMiddle() {
  return (
    <div className='w-full bg-primary-600 px-[20px]'>
      <div className='max-w-[360px] w-full mx-auto flex flex-col py-10'>
        <p className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] text-center'>
          アプリを早速使ってみよう
        </p>

        <img
          src='/images/top-sp-extend/middle/main-pic.webp'
          alt='main-pic'
          className='w-full aspect-[360/302] object-contain mt-4'
        />

        <a
          target='_blank'
          href='https://comado.info/164/'
          className='mt-6 px-[12px] mb-[52px]'
          {...getGTM({
            event_name: 'download_click',
            content_type: 'TOP',
            content_id: '訴求エリア_DL',
          })}
        >
          <SPDownloadButton />
        </a>

        <LimitBlockMobile />
      </div>
    </div>
  )
}
