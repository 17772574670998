import { ArrowDownSvg } from '../top/extend/svg/arrow-down'

export function WhatIsThisPointButton() {
  return (
    <button
      type='button'
      className='h-[48px] w-full flex items-center justify-center gap-1 rounded-full bg-[#ffffff] relative border-2 border-[#FFD335]'
      style={{
        boxShadow: '0px 4px 0px 0px #ECBF1E',
      }}
    >
      <span className='text-[14px] font-semibold tracking-[1.6px] truncate'>
        ポイントとは
      </span>
      <ArrowDownSvg className='transform -rotate-90 text-black' />
    </button>
  )
}
