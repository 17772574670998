import { ComponentPropsWithoutRef } from 'react'

export function PhoneSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      {...props}
    >
      <path
        d='M7.50062 2.93398C7.50062 2.93398 7.48396 2.87565 7.45896 2.85898C7.10062 2.61732 6.02562 1.96732 4.65062 2.22565C2.53396 2.61732 1.82562 4.55898 1.72562 5.15065C1.41729 6.99232 2.32562 10.3507 3.15062 11.8507C5.47562 16.1007 9.10062 17.7507 11.0673 18.3007C13.6173 19.0173 15.4423 18.8507 15.8756 18.6423C16.084 18.5423 17.959 17.8173 18.2923 15.7423C18.4923 14.534 17.934 13.5423 17.7173 13.0923C17.684 13.0257 17.634 12.9923 17.5673 12.9757C16.109 12.7173 14.409 12.484 12.9673 12.2007C12.4506 12.1007 12.4673 12.3423 11.984 12.834C11.534 13.3007 11.4923 13.8757 11.2506 13.7757C10.0006 13.234 7.90896 11.734 6.88396 9.49232C6.74229 9.18398 6.61729 9.17565 7.08396 8.96732C7.42562 8.81732 7.66729 8.57565 7.94229 8.27565C8.04229 8.16732 8.32562 7.83398 8.30062 7.70898C8.02562 6.08398 7.78396 4.53398 7.50896 2.90898L7.50062 2.93398Z'
        fill='black'
        fillOpacity='0.8'
      />
    </svg>
  )
}
