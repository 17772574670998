import { AnimatePresence, motion } from 'framer-motion'
import { SPNavLink } from './nav-link'
import { ELEMENT_ID, MAIL_TO, ROUTE_PATHS, TEL_TO } from 'utils/constant'
import { SPChipLink } from './chip-link'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { Fragment } from 'react'
import { SPDownloadButton } from 'components/base/DownloadButton'
import { EmailSvg } from 'components/top/extend/svg/email'
import { YellowButton } from 'components/base/YellowButton'
import { getGTM } from 'utils/functions'

type Props = {
  open: boolean
  onClick: () => void
}

export function SPMenu({ open, onClick }: Props) {
  return (
    <Fragment>
      <AnimatePresence>
        {open && (
          <motion.div
            aria-hidden
            className='w-full fixed top-0 left-0 h-dvh bg-primary-600 z-[999]'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{
              opacity: 0,
              clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
            }}
            animate={{
              opacity: 1,
              clipPath: 'polygon(0 0, 100% 0%, 100% 100%, 0 100%)',
            }}
            exit={{
              opacity: 0,
              clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
            }}
            transition={{ duration: 0.5 }}
            className='fixed z-[1000] top-0 right-0 w-[min(359px,100%)] bg-white rounded-l-[min(20px,max(calc(2000vw-7180px),0px))] h-dvh origin-right overflow-auto'
          >
            <div className='px-8 pt-[66px] flex flex-col gap-4'>
              <div className='flex flex-col'>
                <SPNavLink
                  onClick={onClick}
                  to={ROUTE_PATHS.TOP}
                  label='ページTOP'
                />
                <SPNavLink
                  onClick={onClick}
                  to={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
                  label='Comadoでできる4つのこと'
                />
                <SPNavLink
                  onClick={onClick}
                  to={ROUTE_PATHS.POINT}
                  label='ポイントとは'
                  {...getGTM({
                    event_name: 'button_click',
                    content_type: 'header',
                    content_id: 'ポイントとは',
                  })}
                />
                <SPNavLink
                  onClick={onClick}
                  to={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
                  label='ご利用方法'
                />
                <SPNavLink
                  onClick={onClick}
                  to={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
                  label='よくある質問'
                />
              </div>

              <div className='flex items-center gap-4'>
                <SPChipLink
                  target='_blank'
                  to='https://web.comado.com/assets/platform/terms/service.html?key=400000142'
                  label='利用規約'
                />
                <SPChipLink
                  target='_blank'
                  to='https://web.comado.com/assets/platform/privacy_policy/index.html?key=400000143'
                  label='プライバシーポリシー'
                />
              </div>

              <a
                href='https://comado.info/160/'
                target='_blank'
                {...getGTM({
                  event_name: 'download_click',
                  content_type: 'header',
                  content_id: 'header_DL',
                })}
              >
                <SPDownloadButton />
              </a>

              <div className='mt-4 border-2 rounded-[10px] border-border py-[24px] px-[16px] flex flex-col items-center w-full z-10 bg-white'>
                <div className='flex items-center justify-center gap-[8px]'>
                  <PhoneSvg className='w-[20x] h-[20px]' />
                  <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                    電話でのお問い合わせ
                  </p>
                </div>
                <a
                  href={TEL_TO}
                  target='_blank'
                  className='text-[28px] leading-[29px] tracking-[2.8px] mt-[8px] underline'
                >
                  0120-630-310
                </a>
                <p className='text-center text-[16px] font-medium leading-[32px] tracking-[0.48px] mt-[8px]'>
                  受付時間：9:00～20:00
                  <br />
                  （土日祝日も承ります）
                </p>

                <div className='flex flex-col items-center mt-[8px] pt-[16px] border-t-[2px] border-t-border w-full'>
                  <div className='flex items-center justify-center gap-[8px]'>
                    <EmailSvg className='w-[20px] h-[20px] mb-[2px]' />
                    <p className='text-[16px] font-semibold leading-[30px] tracking-[1.6px]'>
                      メールでのお問い合わせ
                    </p>
                  </div>
                  <p className='text-[16px] leading-[30px] tracking-[1.6px] font-medium mt-[8px]'>
                    info@comado.com
                  </p>
                  <a
                    href={MAIL_TO}
                    target='_blank'
                    className='w-full mt-[8px]'
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'send_mail',
                      content_id: 'お問い合わせ_ヘッダー',
                    })}
                  >
                    <YellowButton>メールを送る</YellowButton>
                  </a>
                </div>
              </div>
            </div>

            <img
              src='/images/top-sp/header/menu-sp-bg.png'
              alt='menu-bg'
              className=' w-full relative object-contain bottom-0 left-0 mt-3'
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  )
}
