import { ComponentPropsWithoutRef } from 'react'

export function RightSlashSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='22'
      viewBox='0 0 16 22'
      fill='none'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5036 0.895453C13.2253 0.117375 11.5583 0.522862 10.7802 1.80113L1.29669 17.3812C0.518609 18.6595 0.924096 20.3265 2.20237 21.1045C3.48064 21.8826 5.14764 21.4771 5.92572 20.1989L15.4092 4.6188C16.1873 3.34053 15.7818 1.67353 14.5036 0.895453Z'
        fill='currentColor'
      />
    </svg>
  )
}
