import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'

export function SPDownloadButton() {
  return (
    <button
      type='button'
      className='h-[48px] w-full flex items-center justify-center gap-1 rounded-full bg-[#494949] relative'
      style={{
        boxShadow: '0px 4px 0px 0px #000',
      }}
    >
      <span className='text-[14px] font-semibold tracking-[1.6px] text-white truncate'>
        無料ダウンロード
      </span>
      <ArrowDownSvg className='transform -rotate-90 text-primary-600' />
    </button>
  )
}
