import { motion, useAnimationControls } from 'framer-motion'
import { PhoneSvg } from '../../top/extend/svg/phone'
import { createRef, useEffect } from 'react'
import { ELEMENT_ID, MAIL_TO, ROUTE_PATHS, TEL_TO } from 'utils/constant'
import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { EmailSvg } from 'components/top/extend/svg/email'
import { ACTIONS, emitter } from 'utils/emitter'
import { getGTM, isSp } from 'utils/functions'

type Props = {
  open: boolean
  onClose: () => void
}

export function PCContactModal({ open, onClose }: Props) {
  const container = createRef<HTMLDivElement>()

  const modalControls = useAnimationControls()
  const overlayControls = useAnimationControls()

  const handleOpen = async () => {
    // Check PC version
    if (isSp()) return

    if (container.current) {
      container.current.style.display = 'flex'

      overlayControls.start({ opacity: 1, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })
    }
  }

  const handleClose = async () => {
    // Check PC version
    if (isSp()) return

    if (container.current) {
      overlayControls.start({ opacity: 0, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })

      container.current.style.display = 'none'
    }
  }

  useEffect(() => {
    emitter.emit(ACTIONS.MODAL_LOCK, open)
    document.documentElement.classList.toggle('block', open)
    if (open) handleOpen()
    else handleClose()
  }, [open])

  return (
    <div
      ref={container}
      className='fixed hidden inset-0 z-[2000] items-center justify-center d-px-[40px]'
    >
      <motion.div
        aria-hidden
        animate={overlayControls}
        className='bg-[#0B0B0B4D] w-full absolute h-dvh opacity-0'
        onClick={onClose}
      />
      <motion.div
        animate={modalControls}
        initial={{ scale: 0.5 }}
        className='d-w-[800px] max-h-90dvh overflow-y-auto d-rounded-[10px] flex flex-col bg-primary-100 opacity-0 z-20'
      >
        <div className='d-rounded-t-[10px] d-pt-[24px] d-px-[24px] flex justify-end'>
          <button
            type='button'
            className='border-none outline-none flex items-center d-gap-[8px]'
            onClick={onClose}
          >
            <img
              src='/images/top-sp/header/menu-close.png'
              alt='menu-close'
              className='d-w-[44px] object-contain'
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 23 11'
              fill='none'
              className='d-w-[23px] d-h-[11px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0974483 0.719618C-0.156157 1.31905 0.124191 2.01057 0.723621 2.26418L21.1511 10.9066C21.7505 11.1602 22.442 10.8798 22.6956 10.2804C22.9492 9.68095 22.6689 8.98943 22.0694 8.73582L1.64201 0.0934453C1.04258 -0.16016 0.351053 0.120187 0.0974483 0.719618Z'
                fill='currentColor'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.6916 0.719618C22.9452 1.31905 22.6649 2.01057 22.0654 2.26418L1.638 10.9066C1.03857 11.1602 0.347051 10.8798 0.0934453 10.2804C-0.16016 9.68095 0.120187 8.98943 0.719618 8.73582L21.1471 0.0934453C21.7465 -0.16016 22.438 0.120187 22.6916 0.719618Z'
                fill='currentColor'
              />
            </svg>
          </button>
        </div>

        <div className='d-px-[137px] d-pt-[6px] d-pb-[43px] flex flex-col relative'>
          <p className='d-text-[32px] d-leading-[53px] d-tracking-[3.2px] font-semibold text-center'>
            お気軽にお問い合わせください。
          </p>

          <p className='d-mt-[12px] d-text-[16px] d-leading-[30px] d-tracking-[1.6px] font-medium'>
            ログインの方法や歩数連携など、アプリを使う上で
            <br />
            お困りのことがありましたらお気軽にお問い合わせください。
          </p>

          <div className='flex d-gap-[24px] d-mt-[12px]'>
            <div className='flex items-center d-gap-[4px]'>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
                className='d-text-[14px] d-leading-[20px] underline'
              >
                Comadoのよくある質問はこちら
              </a>
              <ArrowDownSvg className='d-w-[20px] d-h-[20px] transform -rotate-90' />
            </div>
            <div className='flex items-center d-gap-[4px]'>
              <a
                href={`${ROUTE_PATHS.POINT}#${ELEMENT_ID.FAQ}`}
                className='d-text-[14px] d-leading-[20px] underline'
              >
                ポイントのよくある質問はこちら
              </a>
              <ArrowDownSvg className='d-w-[20px] d-h-[20px] transform -rotate-90' />
            </div>
          </div>

          <div className='w-full border-border d-mt-[16px] d-border-[2px] d-rounded-[10px] d-py-[32px] bg-white'>
            <div className='flex flex-col items-center d-w-[444px] mx-auto'>
              <div className='flex items-center d-gap-[8px]'>
                <PhoneSvg className='d-w-[33px] d-h-[32px]' />
                <p className='d-text-[18px] leading-[36px] tracking-[1.8px] font-semibold'>
                  電話でのお問い合わせ
                </p>
              </div>
              <a
                href={TEL_TO}
                className='d-mt-[12px] d-text-[40px] leading-[29px] tracking-[4px] underline'
              >
                0120-630-310
              </a>
              <p className='d-text-[16px] d-leading-[30px] tracking-[1.6px] font-medium d-mt-[12px]'>
                受付時間：9:00～20:00（土日祝日も承ります）
              </p>
              <div className='flex flex-col items-center d-mt-[12px] d-pt-[12px] border-t-[2px] border-border w-full'>
                <div className='flex items-center d-gap-[8px]'>
                  <EmailSvg className='d-w-[20px] d-h-[20px]' />
                  <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                    メールでのお問い合わせ
                  </p>
                </div>
                <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-medium d-mt-[4px]'>
                  info@comado.com
                </p>

                <a
                  {...getGTM({
                    event_name: 'button_click',
                    content_type: 'send_mail',
                    content_id: 'お問い合わせ_アイコン',
                  })}
                  href={MAIL_TO}
                  className='outline-none d-w-[280px] d-mt-[4px] bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center justify-center d-py-[12px] relative'
                  style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
                >
                  <span className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] font-semibold'>
                    メールを送る
                  </span>
                  <ArrowDownSvg className='d-w-[24px] d-h-[24px] transform -rotate-90 absolute d-right-[24px]' />
                </a>
                <p className='d-mt-[12px] d-text-[14px] d-leading-[20px]'>
                  ※メールアプリが立ち上がります。
                </p>
              </div>
            </div>
          </div>

          <p className='d-text-[14px] d-leading-[20px] d-mt-[16px]'>
            ※電話番号はおかけ間違いのないよう、ご確認ください。 <br />
            ※携帯電話からもご利用になれます。
          </p>

          <img
            src='/images/assets/admin.png'
            alt='admin'
            className='absolute bottom-0 d-w-[90px] d-right-[137px]'
          />
        </div>
      </motion.div>
    </div>
  )
}
