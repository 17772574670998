import { CheckSvg } from 'components/top/extend/svg/check'

export function SPTopExtendMenu04() {
  return (
    <div
      id='sp-menu-04'
      className='max-w-[360px] w-full mx-auto relative mt-[46px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-sp-extend/menu-02/roof.webp'
          alt='roof'
          className='w-full aspect-[360/104] object-contain'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 pt-[17px]'>
          <div className='w-[32px] h-[32px] bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2'>
            <p className='text-[20px] leading-[20px] font-semibold text-white'>
              4
            </p>
          </div>
          <p className='text-[16px] leading-[2] tracking-[0.48px]'>
            空いた時間が
          </p>
          <p className='text-[20px] leading-[1.8] tracking-[2px] font-semibold'>
            楽しみに
          </p>
        </div>
      </div>

      <div className='w-full px-[10px] flex flex-col'>
        <div className='bg-white pt-[137px] px-8 flex flex-col'>
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-accent-green w-fit'>
            できること
          </p>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            健康やレシピ、落語も
            <br />
            多彩なコラム・動画
          </h1>
          <div className='mt-2 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              健康悩みのマメ知識が満載
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              食や落語、旅などジャンルも豊富
            </p>
          </div>
        </div>

        <div className='bg-white pt-4'>
          <img
            src='/images/top-sp-extend/menu-04/main-pic.webp'
            alt='main-picture'
            className='w-full aspect-[340/268] object-contain'
          />
        </div>

        <div className='bg-white pt-10 px-8 pb-8 flex flex-col relative'>
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-accent-green w-fit'>
            できること
          </p>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            人気有料雑誌が
            <br />
            読み放題
          </h1>
          <div className='mt-2 flex gap-2 items-start'>
            <CheckSvg className='w-4 h-4 mt-[8px] shrink-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              るるぶ・オレンジページ・家庭画報・プレジデントなど人気雑誌を随時更新
            </p>
          </div>
          <p className='mt-4 text-[11px] leading-[20px]'>
            ※対象雑誌については予告なく変更になる場合がございます。
            <br />
            ※定期お届けコースの方限定です。
          </p>
          <img
            src='/images/top-extend/menu-04/sub-main-pic.webp'
            alt='sub-picture'
            className='w-full aspect-square object-contain mt-4'
          />

          <img
            src='/images/top/afternoon/pc-afternoon-bus.webp'
            alt='bus'
            className='w-[87px] object-contain absolute -bottom-[18px] -left-[10px]'
          />

          <img
            src='/images/top/afternoon/pc-afternoon-bus-stop.webp'
            alt='bus-stop'
            className='w-[111px] object-contain absolute -bottom-[27px] -right-4'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='w-full aspect-[360/39] rounded-[4px] object-cover'
      />
    </div>
  )
}
