import { CopySvg } from 'components/top/extend/svg/copy'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { Variants, motion } from 'framer-motion'
import { TEL_TO, TEL_TO_02 } from 'utils/constant'

export function PCPointPart04() {
  const medicineVariants: Variants = {
    initial: { x: 10, y: '100%', opacity: 0, scale: 0.5 },
    animate: {
      x: 10,
      y: '6.67%',
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', duration: 1.5, bounce: 0.4 },
    },
  }

  return (
    <div className='w-full bg-primary-100 flex flex-col items-center d-pb-[88px] d-px-[40px]'>
      <img
        src='/images/point/part-04/coin.webp'
        alt='coin'
        className='d-w-[68px] d-h-[68px] d-mt-[107px] object-contain'
      />

      <div className='flex items-center d-gap-[8px] d-mt-[8px]'>
        <LeftSlashSvg className='d-w-[11px] d-h-[15px]' />
        <p className='font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px]'>
          貯まったポイントを使う
        </p>
        <RightSlashSvg className='d-w-[11px] d-h-[15px]' />
      </div>

      <p className='d-mt-[16px] d-text-[34px] d-leading-[48px] d-tracking-[1.7px] font-semibold text-center'>
        貯まったポイントで
        <br />
        商品のご購入がもっとお得に
      </p>

      <p className='d-mt-[16px] d-text-[14px] d-leading-[20px]'>
        ※ポイントの交換先は予告なく変更になる場合がございます。
      </p>

      <div className='max-w-[1000px] w-full flex items-center justify-center d-gap-[26px] d-mt-[78px]'>
        <div className='relative flex flex-col'>
          <motion.img
            variants={medicineVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true, margin: '0px 0px -20% 0px' }}
            src='/images/point/part-04/medicines.webp'
            alt='medicines'
            className='d-w-[403px] d-h-[150px] object-contain'
          />
          <img
            src='/images/point/part-04/box.webp'
            alt='box'
            className='d-w-[341px] d-h-[109px] object-contain z-10'
          />
        </div>
        <div className='flex flex-col'>
          <p className='d-text-[24px] d-leading-[30px] d-tracking-[2.4px] font-semibold'>
            サントリーウエルネス商品や
            <br />
            サントリーグループの飲料・グッズに
            <br />
            ご利用いただけます。
          </p>
          <img
            src='/images/point/part-04/banner.webp'
            alt='banner'
            className='d-w-[492px] d-h-[108px] d-mt-[16px] object-contain z-10'
          />
        </div>
      </div>

      <div className='d-mt-[102px] relative max-w-[1000px] d-px-[16px] d-pt-[18px] d-pb-[14px] w-full d-rounded-[4px] background-point-pc-part-04 bg-[length:100%_auto] bg-repeat'>
        <img
          src='/images/point/part-04/deco/color.webp'
          alt='color'
          className='d-w-[85px] d-left-[-39px] d-top-[46px] object-contain absolute'
        />
        <img
          src='/images/point/part-04/deco/box.webp'
          alt='box'
          className='d-w-[68px] d-left-[-25px] d-bottom-[62px] object-contain absolute'
        />
        <img
          src='/images/point/part-04/deco/color.webp'
          alt='color'
          className='d-w-[68px] d-left-[-5px] d-bottom-[235px] object-contain absolute'
        />
        <img
          src='/images/point/part-04/deco/color.webp'
          alt='color'
          className='d-w-[68px] d-right-[-35px] d-bottom-[435px] object-contain absolute'
        />
        <img
          src='/images/point/part-04/deco/drink.webp'
          alt='drink'
          className='d-w-[53px] d-right-[-20px] d-bottom-[67px] object-contain absolute'
        />
        <img
          src='/images/point/part-04/deco/medicine.webp'
          alt='medicine'
          className='d-w-[62px] d-right-[-27px] d-top-[330px] object-contain absolute'
        />

        <div className='absolute w-full d-top-[-23px] left-0 flex justify-center'>
          <div
            className='d-w-[74px] d-h-[74px] rounded-full bg-primary-800 absolute d-top-[-20px] flex items-center justify-center'
            style={{ clipPath: 'inset(0 0 50% 0)' }}
          >
            <div
              aria-hidden
              className='d-w-[12px] d-h-[12px] bg-primary-100 rounded-full d-translate-y-[-22px]'
            />
          </div>
          <div className='d-pl-[40px] d-pr-[41px] d-pt-[8px] d-pb-[5px] bg-primary-800 d-rounded-[4px]'>
            <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
              ポイントの使い道
            </p>
          </div>
        </div>

        <div className='bg-white w-full d-rounded-t-[8px] d-pl-[67px] d-pr-[82px] d-pb-[20px] d-pt-[50px] flex flex-col items-center'>
          <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold d-border-b-[5px] border-b-primary-800'>
            使い道①
          </p>

          <p className='d-text-[34px] d-leading-[48px] d-tracking-[1.7px] font-semibold d-mt-[24px] text-center'>
            サントリーウエルネスの
            <br />
            健康食品・美容商品の商品割引
          </p>

          <img
            src='/images/point/part-04/pic-01.webp'
            alt='pic-01'
            className='d-w-[760px] d-h-[149px] object-contain d-mt-[54px]'
          />

          <div
            aria-hidden
            className='w-full d-border-t-[2px] d-mt-[49px] border-t-border'
          />

          <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold d-border-b-[5px] d-mt-[42px] border-b-primary-800'>
            使い道②
          </p>

          <p className='d-text-[34px] d-leading-[48px] d-tracking-[1.7px] font-semibold d-mt-[24px] text-center'>
            サントリーグループの
            <br />
            飲料・グッズの商品割引
          </p>

          <img
            src='/images/point/part-04/pic-02.webp'
            alt='pic-02'
            className='d-w-[531px] d-h-[332px] object-contain d-mt-[24px]'
          />

          <div className='d-mt-[14px]'>
            <p className='d-text-[14px] d-leading-[20px]'>
              ※ハーゲンダッツのギフト券は1枚でミニカップ・クリスピーサンド・バーのいずれ
              <br />
              か2個とお引き換え可能。
            </p>

            <p className='d-mt-[8px] d-text-[14px] d-leading-[20px]'>
              ※画像はイメージです。
            </p>
          </div>
        </div>

        <div className='w-full bg-bg-blue d-py-[20px] d-rounded-b-[8px] flex items-center justify-center'>
          <div className='flex items-baseline d-gap-[4px]'>
            <a
              target='_blank'
              href='https://www.suntory-kenko.com/suntory-wellness-club/point-use/?key=400000145'
              className='d-text-[18px] d-leading-[32px] d-tracking-[1.8px] font-medium underline'
            >
              最新情報はこちらから確認できます。
            </a>
            <CopySvg className='d-w-[20px] d-h-[20px]' />
          </div>
        </div>
      </div>

      <div className='max-w-[1000px] w-full'>
        <p className='d-mt-[80px] d-text-[34px] font-semibold d-leading-[48px] d-tracking-[1.7px] text-center'>
          ポイントの使い方に関するお問い合わせ先
        </p>

        <p className='d-mt-[8px] d-text-[18px] font-medium d-leading-[38px] d-tracking-[1.8px] text-center'>
          受付時間：9:00～20:00（土日祝日も承ります）
        </p>

        <div className='d-mt-[24px] d-rounded-[10px] d-border-[2px] border-[#D9D9D9] bg-white d-pt-[24px] d-pb-[16px] d-px-[52px] w-full grid grid-cols-2'>
          <div className='flex flex-col items-center d-pr-[32px] d-border-r-[2px] border-[#D9D9D9]'>
            <div className='flex d-gap-[8px]'>
              <div className='d-mt-[3px] d-w-[29px] d-h-[29px] rounded-full bg-black leading-1 d-text-[20px] font-bold text-white text-center flex items-center justify-center'>
                1
              </div>
              <p className='d-text-[22px] leading-[1.5] font-semibold d-tracking-[2.2px] text-center'>
                健康食品・美容商品
                <p className='d-text-[18px] d-tracking-[1.8px]'>
                  へのポイント利用
                </p>
              </p>
            </div>

            <div className='flex items-center d-gap-[9px] d-mt-[16px]'>
              <PhoneSvg className='d-w-[28px] d-h-[28px]' />
              <a
                href={TEL_TO}
                className='d-text-[40px] d-leading-[29px] d-tracking-[4px] underline'
              >
                0120-630-310
              </a>
            </div>

            <p className='d-mt-[20px] d-text-[14px] d-leading-[20px]'>
              ※ご契約中の定期お届けコースのお支払いにポイントを使いたい場合は、お届け日の7日前までにお電話ください。
            </p>
          </div>

          <div className='flex flex-col items-center d-pl-[32px]'>
            <div className='flex d-gap-[8px]'>
              <div className='d-mt-[3px] d-w-[29px] d-h-[29px] rounded-full bg-black leading-1 d-text-[20px] font-bold text-white text-center flex items-center justify-center'>
                2
              </div>
              <p className='d-text-[22px] leading-[1.5] font-semibold d-tracking-[1.6px] text-center'>
                サントリーグループの飲料・グッズ
                <p className='d-text-[18px] d-tracking-[1.8px]'>
                  へのポイント利用
                </p>
              </p>
            </div>
            <div className='flex items-center d-gap-[9px] d-mt-[16px]'>
              <PhoneSvg className='d-w-[28px] d-h-[28px]' />
              <a
                href={TEL_TO_02}
                className='d-text-[40px] d-leading-[29px] d-tracking-[4px] underline'
              >
                0120-049-310
              </a>
            </div>
          </div>
        </div>
      </div>

      <p className='d-mt-[12px] d-text-[14px] d-leading-[20px]'>
        ※電話番号はおかけ間違いのないよう、ご確認ください。※携帯電話からもご利用になれます。
      </p>
    </div>
  )
}
