import { ComponentPropsWithoutRef } from 'react'

export function CheckSvg(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      {...props}
    >
      <path
        d='M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z'
        className='fill-primary-600'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.178 6.57856C14.6869 7.04506 14.7213 7.83576 14.2548 8.34466L8.78926 14.307L5.36612 10.8839C4.87796 10.3957 4.87796 9.60428 5.36612 9.11612C5.85427 8.62797 6.64573 8.62797 7.13388 9.11612L8.71074 10.693L12.4119 6.65535C12.8784 6.14645 13.6691 6.11207 14.178 6.57856Z'
        className='fill-text-color'
      />
    </svg>
  )
}
