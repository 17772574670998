import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { ComponentPropsWithoutRef } from 'react'

export function YellowButton({
  style,
  children,
  ...more
}: ComponentPropsWithoutRef<'button'>) {
  return (
    <button
      type='button'
      className='outline-none w-full bg-primary-600 rounded-full flex items-center justify-center py-[12px] relative'
      style={{ boxShadow: '0px 4px 0px 0px #D7A905', ...style }}
      {...more}
    >
      <span className='text-[16px] leading-[32px] tracking-[1.6px] font-semibold'>
        {children}
      </span>
      <ArrowDownSvg className='w-[24px] h-[24px] transform -rotate-90 absolute right-[24px]' />
    </button>
  )
}
