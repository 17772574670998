import { LimitBlock } from './LimitBlock'

export function TopExtendMiddle() {
  return (
    <div className='w-full bg-primary-600 flex flex-col items-center d-py-[80px]'>
      <p className='font-semibold d-text-[34px] d-leading-[48px] d-tracking-[1.7px]'>
        アプリを早速使ってみよう
      </p>
      <p className='d-mt-[10px] d-text-[18px] d-leading-[38px] d-tracking-[1.8px]'>
        スマホのカメラを起動して下のコードを読み取ってください。
      </p>
      <img
        src='/images/top-extend/middle/main-pic.webp'
        alt='main-pic'
        className='object-contain d-w-[1000px] d-h-[436px] d-mt-[24px] mb-[61px]'
      />

      <LimitBlock />
    </div>
  )
}
