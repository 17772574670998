import { Fragment } from 'react'
import { SPTopExtend } from 'components/top-sp/extend'
import { TextPreload } from 'components/common/loader'
import { SPFirstViewNoAnimation } from './first-view/no-animation'
import { SPHeader } from 'components/common/header/sp'
import { SPPointContact } from 'components/point-sp/contact'

export function SPTopNoAnimation() {
  return (
    <Fragment>
      <TextPreload />
      <SPHeader />
      <SPPointContact />
      <SPFirstViewNoAnimation />
      <SPTopExtend />
    </Fragment>
  )
}
