import { ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function LimitBlock() {
  return (
    <div className='mx-auto d-w-[829px] rounded-lg relative'>
      <img
        src='/images/top/limited-block/lead-desktop.png'
        className='absolute left-0 d-top-[-16px] z-10 d-w-[298px] d-h-[40px]'
        alt='leading'
      />
      <div className='bg-white d-pt-[62px] d-pl-[60px] d-pb-[32px] d-pr-[14px] flex d-gap-[20px] rounded-tl-lg rounded-tr-lg relative z-0'>
        <div className='d-w-[339px] shrink-0 d-mt-[17px]'>
          <h3 className='d-text-[24px] d-leading-[30px] d-mb-[8px] d-tracking-[2.4px] font-semibold'>
            Comadoの健康行動で
            <br />
            ポイントを貯めよう
          </h3>
          <div className='flex flex-col d-tracking-[0.72px] d-text-[18px] d-leading-[30px] font-medium'>
            サントリーウエルネスの健康食品・
            美容商品や、サントリーグループの飲料・
            グッズの購入時に使えるポイントで、
            １ポイント＝１円からご利用できます。
          </div>
        </div>
        <div>
          <img
            src='/images/top/limited-block/limit-version.webp'
            alt='定期お届けコースの方限定'
            className='d-w-[396px] d-h-[162px]'
          />
          <p className='d-text-[12px] d-leading-[20px] d-mt-[6px]'>
            ※ハーゲンダッツのギフト券は1枚でミニカップ・クリスピーサンド・バーのいずれか2個とお引き換え可能。
            <br />
            ※画像はイメージです。
          </p>
        </div>
      </div>
      <div className='bg-[#F4F5FB] d-p-[32px] d-text-[16px] d-leading-[30px] d-tracking-[1.6px] text-center font-medium rounded-br-lg rounded-bl-lg'>
        <div>※Comadoのポイント機能は定期お届けコースの方限定機能です。</div>
        <a
          {...getGTM({
            event_name: 'button_click',
            content_type: 'TOP_Point',
            content_id: 'ポイントとは',
          })}
          href={ROUTE_PATHS.POINT}
          className='font-semibold d-text-[18px] d-leading-[36px] d-tracking-[1.8px] items-center bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full d-mt-[24px] inline-flex d-pl-[81px] d-py-[12px] d-pr-[18px] d-gap-[39px]'
          style={{
            boxShadow: 'rgb(215, 169, 5) 0px 4px 0px 0px',
          }}
        >
          <p className='font-semibold d-text-[18px] d-tracking-[1.8px]'>
            ポイントとは
          </p>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            className='transform -rotate-90 d-w-[20px] d-h-[20px]'
          >
            <path
              d='M10.9854 14.2973L16.8065 8.47626C17.594 7.68881 17.0363 6.34238 15.9226 6.34238L4.28049 6.34238C3.16686 6.34238 2.60915 7.68881 3.39661 8.47626L9.21768 14.2973C9.70584 14.7855 10.4973 14.7855 10.9854 14.2973Z'
              fill='currentColor'
            ></path>
          </svg>
        </a>
      </div>
    </div>
  )
}
