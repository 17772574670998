export function PCPointPart01() {
  return (
    <div className='w-full min-h-dvh bg-primary-600 flex items-center justify-center d-px-[40px]'>
      <div className='max-w-[1000px] w-full flex items-center justify-between'>
        <div className='flex flex-col'>
          <img
            src='/images/point/part-01/label.webp'
            alt='label'
            className='d-w-[308px] object-contain'
          />
          <p className='d-text-[48px] d-leading-[78px] d-tracking-[4.8px] d-mt-[16px] font-semibold'>
            Comadoアプリで
            <br />
            健康行動が
            <br />
            ポイントに変わる！
          </p>
          <p className='d-text-[18px] d-leading-[38px] d-tracking-[1.8px] d-mt-[16px] font-medium'>
            サントリーウエルネスの健康食品・美容商品や、
            <br />
            サントリーグループの飲料・グッズの購入時に使える
            <br />
            ポイントで、
            <br />
            １ポイント＝１円からご利用できます。
          </p>
          <div className='flex items-center bg-white w-fit d-rounded-[18px] d-py-[16px] d-pl-[33px] d-pr-[56px] d-gap-[16px] d-mt-[24px]'>
            <div className='flex flex-col'>
              <div className='flex items-baseline d-gap-[7px]'>
                <div className='flex flex-col'>
                  <p className='font-semibold d-text-[32px] d-leading-[26px] d-tracking-[3.2px]'>
                    無料
                  </p>
                  <div className='w-full rounded-[1px] bg-primary-600 d-h-[5px] d-mt-[5px]' />
                </div>
                <p className='font-semibold d-text-[16px] d-tracking-[1.6px]'>
                  でご利用いただけます。
                </p>
              </div>
              <p className='font-semibold d-text-[16px] d-leading-[32px] d-tracking-[1.6px]'>
                右のコードからダウンロード！
              </p>
            </div>
            <img
              src='/images/point/part-01/qr.webp'
              alt='qr'
              className='object-contain d-w-[88px]'
            />
          </div>
        </div>
        <div className='flex flex-col'>
          <img
            src='/images/point/part-01/main.webp'
            alt='main'
            className='d-w-[481px] object-contain'
          />
          <p className='d-text-[14px] d-leading-[20px] d-mt-[48px]'>
            ※定期お届けコース限定の機能やコンテンツもございます。
          </p>
        </div>
      </div>
    </div>
  )
}
