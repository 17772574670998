import { ComponentPropsWithoutRef } from 'react'

export interface SPNavLinkProps extends ComponentPropsWithoutRef<'a'> {
  to?: string
  label: string
  onClick?: () => void
}

export function SPNavLink({ to, label, onClick, ...more }: SPNavLinkProps) {
  return (
    <a
      href={to}
      onClick={onClick}
      className='flex w-full flex-col pt-4 first:pt-0'
      {...more}
    >
      <div className='flex items-center select-none gap-1'>
        <p className='grow text-[16px] font-semibold leading-[1.88] tracking-[1.6px]'>
          {label}
        </p>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M11.4379 7.21867L6.78101 2.56182C6.15104 1.93186 5.0739 2.37802 5.0739 3.26893L5.0739 12.5826C5.0739 13.4735 6.15105 13.9197 6.78101 13.2897L11.4379 8.63289C11.8284 8.24236 11.8284 7.6092 11.4379 7.21867Z'
            fill='currentColor'
          />
        </svg>
      </div>
      <div aria-hidden className='border-t w-full border-t-primary-600 mt-1' />
    </a>
  )
}
