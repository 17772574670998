import { Ticker } from 'components/base/Ticker'
import { LeftSlashSvg } from '../../top/extend/svg/left-slash'
import { RightSlashSvg } from '../../top/extend/svg/right-slash'
import { PhoneSvg } from '../../top/extend/svg/phone'
import { ELEMENT_ID, MAIL_TO, ROUTE_PATHS, TEL_TO } from 'utils/constant'
import { EmailSvg } from 'components/top/extend/svg/email'
import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { getGTM } from 'utils/functions'

export function PCFooter() {
  return (
    <div id='pcfooter' className='flex flex-col w-full relative'>
      <img
        src='/images/top-extend/footer/background.webp'
        alt='background'
        className='w-full object-cover object-top absolute inset-0'
      />

      <div
        className='w-full h-[90%] absolute bottom-0 bg-primary-600 d-pt-[200px]'
        aria-hidden
      />

      <div className='flex flex-col items-center z-10 d-pt-[120px]'>
        <img
          src='/images/top-extend/footer/logo.webp'
          alt='logo'
          className='object-contain d-w-[300px]'
        />
        <div className='flex items-center d-gap-[8px] d-mt-[32px]'>
          <LeftSlashSvg className='d-w-[15px] d-h-[21px]' />
          <p className='font-semibold d-text-[24px] d-leading-[30px] d-tracking-[2.4px]'>
            アプリダウンロードはこちら
          </p>
          <RightSlashSvg className='d-w-[15px] d-h-[21px]' />
        </div>

        <div className='w-full relative flex items-center justify-center d-mt-[32px]'>
          <img
            src={'/images/top-extend/footer/qr.webp'}
            alt='qr'
            className='object-contain z-20 d-w-[265px]'
          />
          <div className='absolute w-screen'>
            <Ticker>
              <div className='relative d-w-[172px] d-h-[203px] d-mx-[20px]'>
                <img
                  src='/images/top-extend/footer/morning-window.webp'
                  alt='morning-window'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[172px] d-h-[203px] d-mx-[20px]'>
                <img
                  src='/images/top-extend/footer/afternoon-window.webp'
                  alt='afternoon-window'
                  className='object-contain'
                />
              </div>
              <div className='relative d-w-[172px] d-h-[203px] d-mx-[20px]'>
                <img
                  src='/images/top-extend/footer/evening-window.webp'
                  alt='evening-window'
                  className='object-contain'
                />
              </div>
            </Ticker>
          </div>
        </div>

        <div className='w-fit flex flex-col items-center d-pb-[126px]'>
          <p className='text-center font-semibold d-text-[18px] d-leading-[32px] d-tracking-[1.8px] d-mt-[34px]'>
            ログイン方法などお困りのことがある場合、
            <br />
            下記よりお気軽にお問い合わせください。
          </p>

          <div className='w-fit border-border d-mt-[16px] d-border-[2px] d-rounded-[10px] d-py-[32px] d-px-[24px] bg-white'>
            <div className='flex flex-col items-center d-w-[444px] mx-auto'>
              <div className='flex items-center d-gap-[8px]'>
                <PhoneSvg className='d-w-[33px] d-h-[32px]' />
                <p className='d-text-[18px] leading-[36px] tracking-[1.8px] font-semibold'>
                  電話でのお問い合わせ
                </p>
              </div>
              <a
                href={TEL_TO}
                className='d-mt-[12px] d-text-[40px] leading-[29px] tracking-[4px] underline'
              >
                0120-630-310
              </a>
              <p className='d-text-[16px] d-leading-[30px] tracking-[1.6px] font-medium d-mt-[12px]'>
                受付時間：9:00～20:00（土日祝日も承ります）
              </p>
              <div className='flex flex-col items-center d-mt-[12px] d-pt-[12px] border-t-[2px] border-border w-full'>
                <div className='flex items-center d-gap-[8px]'>
                  <EmailSvg className='d-w-[20px] d-h-[20px]' />
                  <p className='d-text-[18px] d-leading-[36px] d-tracking-[1.8px] font-semibold'>
                    メールでのお問い合わせ
                  </p>
                </div>
                <p className='d-text-[18px] d-leading-[30px] d-tracking-[0.72px] font-medium d-mt-[4px]'>
                  info@comado.com
                </p>

                <div className='d-w-[280px] d-mt-[4px]'>
                  <a
                    {...getGTM({
                      event_name: 'button_click',
                      content_type: 'send_mail',
                      content_id: 'お問い合わせ_フッター',
                    })}
                    href={MAIL_TO}
                    className='outline-none d-w-[280px] d-mt-[4px] bg-primary-600 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center justify-center d-py-[12px] relative'
                    style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
                  >
                    <span className='d-text-[16px] d-leading-[32px] d-tracking-[1.6px] font-semibold'>
                      メールを送る
                    </span>
                    <ArrowDownSvg className='d-w-[24px] d-h-[24px] transform -rotate-90 absolute d-right-[24px]' />
                  </a>
                </div>
                <p className='d-mt-[12px] d-text-[14px] d-leading-[20px]'>
                  ※メールアプリが立ち上がります。
                </p>
              </div>
            </div>
          </div>

          <div className='flex flex-col items-center d-gap-[8px] d-mt-[40px]'>
            <div className='flex items-center d-gap-[52px]'>
              <a
                href={ROUTE_PATHS.TOP}
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                ページTOP
              </a>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                Comadoでできる4つのこと
              </a>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                ご利用方法
              </a>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                よくある質問
              </a>
            </div>
            <div className='flex items-center gap-[52px]'>
              <a
                href={ROUTE_PATHS.POINT}
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                ポイントとは
              </a>
              <a
                target='_blank'
                href='https://web.comado.com/assets/platform/terms/service.html?key=400000142'
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                利用規約
              </a>
              <a
                target='_blank'
                href='https://web.comado.com/assets/platform/privacy_policy/index.html?key=400000143'
                className='font-medium d-text-[18px] d-leading-[30px] d-tracking-[0.72px]'
              >
                プライバシーポリシー
              </a>
            </div>
          </div>

          <p className='d-mt-[40px] d-text-[11px] d-leading-[20px]'>
            (C) Copyright Suntory Holdings Limited. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
