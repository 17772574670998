import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { CheckSvg } from 'components/top/extend/svg/check'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID, ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function PCPointPart07() {
  return (
    <div className='flex flex-col items-center d-pt-[120px_1440] d-pb-[80px_1440] relative'>
      <div className='flex items-center justify-center d-gap-[8px_1440]'>
        <LeftSlashSvg className='d-w-[15px_1440] d-h-[15px_1440]' />
        <p className='font-semibold d-text-[32px_1440] d-leading-[64px_1440] d-tracking-[3.2px_1440]'>
          Comadoでできること
        </p>
        <RightSlashSvg className='d-w-[15px_1440] d-h-[15px_1440]' />
      </div>

      <div className='max-w-[1440px] w-full d-px-[83px_1440] d-mt-[25px_1440]'>
        <div className='grid grid-cols-3 d-gap-[7px_1440]'>
          {/* Picture 01 */}
          <div className='relative d-px-[7px_1440]'>
            <div className='w-full bg-white min-h-full d-pt-[90px_1440] d-pl-[32px_1440] d-pr-[24px_1440] d-pb-[40px_1440] d-rounded-[8px_1440]'>
              <p className='d-text-[18px_1440] d-leading-[38px_1440] d-tracking-[1.8px_1440] font-medium'>
                小さな健康行動を
              </p>
              <p className='d-text-[32px_1440] d-leading-[52px_1440] d-tracking-[3.2px_1440] font-semibold'>
                習慣化する
              </p>

              <div className='flex d-gap-[8px_1440] d-mt-[16px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  健康行動を習慣化
                </p>
              </div>

              <div className='flex d-gap-[8px_1440] d-mt-[10px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  うっかり忘れ防止サプリの飲用記録
                </p>
              </div>

              <img
                src='/images/point/part-07/pic-01.webp'
                alt='pic-01'
                className='d-w-[256px_1440] d-mt-[42px_1440] mx-auto'
              />
            </div>
            <img
              src='/images/point/part-07/roof.webp'
              alt='roof'
              className='w-full object-contain absolute inset-0'
            />
          </div>

          {/* Picture 02 */}
          <div className='relative d-px-[7px_1400]'>
            <div className='w-full bg-white min-h-full d-pt-[90px_1440] d-pl-[32px_1440] d-pr-[24px_1440] d-pb-[40px_1440] d-rounded-[8px_1440]'>
              <p className='d-text-[18px_1440] d-leading-[38px_1440] d-tracking-[1.8px_1440] font-medium'>
                自分のペースで
              </p>
              <p className='d-text-[32px_1440] d-leading-[52px_1440] d-tracking-[3.2px_1440] font-semibold'>
                続けられる
              </p>

              <div className='flex d-gap-[8px_1440] d-mt-[16px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  お家で5分からできるフィットネス
                </p>
              </div>

              <div className='flex d-gap-[8px_1440] d-mt-[10px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  結果がひと目でわかる歩数記録
                </p>
              </div>

              <img
                src='/images/point/part-07/pic-02.webp'
                alt='pic-02'
                className='d-w-[311px_1440] d-mt-[70px_1440] mx-auto'
              />
            </div>
            <img
              src='/images/point/part-07/roof.webp'
              alt='roof'
              className='w-full object-contain absolute inset-0'
            />
          </div>

          {/* Picture 03 */}
          <div className='relative d-px-[7px_1440]'>
            <div className='w-full bg-white min-h-full d-pt-[90px_1440] d-pl-[32px_1440] d-pr-[24px_1440] d-pb-[40px_1440] d-rounded-[8px_1440]'>
              <p className='d-text-[18px_1440] d-leading-[38px_1440] d-tracking-[1.8px_1440] font-medium'>
                空いた時間が
              </p>
              <p className='d-text-[32px_1440] d-leading-[52px_1440] d-tracking-[3.2px_1440] font-semibold'>
                楽しみに変わる
              </p>

              <div className='flex d-gap-[8px_1440] d-mt-[16px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  健康やレシピ、
                  <br />
                  落語も多彩なコラム・動画
                </p>
              </div>

              <div className='flex d-gap-[8px_1440] d-mt-[10px_1440]'>
                <CheckSvg className='d-w-[20px_1440] d-h-[20px_1440] d-translate-y-[8px_1440]' />
                <p className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
                  読み放題コンテンツ
                </p>
              </div>

              <img
                src='/images/point/part-07/pic-03.webp'
                alt='pic-03'
                className='d-w-[256px_1440] d-mt-[5px_1440] mx-auto'
              />
            </div>
            <img
              src='/images/point/part-07/roof.webp'
              alt='roof'
              className='w-full object-contain absolute inset-0'
            />
          </div>
        </div>
      </div>

      <p className='d-text-[16px_1440] d-leading-[32px_1440] d-tracking-[1.6px_1440] d-mt-[32px_1440] font-medium'>
        Comadoの機能を確認しよう
      </p>

      <a
        {...getGTM({
          event_name: 'button_click',
          content_type: 'recommend',
          content_id: 'comadoでできること',
        })}
        href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
        className='outline-none bg-primary-600 hover:border-primary-700 hover:bg-primary-700 hover:!shadow-none transform transition-all duration-150 hover:translate-y-[4px] rounded-full flex items-center w-fit d-gap-[20px_1440] d-py-[12px_1440] d-pl-[64px_1440] d-pr-[18px_1440] d-mt-[8px_1440]'
        style={{ boxShadow: '0px 4px 0px 0px #D7A905' }}
      >
        <span className='d-text-[18px_1440] d-leading-[36px_1440] d-tracking-[1.8px_1440] font-semibold'>
          Comadoでできること
        </span>
        <ArrowDownSvg className='d-w-[24px_1440] d-h-[24px_1440] transform -rotate-90' />
      </a>
    </div>
  )
}
