import { ComponentPropsWithoutRef, useRef } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

interface CollapseProps extends ComponentPropsWithoutRef<'button'> {
  number: number
  title: string
  duration?: number
}

const DURATION = 0.25

export function SPTopExtendDeviceCollapse({
  children,
  number,
  title,
  duration = DURATION,
  ...more
}: CollapseProps) {
  const panelControls = useAnimationControls()
  const arrowControls = useAnimationControls()

  const block = useRef<boolean>(false)
  const isOpen = useRef<boolean>(false)

  const toggle = async () => {
    if (!block.current) {
      isOpen.current = !isOpen.current
      block.current = true
      arrowControls.start({
        rotate: isOpen.current ? 180 : 0,
        transition: { duration },
      })
      await panelControls.start({
        height: isOpen.current ? 'auto' : 0,
        transition: { duration },
      })
      block.current = false
    }
  }

  return (
    <div className='flex flex-col px-6 py-4 border-2 border-border rounded-lg'>
      <button
        type='button'
        className='w-full flex items-center gap-2'
        onClick={toggle}
        {...more}
      >
        <div className='w-6 h-6 shrink-0 rounded-full flex items-center justify-center bg-accent-green'>
          <p className='text-[16px] leading-[16px] text-white'>{number}</p>
        </div>
        <p className='text-[16px] font-semibold leading-[1.87] tracking-[1.6px] ml-2 grow text-left'>
          {title}
        </p>
        <motion.svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          animate={arrowControls}
        >
          <path
            d='M10.9854 14.2973L16.8065 8.47626C17.594 7.68881 17.0363 6.34238 15.9226 6.34238L4.28049 6.34238C3.16686 6.34238 2.60915 7.68881 3.39661 8.47626L9.21768 14.2973C9.70584 14.7855 10.4973 14.7855 10.9854 14.2973Z'
            fill='currentColor'
          />
        </motion.svg>
      </button>
      <motion.div animate={panelControls} className='overflow-hidden h-0'>
        {children}
      </motion.div>
    </div>
  )
}
