import { CSSProperties } from 'react'

export const randomId = (length = 5) => {
  const buf = new Uint8Array(length)
  window.crypto.getRandomValues(buf)
  return btoa(String.fromCharCode(...buf))
}

export const sleep = (milliseconds: number) => {
  return new Promise((r) => setTimeout(r, milliseconds))
}

export interface CSSScale extends CSSProperties {
  scaleWidth?: number
}

export const getScaleStyle = (styles: CSSScale) => {
  const { scaleWidth = 1080, ...more } = styles
  const entries = Object.entries(more)?.map((item) => {
    const [key, value]: any = item
    if (value?.includes('px')) {
      const parse = value.slice(0, -2)
      return parseInt(parse) < 0
        ? [key, `max(${parse}px,calc(100vw*${parse}/${scaleWidth}))`]
        : [key, `min(${parse}px,calc(100vw*${parse}/${scaleWidth}))`]
    }
    return item
  })
  return Object.fromEntries(entries)
}

type GTMProps = {
  event_name: string
  content_type: string
  content_id: string
}

export const getGTM = (props: GTMProps) => {
  const { event_name, content_type, content_id } = props
  return {
    'data-event': Object.entries(props)
      .map((item) => item.join(':'))
      .join(';'),
    onMouseDown: () => {
      const parent: any = window
      if (parent.dataLayer) {
        parent.dataLayer.push({
          event: 'gaEvent',
          event_name: event_name,
          content_type: content_type,
          content_id: content_id,
        })
      }
    },
  }
}

export const isSp = () => {
  return window.innerWidth < 744
}

export const isPc = () => {
  return window.innerWidth >= 744
}
