export function PCPointPart03() {
  return (
    <div className='w-full bg-primary-600 d-px-[40px]'>
      <div className='max-w-[1000px] w-full mx-auto flex flex-col d-py-[80px]'>
        <p className='text-center d-text-[32px] d-leading-[58px] d-tracking-[3.2px] font-semibold'>
          Comadoアプリを使って
          <br />
          ポイントを獲得しよう
        </p>
        <img
          src='/images/point/part-03/main.webp'
          alt='main'
          className='d-w-[1000px] d-h-[436px] d-mt-[24px] object-contain'
        />
      </div>
    </div>
  )
}
