import { SPDownloadButton } from 'components/base/DownloadButton'
import { Ticker } from 'components/base/Ticker'
import { YellowButton } from 'components/base/YellowButton'
import { EmailSvg } from 'components/top/extend/svg/email'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID, MAIL_TO, ROUTE_PATHS, TEL_TO } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPFooter() {
  return (
    <div
      id='spfooter'
      className='flex flex-col w-full relative background-sp-footer bg-[length:100vw_auto] bg-no-repeat bg-top'
    >
      <div
        className='w-full h-[90%] absolute bottom-0 bg-primary-600 d-pt-[200px]'
        aria-hidden
      />
      <div className='flex flex-col items-center relative mt-[60px] pb-[98px]'>
        <img
          src='/images/top-extend/footer/logo.webp'
          alt='logo'
          className='w-[207px] object-contain'
        />
        <div className='flex items-center gap-2 mt-[17px]'>
          <LeftSlashSvg className='w-2 h-3' />
          <p className='text-[18px] font-semibold leading-[2.11] tracking-[1.8px]'>
            アプリダウンロードはこちら
          </p>
          <RightSlashSvg className='w-2 h-3' />
        </div>

        <div className='max-w-[360px] w-full mx-auto mt-3 px-[10px]'>
          <a
            target='_blank'
            href='https://comado.info/161/'
            className='w-full'
            {...getGTM({
              event_name: 'download_click',
              content_type: 'footer',
              content_id: 'footer_DL',
            })}
          >
            <SPDownloadButton />
          </a>
        </div>

        <div className='mt-[27px]'>
          <Ticker>
            <div className='w-[90px] h-[106px] relative mx-[10px]'>
              <img
                src='/images/top-extend/footer/morning-window.webp'
                alt='morning-window'
                className='object-contain'
              />
            </div>
            <div className='w-[90px] h-[106px] relative mx-[10px]'>
              <img
                src='/images/top-extend/footer/afternoon-window.webp'
                alt='afternoon-window'
                className='object-contain'
              />
            </div>
            <div className='w-[90px] h-[106px] relative mx-[10px]'>
              <img
                src='/images/top-extend/footer/evening-window.webp'
                alt='evening-window'
                className='object-contain'
              />
            </div>
          </Ticker>
        </div>

        <div className='w-full flex flex-col items-center max-w-[360px] mx-auto mt-[38px] px-[10px]'>
          <p className='text-center text-[16px] font-semibold leading-[1.87] tracking-[1.6px]'>
            お困りのことがある場合、
            <br />
            下記よりお気軽に
            <br />
            お問い合わせください。
          </p>

          <div className='mt-4 border-2 rounded-[10px] border-border pt-[24px] px-[16px] pb-[16px] flex flex-col items-center w-full bg-white'>
            <div className='flex items-center justify-center gap-[8px]'>
              <PhoneSvg className='w-[20x] h-[20px]' />
              <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                電話でのお問い合わせ
              </p>
            </div>
            <a
              href={TEL_TO}
              target='_blank'
              className='text-[28px] leading-[29px] tracking-[2.8px] mt-[8px] underline'
            >
              0120-630-310
            </a>
            <p className='text-center text-[16px] font-medium leading-[32px] tracking-[0.48px] mt-[8px]'>
              受付時間：9:00～20:00
              <br />
              （土日祝日も承ります）
            </p>

            <div className='flex flex-col items-center mt-[16px] pt-[16px] border-t-[2px] border-t-border w-full'>
              <div className='flex items-center justify-center gap-[8px]'>
                <EmailSvg className='w-[20px] h-[20px] mb-[2px]' />
                <p className='text-[16px] font-semibold leading-[30px] tracking-[1.6px]'>
                  メールでのお問い合わせ
                </p>
              </div>
              <p className='text-[16px] leading-[30px] tracking-[1.6px] font-medium mt-[8px]'>
                info@comado.com
              </p>
              <a
                href={MAIL_TO}
                target='_blank'
                className='w-full mt-[8px]'
                {...getGTM({
                  event_name: 'button_click',
                  content_type: 'send_mail',
                  content_id: 'お問い合わせ_フッター',
                })}
              >
                <YellowButton>メールを送る</YellowButton>
              </a>
              <p className='mt-[8px] text-[11px] leading-[20px]'>
                ※メールアプリが立ち上がります。
              </p>
            </div>
          </div>

          <div className='mt-10 flex flex-col items-center'>
            <div className='flex items-center gap-4'>
              <a
                href={ROUTE_PATHS.TOP}
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                ページTOP
              </a>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}`}
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                Comadoのできる4つのこと
              </a>
            </div>
            <div className='flex items-center gap-2'>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                ご利用方法
              </a>
              <a
                href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                よくある質問
              </a>
            </div>
            <a
              href={ROUTE_PATHS.POINT}
              className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
            >
              ポイントとは
            </a>
            <div className='flex items-center gap-2'>
              <a
                target='_blank'
                href='https://web.comado.com/assets/platform/terms/service.html?key=400000142'
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                利用規約
              </a>
              <a
                target='_blank'
                href='https://web.comado.com/assets/platform/privacy_policy/index.html?key=400000143'
                className='text-[14px] font-medium leading-[2.28] tracking-[0.42px]'
              >
                プライバシーポリシー
              </a>
            </div>
          </div>

          <p className='mt-6 text-[11px] leading-[1.82]'>
            (C) Copyright Suntory Holdings Limited. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
