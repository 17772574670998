import { SPDownloadButton } from 'components/base/DownloadButton'
import { YellowButton } from 'components/base/YellowButton'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { ELEMENT_ID, ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPPointPart05() {
  return (
    <div className='px-[16px] pb-[40px] bg-illust'>
      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center'>
        <div className='flex items-center gap-[8px] mt-[40px]'>
          <LeftSlashSvg className='w-[7px] h-[11px]' />
          <p className='font-semibold text-[20px] leading-[36px] tracking-[2px]'>
            ポイントの始め方
          </p>
          <RightSlashSvg className='w-[7px] h-[11px]' />
        </div>

        <p className='text-[16px] leading-[32px] tracking-[0.48px] px-[8px] font-medium mt-[8px]'>
          Comadoアプリでポイントを貯めるには「定期お届けコース」のお申し込みが必要です。
        </p>

        <div className='flex items-center gap-[8px] mt-[24px] self-start'>
          <div
            aria-hidden
            className='w-[16px] h-[16px] rounded-full bg-accent-green'
          />
          <p className='text-[16px] leading-[32px] tracking-[1.6px] font-semibold'>
            定期お届けコースお申し込み済みの方
          </p>
        </div>

        <div className='flex flex-col pb-[52px] px-[16px] bg-white rounded-[8px] mt-[8px] w-full'>
          <p className='text-[20px] leading-[36px] tracking-[2px] mt-[24px] font-semibold pb-[4px] border-b-[3px] border-b-primary-600'>
            1.Comadoにログイン
          </p>

          <img
            src='/images/point-sp/part-05/pic-01.webp'
            alt='pic-01'
            className='w-full aspect-square object-contain mt-[16px]'
          />

          <p className='tex-[16px] leading-[32px] tracking-[0.48px] mt-[12px] font-medium'>
            サントリーウエルネスでログインしたアカウント情報で、Comadoにログインしてください。
          </p>

          <a
            href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.HOW_TO_USER}`}
            className='tex-[16px] leading-[32px] tracking-[0.48px] mt-[8px] font-medium underline'
          >
            ログイン方法はこちら
          </a>

          <p className='text-[14px] leading-[20px] mt-[24px]'>
            アプリのダウンロードがまだの方はこちら
          </p>

          <a
            target='_blank'
            href='https://comado.info/174/'
            className='mt-[4px]'
            {...getGTM({
              event_name: 'download_click',
              content_type: 'Point',
              content_id: 'comado_始め方',
            })}
          >
            <SPDownloadButton />
          </a>

          <p className='text-[20px] leading-[36px] tracking-[2px] mt-[32px] font-semibold pb-[4px] border-b-[3px] border-b-primary-600'>
            2.チャレンジを達成する
          </p>

          <img
            src='/images/point-sp/part-05/pic-02.webp'
            alt='pic-02'
            className='w-full aspect-square object-contain mt-[16px]'
          />

          <p className='tex-[16px] leading-[32px] tracking-[0.48px] mt-[12px] font-medium'>
            下のタブから「チャレンジ」をタップし、該当の健康行動を達成したら青いバナーが出てきます。
            青いバナーが出たらポイント獲得です。
          </p>
        </div>

        <div className='flex items-center gap-[8px] mt-[40px] self-start'>
          <div
            aria-hidden
            className='w-[16px] h-[16px] rounded-full bg-accent-green'
          />
          <p className='text-[16px] leading-[32px] tracking-[1.6px] font-semibold'>
            定期お届けコースに申し込んでいない方
          </p>
        </div>

        <div className='flex flex-col pb-[36px] px-[16px] bg-white rounded-[8px] mt-[8px] w-full'>
          <p className='text-[20px] leading-[36px] tracking-[2px] mt-[24px] font-semibold pb-[4px] border-b-[3px] border-b-primary-600'>
            定期お届けコース申し込み方法
          </p>

          <img
            src='/images/point-sp/part-05/pic-03.webp'
            alt='pic-03'
            className='w-full aspect-square object-contain mt-[16px]'
          />

          <p className='tex-[16px] leading-[32px] tracking-[0.48px] mt-[8px] font-medium'>
            サントリーウエルネスのwebサイトで商品をご注文いただく際、各商品「定期お届けコース」枠内の『買い物かごに入れる』ボタンをクリックして定期お届けコースにお申し込みください。
          </p>

          <a
            href='https://www.suntory-kenko.com/service/regular/?key=400000144'
            target='_blank'
            className='tex-[16px] leading-[32px] tracking-[0.48px] mt-[8px] font-medium underline'
          >
            定期お届けコースとは？
          </a>

          <p className='text-[14px] leading-[20px] mt-[24px] text-center'>
            サントリーウエルネスwebサイトで
            <br />
            商品を確認できます。
          </p>

          <a
            href='https://www.suntory-kenko.com/?key=400000146'
            target='_blank'
            className='mt-[4px]'
            {...getGTM({
              event_name: 'button_click',
              content_type: 'howtostart',
              content_id: '商品を選ぶ',
            })}
          >
            <YellowButton style={{ paddingTop: 8, paddingBottom: 8 }}>
              商品を選ぶ
            </YellowButton>
          </a>
        </div>
      </div>
    </div>
  )
}
