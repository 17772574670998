import { SPTopExtendOverviewItem } from './item'
import { getGTM } from 'utils/functions'
import { ELEMENT_ID } from 'utils/constant'

export function SPTopExtendOverview() {
  const scrollTo = (id: string) => {
    document.querySelector(`#${id}`)?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div id={ELEMENT_ID.FOUR_THINGS_YOU_CAN_DO}>
      <img
        src='/images/top-extend/overview/left-leafs.webp'
        alt='left-leafs'
        className='w-[130px] object-contain absolute top-0 -left-[14px]'
      />
      <img
        src='/images/top-extend/overview/right-leafs.webp'
        alt='right-leafs'
        className='w-[130px] object-contain absolute top-0 -right-[14px]'
      />
      <div className='max-w-[340px] mx-auto w-full pt-[105px] flex flex-col items-center justify-center px-[10px] overflow-hidden'>
        <p className='text-[14px] font-medium leading-[2.28] tracking-[0.42px] text-center'>
          Comadoでちょっと楽しくずっと健康な毎日を。
        </p>

        <div className='flex items-center justify-center flex-wrap'>
          <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px]'>
            Comadoでできる
          </p>
          <p className='mx-2 text-[42px] leading-[1.8] tracking-[6px]'>4</p>
          <p className='text-[20px] font-semibold leading-[1.8] tracking-[2px]'>
            つのこと
          </p>
        </div>

        <div className='flex flex-col w-full mt-4'>
          <SPTopExtendOverviewItem
            number={1}
            title='健康行動が'
            label='ポイントに'
            onClick={() => scrollTo('sp-menu-01')}
            {...getGTM({
              event_name: 'tab_click',
              content_type: 'main_contents',
              content_id: 'できること１',
            })}
          />
          <div aria-hidden className='w-full border-t border-t-primary-100' />
          <SPTopExtendOverviewItem
            number={2}
            title='小さな健康行動を'
            label='習慣化できる '
            onClick={() => scrollTo('sp-menu-02')}
            {...getGTM({
              event_name: 'tab_click',
              content_type: 'main_contents',
              content_id: 'できること２',
            })}
          />
          <div aria-hidden className='w-full border-t border-t-primary-100' />
          <SPTopExtendOverviewItem
            number={3}
            title='自分のペースで'
            label='続ける '
            onClick={() => scrollTo('sp-menu-03')}
            {...getGTM({
              event_name: 'tab_click',
              content_type: 'main_contents',
              content_id: 'できること３',
            })}
          />
          <div aria-hidden className='w-full border-t border-t-primary-100' />
          <SPTopExtendOverviewItem
            number={4}
            title='空いた時間が'
            label='楽しみに '
            onClick={() => scrollTo('sp-menu-04')}
            {...getGTM({
              event_name: 'tab_click',
              content_type: 'main_contents',
              content_id: 'できること４',
            })}
          />
        </div>
      </div>
    </div>
  )
}
