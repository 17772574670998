import { motion, useAnimationControls } from 'framer-motion'
import { createRef, useEffect } from 'react'
import { TOP_SP_ACTIONS, topSPEmitter } from 'utils/emitter'
import { ArrowDownSvg } from 'components/top/extend/svg/arrow-down'
import { ELEMENT_ID, MAIL_TO, ROUTE_PATHS, TEL_TO } from 'utils/constant'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { EmailSvg } from 'components/top/extend/svg/email'
import { YellowButton } from 'components/base/YellowButton'
import { isPc } from 'utils/functions'

type Props = {
  open: boolean
  onClose?: () => void
}

export function SPContactModal({ open = false, onClose }: Props) {
  const container = createRef<HTMLDivElement>()

  const modalControls = useAnimationControls()
  const overlayControls = useAnimationControls()

  const handleOpen = async () => {
    // Check SP version
    if (isPc()) return

    if (container.current) {
      container.current.style.display = 'flex'

      overlayControls.start({ opacity: 1, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 1,
        scale: 1,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })
    }
  }

  const handleClose = async () => {
    // Check SP version
    if (isPc()) return

    if (container.current) {
      overlayControls.start({ opacity: 0, transition: { duration: 0.5 } })
      await modalControls.start({
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.7, type: 'spring', bounce: 0.3 },
      })

      container.current.style.display = 'none'
    }
  }

  useEffect(() => {
    topSPEmitter.emit(TOP_SP_ACTIONS.MODAL_LOCK, open)
    document.documentElement.classList.toggle('block', open)
    if (open) handleOpen()
    else handleClose()
  }, [open])

  return (
    <div
      ref={container}
      className='fixed hidden inset-0 z-[2000] items-center justify-center d-px-[40px]'
    >
      <motion.div
        aria-hidden
        animate={overlayControls}
        className='bg-[#0B0B0B4D] w-full absolute h-dvh opacity-0'
        onClick={onClose}
      />
      <motion.div
        animate={modalControls}
        initial={{ scale: 0.5 }}
        className='max-w-[360px] w-full rounded-[10px] flex flex-col bg-primary-100 opacity-0 z-20 max-h-90dvh overflow-auto'
      >
        <div className='ml-auto px-[16px] pt-[16px]'>
          <button
            type='button'
            className='border-none outline-none flex items-center gap-[8px] self-end'
            onClick={onClose}
          >
            <img
              src='/images/top-sp/header/menu-close.png'
              alt='menu-close'
              className='w-[44px] object-contain'
            />
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 23 11'
              fill='none'
              className='w-[23px] h-[11px]'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.0974483 0.719618C-0.156157 1.31905 0.124191 2.01057 0.723621 2.26418L21.1511 10.9066C21.7505 11.1602 22.442 10.8798 22.6956 10.2804C22.9492 9.68095 22.6689 8.98943 22.0694 8.73582L1.64201 0.0934453C1.04258 -0.16016 0.351053 0.120187 0.0974483 0.719618Z'
                fill='currentColor'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.6916 0.719618C22.9452 1.31905 22.6649 2.01057 22.0654 2.26418L1.638 10.9066C1.03857 11.1602 0.347051 10.8798 0.0934453 10.2804C-0.16016 9.68095 0.120187 8.98943 0.719618 8.73582L21.1471 0.0934453C21.7465 -0.16016 22.438 0.120187 22.6916 0.719618Z'
                fill='currentColor'
              />
            </svg>
          </button>
        </div>
        <div className='rounded-t-[10px] mt-[14px] px-[24px] pb-[11px] flex flex-col relative'>
          <p className='text-[20px] leading-[36px] tracking-[2px] font-semibold'>
            お気軽に
            <br />
            お問い合わせください。
          </p>
          <p className='mt-[12px] text-[16px] leading-[32px] tracking-[0.48px] font-medium'>
            ログインの方法や歩数連携など、アプリを使う上でお困りのことがありましたらお気軽にお問い合わせください。
          </p>

          <div className='flex items-center gap-[2px] mt-[12px]'>
            <a
              href={`${ROUTE_PATHS.TOP}#${ELEMENT_ID.FAQ}`}
              className='text-[11px] leading-[20px] underline'
            >
              Comadoのよくある質問はこちら
            </a>
            <ArrowDownSvg className='w-[16px] h-[16px] transform -rotate-90' />
          </div>

          <div className='flex items-center gap-[2px] mt-[12px]'>
            <a
              href={`${ROUTE_PATHS.POINT}#${ELEMENT_ID.FAQ}`}
              className='text-[11px] leading-[20px] underline'
            >
              ポイントのよくある質問はこちら
            </a>
            <ArrowDownSvg className='w-[16px] h-[16px] transform -rotate-90' />
          </div>

          <div className='mt-4 border-2 rounded-[10px] border-border pt-[24px] px-[16px] pb-[16px] flex flex-col items-center w-full bg-white'>
            <div className='flex items-center justify-center gap-[8px]'>
              <PhoneSvg className='w-[20x] h-[20px]' />
              <p className='text-[16px] leading-[30px] tracking-[1.6px] font-semibold'>
                電話でのお問い合わせ
              </p>
            </div>
            <a
              href={TEL_TO}
              target='_blank'
              className='text-[28px] leading-[29px] tracking-[2.8px] mt-[8px] underline'
            >
              0120-630-310
            </a>
            <p className='text-center text-[16px] font-medium leading-[32px] tracking-[0.48px] mt-[8px]'>
              受付時間：9:00～20:00
              <br />
              （土日祝日も承ります）
            </p>

            <div className='flex flex-col items-center mt-[16px] pt-[16px] border-t-[2px] border-t-border w-full'>
              <div className='flex items-center justify-center gap-[8px]'>
                <EmailSvg className='w-[20px] h-[20px] mb-[2px]' />
                <p className='text-[16px] font-semibold leading-[30px] tracking-[1.6px]'>
                  メールでのお問い合わせ
                </p>
              </div>
              <p className='text-[16px] leading-[30px] tracking-[1.6px] font-medium mt-[8px]'>
                info@comado.com
              </p>
              <a href={MAIL_TO} target='_blank' className='w-full mt-[8px]'>
                <YellowButton>メールを送る</YellowButton>
              </a>
              <p className='mt-[8px] text-[11px] leading-[20px]'>
                ※メールアプリが立ち上がります。
              </p>
            </div>
          </div>

          <p className='mt-[16px] text-[11px] leading-[20px]'>
            ※電話番号はおかけ間違いのないよう、
            <br />
            ご確認ください。
            <br />
            ※携帯電話からもご利用になれます。
          </p>

          <img
            src='/images/assets/admin.png'
            alt='admin'
            className='absolute bottom-0 w-[64px] right-[23px]'
          />
        </div>
      </motion.div>
    </div>
  )
}
