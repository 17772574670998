import { YellowButton } from 'components/base/YellowButton'
import { CheckSvg } from 'components/top/extend/svg/check'
import { ROUTE_PATHS } from 'utils/constant'
import { getGTM } from 'utils/functions'

export function SPTopExtendMenu01() {
  return (
    <div
      id='sp-menu-01'
      className='max-w-[360px] w-full mx-auto relative mt-[46px]'
    >
      <div className='z-10 absolute inset-0 h-fit'>
        <img
          src='/images/top-sp-extend/menu-01/roof.webp'
          alt='roof'
          className='w-full aspect-[360/104] object-contain'
        />
        <div className='flex flex-col items-center w-full absolute inset-0 pt-[17px]'>
          <div className='w-[32px] h-[32px] bg-accent-green rounded-full absolute top-0 grid place-content-center transform -translate-y-1/2'>
            <p className='text-[20px] leading-[20px] font-semibold text-white'>
              1
            </p>
          </div>
          <p className='text-[16px] leading-[2] tracking-[0.48px]'>
            健康行動が
          </p>
          <p className='text-[20px] leading-[1.8] tracking-[2px] font-semibold'>
            ポイントに
          </p>
        </div>
      </div>

      <div className='w-full px-[10px] flex flex-col'>
        <div className='bg-white pt-[137px] px-8 pb-6 flex flex-col'>
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px] border-b-[3px] border-primary-600 w-fit'>
            できること
          </p>
          <h1 className='text-[22px] font-semibold leading-[1.64] tracking-[2.2px] mt-4'>
            健康行動が
            <br />
            お得なポイントに
          </h1>
          <div className='mt-2 flex gap-2'>
            <CheckSvg className='w-4 h-4 transform !translate-y-2' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              ポイントも貯めながら健康
              <br />
              行動を習慣化
            </p>
          </div>
          <div className='mt-1 flex gap-2 items-center'>
            <CheckSvg className='w-4 h-4 transform !translate-y-0' />
            <p className='text-[16px] leading-[2] tracking-[0.48px]'>
              サプリなどの商品をお得に購入
            </p>
          </div>
          <img
            src='/images/top-sp-extend/menu-01/main-pic.webp'
            alt='main-picture'
            className='w-full aspect-square object-contain mt-2'
          />
        </div>

        <div className='flex flex-col bg-bg-blue px-8 pt-6 pb-5 relative'>
          <p className='text-[16px] font-semibold leading-[1.8] tracking-[1.6px]'>
            ポイントは、サプリ購入やサントリー体験などにご活用いただけます。
          </p>
          <p className='mt-2 text-[11px] leading-[1.81]'>
            ※Comadoのポイント機能は定期お届けコースの方限定機能です。
          </p>
          <a
            href={ROUTE_PATHS.POINT}
            className='w-full mt-[8px]'
            {...getGTM({
              event_name: 'button_click',
              content_type: 'TOP_Point',
              content_id: 'ポイントとは',
            })}
          >
            <YellowButton>ポイントとは</YellowButton>
          </a>

          <img
            src='/images/top-sp-extend/menu-01/medicine.webp'
            alt='medicine'
            className='absolute -left-[10px] -bottom-[20px] w-[39px] object-contain'
          />
        </div>
      </div>

      <img
        src='/images/top/morning/pc-morning-table.webp'
        alt='table'
        className='w-full aspect-[360/39] rounded-[4px] object-cover'
      />
    </div>
  )
}
