import { CopySvg } from 'components/top/extend/svg/copy'
import { LeftSlashSvg } from 'components/top/extend/svg/left-slash'
import { PhoneSvg } from 'components/top/extend/svg/phone'
import { RightSlashSvg } from 'components/top/extend/svg/right-slash'
import { Variants, motion } from 'framer-motion'
import { TEL_TO, TEL_TO_02 } from 'utils/constant'

export function SPPointPart04() {
  const medicineVariants: Variants = {
    initial: { x: 3, y: '100%', opacity: 0, scale: 0.5 },
    animate: {
      x: 3,
      y: '6.67%',
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', duration: 1, bounce: 0.4 },
    },
  }

  return (
    <div className='relative px-[16px] overflow-hidden'>
      <img
        src='/images/point-sp/part-04/left-deco.webp'
        alt='left-deco'
        className='w-[147px] object-contain absolute left-0 -top-[18px]'
      />
      <img
        src='/images/point-sp/part-04/right-deco.webp'
        alt='right-deco'
        className='w-[147px] object-contain absolute right-0 -top-[18px]'
      />

      <div className='max-w-[360px] w-full mx-auto flex flex-col items-center pb-[60px]'>
        <img
          src='/images/point-sp/part-04/icon.webp'
          alt='icon'
          className='w-[40px] aspect-square mt-[80px] object-contain'
        />

        <div className='flex items-center gap-[5px] mt-[4px]'>
          <LeftSlashSvg className='w-[6px] h-[10px]' />
          <p className='font-semibold text-[16px] leading-[30px] tracking-[1.6px]'>
            貯まったポイントを使う
          </p>
          <RightSlashSvg className='w-[6px] h-[10px]' />
        </div>

        <p className='mt-[4px] text-[20px] leading-[36px] tracking-[2px] font-semibold text-center'>
          貯まったポイントで
          <br />
          商品のご購入がもっとお得に
        </p>

        <p className='mt-[12px] text-[11px] leading-[20px]'>
          ※ポイントの交換先は予告なく変更になる場合がございます。
        </p>

        <div className='relative flex flex-col mt-[24px]'>
          <motion.img
            variants={medicineVariants}
            initial='initial'
            whileInView='animate'
            viewport={{ once: true, margin: '0px 0px -20% 0px' }}
            src='/images/point-sp/part-04/medicines.webp'
            alt='medicines'
            className='w-[249px] aspect-[249/92] object-contain'
          />
          <img
            src='/images/point-sp/part-04/box.webp'
            alt='box'
            className='w-[205px] aspect-[205/66] object-contain z-10'
          />
        </div>

        <p className='mt-[8px] text-[16px] leading-[32px] tracking-[0.48px] font-medium text-center'>
          サントリーウエルネス商品や
          <br />
          サントリーグループの飲料・グッズに
          <br />
          ご利用いただけます。
        </p>

        <img
          src='/images/point-sp/part-04/label.webp'
          alt='label'
          className='w-[343px] aspect-[343/69] mt-[8px] object-contain'
        />

        <div
          className={`mt-[83px] w-full relative px-[16px] py-[18px] rounded-[4px] background-point-sp-part-04 bg-[length:100%_auto] bg-repeat`}
        >
          <div className='absolute w-full -top-[23px] left-0 flex justify-center'>
            <div
              className='w-[74px] h-[74px] rounded-full bg-primary-800 absolute -top-[20px] flex items-center justify-center'
              style={{ clipPath: 'inset(0 0 50% 0)' }}
            >
              <div
                aria-hidden
                className='w-[12px] h-[12px] bg-primary-100 rounded-full transform -translate-y-[22px]'
              />
            </div>
            <div className='pl-[56px] pr-[51px] pt-[8px] pb-[5px] bg-primary-800 rounded-[4px]'>
              <p className='text-[18px] leading-[36px] tracking-[1.8px] font-semibold'>
                ポイントの使い道
              </p>
            </div>
          </div>

          <div className='bg-white w-full rounded-t-[8px] px-[16px] pb-[35px] flex flex-col'>
            <p className='mt-[40px] text-[16px] leading-[32px] tracking-[1.6px] font-semibold mx-auto border-b-[3px] border-b-primary-800'>
              使い道①
            </p>

            <div className='w-full px-[16px] mt-[16px]'>
              <p className='text-[22px] leading-[36px] tracking-[2.2px] font-semibold'>
                サントリーウエルネスの健康食品・美容商品の商品割引
              </p>
            </div>

            <img
              src='/images/point-sp/part-04/pic-01.webp'
              alt='pic-01'
              className='w-[286px] aspect-[256/175] mt-[16px] object-contain mx-auto'
            />

            <p className='mt-[32px] text-[16px] leading-[32px] tracking-[1.6px] font-semibold mx-auto border-b-[3px] border-b-primary-800'>
              使い道②
            </p>

            <div className='w-full px-[16px] mt-[16px]'>
              <p className='text-[22px] leading-[36px] tracking-[2.2px] font-semibold'>
                サントリーグループの飲料・グッズの商品割引
              </p>
            </div>

            <img
              src='/images/point-sp/part-04/pic-02.webp'
              alt='pic-02'
              className='w-[264px] aspect-[264/254] mt-[8px] object-contain mx-auto'
            />

            <div className='w-full px-[9px] mt-[8px]'>
              <p className='text-[11px] leading-[20px]'>
                ※ハーゲンダッツのギフト券は1枚でミニカップ・クリスピーサンド・バーのいずれか2個とお引き換え可能。
              </p>
              <p className='text-[11px] leading-[20px] mt-[8px]'>
                ※画像はイメージです。
              </p>
            </div>
          </div>

          <div className='bg-pale-gray w-full rounded-b-[8px] py-[16px] px-[18px]'>
            <div className='flex items-end gap-[3px] justify-center'>
              <a
                target='_blank'
                href='https://www.suntory-kenko.com/suntory-wellness-club/point-use/?key=400000145'
                className='text-[16px] leading-[32px] tracking-[0.48px] font-medium underline text-center'
              >
                最新情報は
                <br />
                こちらから確認できます。
              </a>
              <CopySvg className='mb-[5px]' />
            </div>
          </div>
        </div>

        <p className='mt-[40px] text-[20px] font-semibold leading-[36px] tracking-[2px] text-center'>
          ポイントの使い方に関する
          <br />
          お問い合わせ先
        </p>
        <p className='mt-[12px] text-[16px] font-medium leading-[32px] tracking-[0.48px] text-center'>
          受付時間9:00～20:00
          <br />
          （土日祝日も承ります）
        </p>

        <div className='px-[24px] w-full'>
          <div className='mt-[16px] rounded-[8px] border-2 border-[#D9D9D9] py-[24px] bg-white w-full'>
            <div className='px-[16px] flex flex-col items-center pb-[16px] border-b-2 border-b-[#D9D9D9]'>
              <div className='flex gap-[8px]'>
                <div className='mt-[3px] w-[21px] h-[21px] rounded-full bg-black leading-1 text-[16px] font-bold text-white text-center flex items-center justify-center'>
                  1
                </div>
                <p className='text-[16px] leading-[1.5] font-semibold d-tracking-[1.6x] text-center'>
                  健康食品・美容商品
                  <p className='text-[14px] d-tracking-[1.4px]'>
                    へのポイント利用
                  </p>
                </p>
              </div>

              <div className='flex items-center gap-[9px] mt-[12px]'>
                <PhoneSvg className='w-[20px] h-[20px]' />
                <a
                  href={TEL_TO}
                  className='text-[28px] leading-[29px] tracking-[2.8px] underline'
                >
                  0120-630-310
                </a>
              </div>

              <p className='mt-[12px] text-[11px] leading-[20px]'>
                ※ご契約中の定期お届けコースのお支払いにポイントを使いたい場合は、お届け日の7日前までにお電話ください。
              </p>
            </div>

            <div className='px-[16px] flex flex-col items-center pt-[16px]'>
              <div className='flex gap-[8px]'>
                <div className='mt-[3px] w-[21px] h-[21px] rounded-full bg-black leading-1 text-[16px] font-bold text-white text-center flex items-center justify-center'>
                  2
                </div>
                <p className='text-[16px] leading-[1.5] font-semibold d-tracking-[1.6x] text-center'>
                  サントリーグループの
                  <p className='text-[14px] d-tracking-[1.4px]'>
                    飲料・グッズ
                    <br />
                    へのポイント利用
                  </p>
                </p>
              </div>

              <div className='flex items-center gap-[9px] mt-[12px]'>
                <PhoneSvg className='w-[20px] h-[20px]' />
                <a
                  href={TEL_TO_02}
                  className='text-[28px] leading-[29px] tracking-[2.8px] underline'
                >
                  0120-049-310
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='px-[24px] mt-[16px] w-full text-[11px] leading-[20px]'>
          ※電話番号はおかけ間違いのないよう、ご確認ください。 <br />
          ※携帯電話からもご利用になれます。
        </div>
      </div>
    </div>
  )
}
